import { Component, Fragment } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './styles.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import routes, { IMenu } from 'routes';

interface IProps {
  history: any;
  t: any;
}

interface IState {
  width: number;
  menuLeft?: IMenu;
}

const initWidth = 300;

class MenuLeft extends Component<IProps & RouteComponentProps, IState> {
  constructor(props: IProps & RouteComponentProps) {
    super(props);
    this.state = { width: initWidth, menuLeft: undefined };
  }

  componentDidMount() {
    this.handleGetMenuleft(this.props);
  }

  UNSAFE_componentWillReceiveProps(nProps: IProps & RouteComponentProps) {
    this.handleGetMenuleft(nProps);
  }

  handleIcon = () => {
    const { width } = this.state;
    if (width === 0) {
      this.setState({ width: initWidth });
    } else {
      this.setState({ width: 0 });
    }
  };

  handleGetMenuleft = (props: IProps & RouteComponentProps) => {
    const { history } = props;
    const { location } = history;
    const { pathname } = location;
    const route: any = routes.find((el) => pathname.includes(el.path));
    if (route) {
      const { menuLeft } = route;
      this.setState({ menuLeft });
    }
  };

  render() {
    const { width, menuLeft } = this.state;
    const { t, history } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (!menuLeft) {
      return null;
    }
    return (
      <div className="menu-left-custom" style={{ width }}>
        <div
          className="icon-back"
          onClick={this.handleIcon}
          style={{ transform: width ? 'rotate(0)' : 'rotate(180deg)', right: width ? -11 : -14 }}
        >
          <ArrowBackIosIcon fontSize="small" style={{ color: '#fff', width: 13, marginLeft: 4 }} />
        </div>
        <div className="menu-list" style={{ height: window.innerHeight - 100 }}>
          {Array.isArray(menuLeft) ? (
            menuLeft.map((e, index: number) => (
              <Fragment key={index.toString()}>
                <div className="menu-item-title">{t(e.title)}</div>
                {e.routes?.map((e: any, index: number) => (
                  <div
                    className={`menu-item-content ${
                      pathname === e.path ? ' menu-left-item-active' : ''
                    }`}
                    key={index.toString()}
                    onClick={() => history.push(e.path)}
                  >
                    {t(e.menuText)}
                  </div>
                ))}
              </Fragment>
            ))
          ) : (
            <Fragment>
              <div className="menu-item-title" style={{ marginTop: 8 }}>
                {t(menuLeft.title)}
              </div>
              {menuLeft.routes?.map((e: any, index: number) => (
                <div
                  className={`menu-item-content ${
                    pathname === e.path ? ' menu-left-item-active' : ''
                  }`}
                  key={index.toString()}
                  onClick={() => history.push(e.path)}
                >
                  {t(e.menuText)}
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(MenuLeft);
