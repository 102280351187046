import axiosConfig from 'config/axios';

const content = {
  async list(dataReq) {
    try {
      const data = await axiosConfig.get('/admin-cms/content-management/list', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async public(id: string) {
    try {
      const data = await axiosConfig.put(`/admin-cms/content-management/publish/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async delete(id: string) {
    try {
      const data = axiosConfig.delete(`/admin-cms/content-management/delete/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async create(dataReq) {
    try {
      const data = axiosConfig.post('/admin-cms/content-management/create', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async edit(dataReq, id) {
    try {
      const data = axiosConfig.put(`/admin-cms/content-management/update/${id}`, dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async changeStatusMaintance(id: string) {
    try {
      const data = await axiosConfig.put(
        `/admin-cms/content-management/change-status-maintance/${id}`
      );
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default content;
