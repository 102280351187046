/* eslint-disable no-useless-escape */

import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
message.config({ maxCount: 1 });

export function validateEmail(email: string) {
  const re =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
  if (!email) return false;
  var emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  var account = emailParts[0];
  var address = emailParts[1];

  if (account.length > 64) return false;
  else if (address.length > 255) return false;

  var domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  ) {
    return false;
  }
  return re.test(String(email).toLowerCase());
}

export function setLocalStogareKey(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  }
}

export function getLocalStogareKey(key) {
  return localStorage.getItem(key);
}

export function removeLocalStogareKey(key) {
  localStorage.removeItem(key);
}
export function login(access_token, refresh_token) {
  setLocalStogareKey('access_token', access_token);
  setLocalStogareKey('refresh_token', refresh_token);
}

export function logout() {
  removeLocalStogareKey('access_token');
  removeLocalStogareKey('refresh_token');
}

export function getHeaders(contentType = 'application/json; charset=UTF-8') {
  const access_token = getLocalStogareKey('access_token');
  return {
    Authorization: 'Bearer ' + access_token,
    'Content-Type': contentType,
  };
}

export function showMessageSuccess(content) {
  message.success({
    content: (
      <span className="content-custom-message">
        {content}
        <CloseOutlined
          onClick={() => message.destroy()}
          className="icon-close-custom-message"
          style={{ color: 'rgba(0,0,0,0.54)', width: 14 }}
        />
      </span>
    ),
    className: 'message_success_custom',
  });
}

export function showMessageError(content) {
  message.error({
    content: (
      <span className="content-custom-message-error">
        {content}
        <CloseOutlined
          onClick={() => message.destroy()}
          className="icon-close-custom-message"
          style={{ color: 'rgba(0,0,0,0.54)', width: 14 }}
        />
      </span>
    ),
    className: 'message_error_custom',
  });
}

export function random(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
