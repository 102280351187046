import axiosConfig from 'config/axios';

const account = {
  async accounts(dataReq) {
    try {
      const data = await axiosConfig.get('/organization-cms', { params: dataReq });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async create(dataReq) {
    try {
      const data = await axiosConfig.post('/organization-cms', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async turnOffIP(id: string) {
    try {
      const data = await axiosConfig.put(`/organization-cms/turn-of-ip-restriction/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async update(dataReq: any, id: string) {
    try {
      const data = await axiosConfig.put(`/organization-cms/update/${id}`, dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async detail(id) {
    try {
      const data = await axiosConfig.get(`/organization-cms/detail/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async listUser(dataReq) {
    try {
      const data = await axiosConfig.get('/organization-cms/list-user-by-account', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },

  async createUser(dataReq, id) {
    try {
      const data = await axiosConfig.put(`/organization-cms/create-account-user/${id}`, dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async logAccount(id) {
    try {
      const data = await axiosConfig.get(`/organization-cms/log/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },

  async delete(id) {
    try {
      const data = await axiosConfig.delete(`/organization-cms/delete/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async changeStatus(id) {
    try {
      const data = await axiosConfig.put(`/organization-cms/active/${id}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async listAccountRoot(dataReq?: any) {
    try {
      const data = await axiosConfig.get(`/organization-cms/account-root`, { params: dataReq });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default account;
