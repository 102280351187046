export * from './incident-question';


export interface ISettingPageProps {
  page: string;
}

export enum ESettingPage {
  ORGANIZATION_SETUP = 'ogSetup',
  ADMIN_SETUP = 'adminSetup',
  SOS_SETUP = 'sosSetup',
  INCIDENT_MANAGEMENT = 'incidentManagement',
  ADMIN_USER_SETUP = 'adminUserSetup',
  MESSAGE_CLOSE_INCIDENT_DISPLAY = 'messageClosedIncidentDisplay',
  UPLOAD = 'settingUpload',
  SECURITY = 'security',
  INCIDENT_QUESTION = 'incidentQuestion',
  AUTOMATED_RESPONSE = 'automatedResponse',
  FREEZE_INCIDENT_REPORTING = 'freezeIncidentReporting',
  DEACTIVATED_USER = 'deactivatedUser',
}

export interface IMyOptInsProps {}

/**
 * Form Account Organization Setup
 */
export interface IOGLevelACOGProps {
  formRef: any;
  onFinish: (values: any) => void;
  isVisible: boolean;
  onCancel: () => void;
  selectedItem: any;
  loadingOrganizations?: () => void;
}

export interface IFormCreateACOGProps extends IOGLevelACOGProps {
  optionsCascaderOG: any[];
}

export interface IGenQrCodeProps {
  isVisible: boolean;
  onCancel: () => void;
  code: string;
}

export interface IDeactiveCodeProps extends IGenQrCodeProps {
  handleDeactive: (code: string) => void;
  selectedItem: any;
  isLoading: boolean;
}

export interface IMoveOGProps {
  isVisible: boolean;
  onCancel: () => void;
  optionsCascaderOG: any[];
  handleMove: (to: string[], cb: () => void) => void;
  selectedItem: any;
  isLoading: boolean;
  selectedItems?: any[];
}

export interface IModalHideAndDeleteProps {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isDelete?: boolean;
  isHide?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  isUnHide?: boolean;
  errorCode?: string;
}

export enum EStatusMdHideDelete {
  DELETE = 'd',
  HIDE = 'h',
  ERROR = 'e',
}
export interface IStatusMdHideDelete {
  isDelete: boolean;
  isHide: boolean;
  isError: boolean;
  isUnHide: boolean;
  isLoading: boolean;
  errorCode?: string;
}

export interface ICheckableActionProps {
  selected: number;
  handleMove: () => void;
  handleHide: () => void;
  isLoading: boolean;
  disabledMove: boolean;
}

/**
 * Action & Payload
 */

// List OG
export interface IQueryListOrganization {
  show_hidden?: boolean;
  name?: string;
}
export interface IPayloadLoadingOrganization {
  query?: IQueryListOrganization;
  onSuccess?: (data?: any[]) => void;
  onError?: (errorCode: string, data?: any) => void;
  ignoreDispatchReducer?: boolean;
}
export interface IActionLoadingOrganiztion {
  type: string;
  payload: IPayloadLoadingOrganization;
}

export interface IOrganizationSelector {
  organizations: any[];
  isLoading: boolean;
  isShowHiddenOrganization: boolean;
  isExpand: boolean;
  keySearch: string;
}

// Create OG

export enum ETypeOrganization {
  SINGLE = 0,
  MULTIPLE = 1,
}
export interface ICreateOrganizationFormValues {
  parent_id: string;
  name: string;
  type: ETypeOrganization; // 0: Single 1: Multi
  home_message: string;
  incident_message: string;
  working_time: string;
  access_codes: string[];

  // for optional of UI
  position?: string[];
}

export interface IPayloadCreateOrganization {
  values: ICreateOrganizationFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionCreateOrganization {
  type: string;
  payload: IPayloadCreateOrganization;
}

// Checking Access Code
export enum EActionAccessCode {
  VALIDATE = 'VALIDATE',
  SAVE = 'SAVE',
  DEACTIVE = 'DEACTIVE',
}
export interface ICheckAccessCodeValues {
  access_code: string;
  action: EActionAccessCode;
  organization_id: string;
}
export interface IPayloadCheckAccessCode {
  values: ICheckAccessCodeValues;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionCheckAccessCodeOrganization {
  type: string;
  payload: IPayloadCheckAccessCode;
}

// Detail Organization

export interface IPayloadDetailOrganization {
  organization_id: string;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string, data?: any) => void;
}
export interface IActionDetailOrganization {
  type: string;
  payload: IPayloadDetailOrganization;
}

export enum EBrandingType {
  READ_ONLY = 'READ_ONLY',
  EDITABLE = 'EDITABLE',
}

// Update Organization

export interface IUpdateOrganizationFormValues {
  organization_id: string;
  name: string;
  branding_type?: EBrandingType;
  home_message?: string;
  incident_message?: string;
  working_time?: string;

  // For optional
  access_codes?: any[];
}

export interface IPayloadUpdateOrganization {
  values: IUpdateOrganizationFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionUpdateOrganization {
  type: string;
  payload: IPayloadUpdateOrganization;
}

// Multi action ["HIDE","SHOW","DELETE"]

export enum EMultiActionOrganization {
  HIDE = 'HIDE',
  SHOW = 'SHOW',
  DELETE = 'DELETE',
}
export interface IMultiActionFormValues {
  action: EMultiActionOrganization;
  organization_id: string;
}

export interface IPayloadMultiActionOrganization {
  values: IMultiActionFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IMultiActionOrganization {
  type: string;
  payload: IPayloadMultiActionOrganization;
}

// Change show hidden organization
export interface IActionChangeShowHiddenOrganization {
  type: string;
  payload: boolean;
}

export interface IActionExpandOrganization extends IActionChangeShowHiddenOrganization {}
export interface IActionSearchOrganization {
  type: string;
  payload: string;
}

// hide multi organization

export interface IPayloadHideMultiOrganization {
  orgIds: any[];
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}
export interface IHideMultiOrganizationAction {
  type: string;
  payload: IPayloadHideMultiOrganization;
}

// structure organization
export interface IStructureFormValues {
  children_ids: string[];
  parent_id: string;
}

export interface IPayloadUpdateSettingUpload {
  value: boolean;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IPayloadGetSettingUpload {
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string, data?: any) => void;
}
export interface IPayloadStructureOrganization {
  values: IStructureFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}
export interface IActionStructureOrganization {
  type: string;
  payload: IPayloadStructureOrganization;
}

export interface IActionUpdateSettingUpload {
  type: string;
  payload: IPayloadUpdateSettingUpload;
}

export interface IActionGetSettingUpload {
  type: string;
  payload: IPayloadGetSettingUpload;
}

export enum IIncidentAttrType {
  TYPE = 'TYPE',
  LABEL = 'LABEL',
  ACTION = 'ACTION',
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
}

export interface IElementIncidentType {
  editable: boolean;
  family: {
    code: string;
    title: string;
  };
  name: string;
  type: IIncidentAttrType;
  _id: string;
  color: string;
  org_id: string;
}

export interface IChangeNameOrg {
  org_id: string;
  new_name: string;
  old_name: string;
}
