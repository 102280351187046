import { Col, Form, Row } from 'antd';
import InputBase from 'components/Input';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import cms from 'services/cms';
import { validateEmail } from 'utils';
import throwException from 'utils/throwException';
import './styles.scss';

interface IProps {
  t: any;
  onRef?: (r: any) => any;
  i18n: any;
  editable?: boolean;
}

class FormAddUser extends Component<IProps> {
  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleOk = (values, callback) => {
    const { i18n } = this.props;
    const { language } = i18n;
    cms
      .createUserCms({ ...values, file_url: values.file_url || [], language })
      .then(() => {
        if (callback) callback(true);
      })
      .catch((e) => {
        throwException(e);
        if (callback) callback(false);
      });
  };

  render() {
    const { t, onRef, editable = true } = this.props;
    if (onRef) onRef(this);
    return (
      <div className="formAddLogin">
        <div style={{ fontSize: 21, fontWeight: 600, paddingBottom: 16 }}>
          {editable
            ? t('user.management.modal.create_new_user.title')
            : t('user.management.modal.detail_new_user.title')}
        </div>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('user.management.modal.create_new_user.field.last_name.required'),
                },
              ]}
              name="last_name"
              label={t('user.management.modal.create_new_user.field.last_name')}
            >
              <InputBase
                previewSpan
                disabled={!editable}
                placeholder={t('user.management.modal.create_new_user.field.last_name.placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('user.management.modal.create_new_user.field.first_name.required'),
                },
              ]}
              name="first_name"
              label={t('user.management.modal.create_new_user.field.first_name')}
            >
              <InputBase
                previewSpan
                disabled={!editable}
                placeholder={t(
                  'user.management.modal.create_new_user.field.first_name.placeholder'
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          rules={[
            {
              required: true,
              whitespace: false,
              message: t('user.management.modal.create_new_user.field.email.required'),
            },
            {
              validator: this.handleValidateEmail,
            },
          ]}
          name="email"
          label={t('user.management.modal.create_new_user.field.email')}
        >
          <InputBase
            previewSpan
            disabled={!editable}
            placeholder={t('user.management.modal.create_new_user.field.email.placeholder')}
          />
        </Form.Item>
      </div>
    );
  }
}

export default withTranslation()(FormAddUser);
