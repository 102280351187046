import { CallEffect } from 'redux-saga/effects';

export const PERMISSION_ROOT = 'root';

export interface IUser {
  email: string;
  first_name: string;
  language: string;
  last_name: string;
  permission: 'root' | '';
  phone: string;
  _id: string;
}

const initValue = {
  email: '',
  first_name: '',
  language: '',
  last_name: '',
  permission: '',
  phone: '',
};

const user = {
  namespace: 'user',
  state: {
    user: {
      email: '',
      first_name: '',
      language: '',
      last_name: '',
      permission: '',
      phone: '',
    },
  },
  effects: {
    *change(
      { payload, callback }: { payload: any; callback?: any },
      { put }: { call: CallEffect; put: any }
    ) {
      yield put({
        type: 'user/save',
        payload,
      });
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    save(state: IUser, action: any) {
      return {
        ...state,
        user: action.payload.user || initValue,
      };
    },
  },
};

export default user;
