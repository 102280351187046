import { Component, Fragment } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import throwException from 'utils/throwException';
import Dragger from 'antd/lib/upload/Dragger';
import upload from 'services/upload';
import debounce from 'lodash/debounce';
import './styles.scss';
import { showMessageError } from 'utils';

interface IProps {
  t: any;
  setFieldsValue: (v: any) => any;
  file_url: any[];
  setLoading: (flag: boolean) => any;
  placeholerKey?: string;
  accepts?: string[];
  onRef?: any;
  multiple?: boolean;
}

interface IState {
  error?: string;
}

class UploadFile extends Component<IProps, IState> {
  input?: any;
  constructor(props: IProps) {
    super(props);
    this.state = { error: undefined };
    this.handleOnChangeDebounce = debounce(this.handleOnChangeDebounce, 300);
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (typeof onRef === 'function') {
      onRef(this);
    }
  }

  shouldComponentUpdate(nProps: IProps, nState: IState) {
    const { file_url } = this.props;
    const { error } = this.state;
    return file_url !== nProps.file_url || error !== nState.error;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (typeof onRef === 'function') {
      onRef(undefined);
    }
  }

  setError = (errorMessage?: string | any) => {
    const { error } = this.state;
    if (error !== errorMessage) {
      this.setState({ error: errorMessage });
    }
  };

  handleOnChangeDebounce = (data: any) => {
    this.handleOnChange({ fileList: data.fileList?.map?.((e) => e.originFileObj) });
  };

  handleOnChange = async ({ fileList }: any) => {
    const {
      setFieldsValue,
      setLoading,
      file_url = [],
      accepts = ['application/pdf'],
      t,
      multiple = true,
    } = this.props;
    try {
      if ([...file_url, ...fileList].length > 10) {
        return showMessageError(t('report.message.warning.upload.more_than_10_files'));
      }
      let checkFalse = false;
      const files: any[] = [];
      for (const file of fileList) {
        if (file.size > 104857600) {
          checkFalse = true;
          showMessageError(t('upload.file.size.to_lager'));
          break;
        }
        if (file.size <= 0) {
          checkFalse = true;
          showMessageError(t('upload.file.size.to_empty'));
          break;
        }
        if (!accepts.includes(file.type)) {
          checkFalse = true;
          showMessageError(t('upload.file.type.invalid'));

          break;
        }
        files.push(file);
      }
      if (!files.length || checkFalse) {
        return;
      }
      if (!multiple && files.length > 1) {
        return;
      }
      setLoading(true);

      const { data } = await upload.getUrlUpload({
        files: files.map((file) => encodeURIComponent(file.name)),
      });
      const dataUrls = data?.data?.map?.((e) => ({
        ...e,
        original_name: decodeURIComponent(e.original_name),
      }));
      if (dataUrls) {
        const headers = {
          'Content-Type': 'application/pdf',
          'Access-Control-Allow-Origin': '*',
        };
        const promise = dataUrls.map((e) => {
          const file = files.find((el) => el.name === e.original_name);
          if (!file) return null;
          return upload.upload(e.url, { headers }, file);
        });

        await Promise.all(promise);
        if (multiple) {
          setFieldsValue({ file_url: [...file_url, ...dataUrls] });
        } else {
          setFieldsValue({ file_url: dataUrls });
        }
        this.setState({ error: undefined });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throwException(e);
    }
  };

  handleGetUrl = async (files = []) => {
    if (!Array.isArray(files) || !files.length) return {};
    const { data } = await upload.preview({ files: files.map((e: any) => e.fileKey) });
    return data;
  };

  handleRemoveImage = (index: number) => {
    const { setFieldsValue, file_url = [] } = this.props;
    const newFile = file_url.filter((_e, i: number) => index !== i);
    setFieldsValue({ file_url: newFile });
  };

  handleRenderSize = (size) => {
    const { t } = this.props;
    const kb = size / 1024;
    if (kb < 1024) {
      return t('content.management.article.size.KB', { name: kb.toFixed(2) });
    }
    const mb = kb / 1024;
    return t('content.management.article.size.MB', { name: mb.toFixed(2) });
  };

  render() {
    const {
      t,
      file_url = [],
      placeholerKey,
      accepts = ['application/pdf'],
      multiple = true,
    } = this.props;
    const { error } = this.state;
    return (
      <Fragment>
        {file_url.map((el: any, index: number) => {
          const nameFile = el.original_name || el.metadata?.[0] || '';
          return (
            <div
              key={index.toString()}
              style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}
            >
              <div className="previewPDFText">{nameFile.split('.').pop()}</div>
              <div
                style={{
                  paddingLeft: 10,
                  maxWidth: '80%',
                  whiteSpace: 'pre',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {decodeURIComponent(nameFile)}
              </div>
              <img
                onClick={() => this.handleRemoveImage(index)}
                style={{ marginLeft: 22, cursor: 'pointer' }}
                width={20}
                height={20}
                src="/images/remove-image.png"
                alt=""
              />
            </div>
          );
        })}
        <Dragger
          openFileDialogOnClick={false}
          multiple={multiple}
          fileList={[]}
          customRequest={() => null}
          accept={accepts.toString()}
          onChange={this.handleOnChangeDebounce}
          style={{
            marginTop: 24,
            borderColor: error ? '#ff4d4f' : '#d9d9d9',
            cursor: 'default',
            userSelect: 'none',
          }}
        >
          <p className="ant-upload-text">
            <CloudUploadOutlinedIcon
              style={{ marginRight: 5, color: 'rgba(0, 0, 0, 0.54)', marginTop: -3 }}
            />
            {t(
              placeholerKey || 'content.management.modal.create_new.field.upload_file_notice_drag'
            )}
            <span
              style={{ color: '#18C9F3', marginLeft: 5, cursor: 'pointer' }}
              onClick={() => this.input?.click?.()}
            >
              {t('content.management.modal.create_new.field.upload_file_notice_drag_browser')}
            </span>
            {t('content.management.modal.create_new.field.upload_file_notice_drag_browser2')}
          </p>
        </Dragger>
        <input
          onChange={(e) => {
            const { files } = e.target;
            this.handleOnChange({ fileList: files });
            e.target.value = '';
          }}
          accept={accepts.toString()}
          multiple={multiple}
          ref={(ref) => (this.input = ref)}
          style={{ display: 'none' }}
          type="file"
        />
        <div style={{ color: '#ff4d4f', height: 24 }}>{error}</div>
      </Fragment>
    );
  }
}

export default UploadFile;
