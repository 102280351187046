import { logout, showMessageError } from 'utils';
import i18n from 'i18next';

export default function throwException(e, callback?: { callbackCheck?: any; callbackError?: any }) {
  const { status, data } = e.response || {};
  const { error_code, error_message } = data || {};
  const { callbackCheck, callbackError } = callback || {};
  if (status === 401) {
    logout();
    window.location.href = '/login';
  } else if (callbackCheck?.(error_code, error_message)) {
    callbackError?.(error_code, error_message);
  } else {
    if (e.message === 'Network Error') {
      showMessageError(i18n.t('network.disconnected'));
    } else {
      if (e.message.includes('timeout of')) {
        showMessageError(i18n.t('error.network.timeout'));
      } else {
        showMessageError(error_code ? i18n.t(`errors.code.${error_code}`) : e.message);
      }
    }
  }
}
