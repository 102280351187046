import { Button, Modal, Spin } from 'antd';
import EmptyBase from 'components/Empty';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import account from 'services/account';
import throwException from 'utils/throwException';
import './history-log.scss';
interface IProps {
  onRef?: (v: any) => any;
  t: any;
}

interface IState {
  visible: boolean;
  data: any[];
  record: any;
  loading: boolean;
}

class ModalHistory extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, data: [], loading: true, record: undefined };
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { loading, visible, data } = this.state;
    return loading !== nState.loading || visible !== nState.visible || data !== nState.data;
  }

  handleVisible = (record?: any) => {
    this.setState({ visible: !!record, data: [], record });
    if (record) this.handleGetListHistory(record);
  };

  handleGetListHistory = (record: any) => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    account
      .logAccount(record._id)
      .then(({ data }) => {
        this.setState({ loading: false, data: data.data });
      })
      .catch(throwException);
  };

  renderAction = (logItem: any, t: any) => {
    const message = t(`account.management.log.type${logItem.category}`, {
      ...logItem,
    });
    if (logItem.category === '8') {
      //update
      const changeMessage = logItem?.old_value
        ?.map((item, index) => {
          if (logItem?.old_value[index] === logItem?.new_value[index]) return '';
          if (logItem?.old_value[index] === '')
            return t('account.management.log.add', {
              new_value: logItem?.new_value[index],
            });
          if (logItem?.new_value[index] === '')
            return t('account.management.log.remove', {
              old_value: logItem?.old_value[index],
            });
          let message = t('account.management.log.update_change', {
            old_value: logItem?.old_value[index],
            new_value: logItem?.new_value[index],
          });
          return message.substring(0, message.length - 1);
        })
        .filter((i) => !!i)
        .join(', ');
      return `${message} ${changeMessage}`;
    }
    return message;
  };

  handleExport = () => {
    const { record } = this.state;
    const params: any = {
      standby_token: localStorage.getItem('access_token'),
    };
    const queryString = new URLSearchParams(params).toString();
    window.open(
      process.env.REACT_APP_API_URL + `/organization-cms/log/export/${record._id}?` + queryString
    );
  };

  render() {
    const { onRef, t } = this.props;
    if (onRef) onRef(this);
    const { visible, loading, data } = this.state;
    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={[
          <Button type="primary" style={{ height: 40, marginLeft: 12 }} onClick={this.handleExport}>
            {t('history_log.button.export.title')}
          </Button>,
          <Button
            onClick={() => this.handleVisible(undefined)}
            style={{ height: 40, marginLeft: 12 }}
          >
            {t('common.button.close')}
          </Button>,
        ]}
        title={t('account.management.modal.activity_log.title')}
        visible={visible}
        className="modal modal-log"
        width={791}
      >
        <Spin spinning={loading}>
          <div>
            <div style={{ margin: '0 24px 24px' }}>
              {!data?.length ? (
                <div style={{ paddingTop: 50 }}>
                  <EmptyBase />
                </div>
              ) : null}
              {data.map((logItem: any, index: number) => (
                <div
                  key={index.toString()}
                  style={{
                    backgroundColor: 'rgba(144, 147, 153, 0.1)',
                    borderRadius: 4,
                    padding: '11px 8px',
                    marginTop: 16,
                  }}
                >
                  <div>
                    <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
                      {logItem.cms_admin_name}
                      <div
                        style={{
                          backgroundColor: '#C4C4C4',
                          width: 4,
                          height: 4,
                          margin: '0 8px',
                          borderRadius: 50,
                        }}
                      />
                      <span style={{ color: '#818181', fontSize: 13 }}>
                        {moment(logItem.time).format('YYYY/MM/DD HH:mm')}
                      </span>
                    </div>
                    <div style={{ fontSize: 17, paddingTop: 18 }}>
                      {this.renderAction(logItem, t)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

export default withTranslation()(ModalHistory);
