/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Input, InputProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { Component, useCallback, useRef, useState } from 'react';

class InputBase extends Component<InputProps & { previewSpan?: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      valueInputDefault: '',
    };
  }
  static Password: (props: InputProps) => JSX.Element;
  static TextArea: (props: TextAreaProps) => JSX.Element;
  onChange = (e) => {
    const { onChange, maxLength = 256 } = this.props;
    e.target.value = e.target.value.substring(0, maxLength);
    if (onChange) {
      onChange(e);
    }
  };
  render() {
    const {
      style,
      maxLength = 256,
      disabled,
      previewSpan,
      value,
      placeholder,
      ...props
    } = this.props;
    const { valueInputDefault }: any = this.state;

    if (previewSpan && disabled) {
      return (
        <div
          style={{
            wordBreak: 'break-word',
            backgroundColor: '#f5f5f5',
            minHeight: 42,
            borderRadius: 4,
            border: '1px solid #d9d9d9',
            display: 'flex',
            alignItems: 'center',
            padding: '4px 11px',
            color: '#b8b8b8',
          }}
        >
          {valueInputDefault || value}
        </div>
      );
    }

    return (
      <Input
        allowClear
        maxLength={maxLength}
        style={{ height: 42, borderRadius: 4, ...style }}
        {...props}
        disabled={disabled}
        defaultValue={valueInputDefault || ''}
        value={value}
        onChange={this.onChange}
        placeholder={disabled ? '' : placeholder}
      />
    );
  }
}

InputBase.Password = (props: InputProps) => {
  const { style, disabled, placeholder, ...p } = props;
  return (
    <Input.Password
      allowClear
      style={{ height: 42, borderRadius: 4, ...style }}
      {...p}
      disabled={disabled}
      placeholder={disabled ? '' : placeholder}
    />
  );
};

const TextArea = (props) => {
  const { style, onChange, className, disabled, placeholder, ...p } = props;
  const inputArea: any = useRef();

  const [classNameCustom, setClassName] = useState('');

  const handleChange = useCallback(
    (v: any) => {
      onChange?.(v);
      const area = inputArea.current?.resizableTextArea?.textArea;
      if (!area) {
        return;
      }
      const { scrollHeight, clientHeight } = area;
      if (scrollHeight > clientHeight) {
        if (!classNameCustom) {
          setClassName('text-area-scroll');
        }
      } else if (classNameCustom) {
        setClassName('');
      }
    },
    [onChange, classNameCustom]
  );

  return (
    <span className={classNameCustom}>
      <Input.TextArea
        ref={inputArea}
        allowClear
        style={{ minHeight: 42, borderRadius: 4, ...style }}
        {...p}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </span>
  );
};

InputBase.TextArea = TextArea;

export default InputBase;
