import { Button, Form, Row, Col, Spin } from 'antd';
import modal from 'antd/lib/modal';
import InputBase from 'components/Input';
import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IUser } from 'sagas/models/user';
import cms from 'services/cms';
import { showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';

interface IProps {
  t: any;
  user: IUser;
  history: any;
  pathname: string;
  form: any;
  dispatch: any;
}

interface IData {
  first_name: string;
  last_name: string;
  phone?: string;
  language: string;
  email: string;
}

interface IState {
  data: IData[];
  loading: boolean;
  totalDocs: number;
}

class MyProfile extends Component<IProps, IState> {
  refFormAdd: any;
  page_number: number;
  namesearch: any;
  modalWarning: any;
  modalDetail: any;

  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: true, totalDocs: 0 };
    this.page_number = 1;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    cms
      .usersCms({ page_size: 10, page: this.page_number, name: this.namesearch })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  handleDetail = (record: any) => {
    this.modalDetail?.handleVisible?.(record);
  };

  handleResetPassword = () => {
    const { t } = this.props;
    this.modalWarning?.destroy();
    this.setState({ loading: true });
    cms
      .resetPassword()
      .then(() => {
        showMessageSuccess(t('auth.my_profile.reset_passwors.success'));
      })
      .catch(throwException)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCancelShowResetPassword = () => {
    // destroy
    this.modalWarning?.destroy();
  };

  handleUpdateInfor = (value) => {
    const { t, user, dispatch } = this.props;
    this.setState({ loading: true });
    cms
      .updateUser(user._id, value)
      .then((res) => {
        let new_data = res.data.data;
        new_data._id = user._id;
        dispatch({
          type: 'user/change',
          payload: { user: { ...user, ...new_data } },
          callback: () => {},
        });
        showMessageSuccess(t('auth.my_profile.update.success'));
        this.setState({ loading: false });
      })
      .catch(throwException)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleShowResetPassword = () => {
    const { t } = this.props;
    const config = {
      title: t('auth.my_profile.change_password'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 20 }}>
            <span>{t('auth.my_profile.reset_passwors.content1')}</span>
            <br></br>
            <div style={{ marginTop: 20 }}>
              <span>{t('auth.my_profile.reset_passwors.content2')}</span>
            </div>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              // danger
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleResetPassword()}
            >
              {t('auth.my_profile.reset_passwors.okBtn')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelShowResetPassword}>
              {t('account.management.modal.edit.button.close')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 807,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };
  renderDataLanguage = () => {
    return [
      { value: 'en', title: 'English' },
      { value: 'jp', title: 'Japan' },
    ];
  };

  render() {
    const { loading } = this.state;
    const { user, t } = this.props;
    return (
      <Spin spinning={loading}>
        {/* <div
          style={{
            fontSize: 17,
            backgroundColor: '#F9F9F9',
            height: 60,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ marginLeft: 50 }}>{t('auth.my_profile.title')}</span>
        </div> */}
        <div style={{ width: '50%', marginLeft: 50, marginBottom: 100 }}>
          <Form
            onFinish={(value) => {
              this.handleUpdateInfor(value);
            }}
            style={{ paddingTop: 30 }}
            colon={false}
            layout="vertical"
            autoComplete="off"
            className="form-login-success"
            initialValues={user}
            scrollToFirstError
          >
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t('user.management.modal.create_new_user.field.last_name.required'),
                    },
                  ]}
                  name="last_name"
                  label={t('user.management.modal.create_new_user.field.last_name')}
                >
                  <InputBase
                    disabled={false}
                    placeholder={t(
                      'user.management.modal.create_new_user.field.last_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t('user.management.modal.create_new_user.field.first_name.required'),
                    },
                  ]}
                  name="first_name"
                  label={t('user.management.modal.create_new_user.field.first_name')}
                >
                  <InputBase
                    disabled={false}
                    placeholder={t(
                      'user.management.modal.create_new_user.field.first_name.placeholder'
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 32]}>
              <Col span={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: t('user.management.modal.create_new_user.field.email.required'),
                    },
                  ]}
                  name="email"
                  label={t('user.management.modal.create_new_user.field.email')}
                >
                  <InputBase
                    disabled={true}
                    placeholder={t('user.management.modal.create_new_user.field.email.placeholder')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                onClick={() => this.handleShowResetPassword()}
                style={{ borderColor: '#18BCF3', color: '#18BCF3' }}
              >
                {t('auth.my_profile.change_password')}
              </Button>
            </Row>
            <div
              style={{
                marginTop: 20,
                // marginBottom: 10,
                height: 1,
                backgroundColor: '#DDDDDE',
              }}
            ></div>
            <Row style={{ marginTop: 20 }}>
              <Button type="primary" htmlType="submit">
                {t('account.management.modal.edit.button.save')}
              </Button>
            </Row>
          </Form>
        </div>
      </Spin>
    );
  }
}

export default connect(({ user }: { user: { user: IUser } }) => ({ user: user.user }))(
  withTranslation()(MyProfile)
);
