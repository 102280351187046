import SwitchBox from 'components/SwitchBox';
import TableBase from 'components/TableBase';
import moment from 'moment';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { IUser } from 'sagas/models/user';
import content from 'services/content';
import throwException from 'utils/throwException';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Button } from 'antd';
import modal from 'antd/lib/modal';
import { showMessageSuccess } from 'utils';
import FormAdd from './FormAdd';
import ModalEdit from './ModalEdit';
import ModalPublic from './ModalPublic';

interface IProps {
  t: any;
  user: IUser;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
}

class Article extends Component<IProps, IState> {
  refFormAdd: any;
  page_number: number;
  namesearch: any;
  modalWarning: any;
  modalEdit: any;
  modaPublic: any;
  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: true, totalDocs: 0 };
    this.page_number = 1;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    content
      .list({
        page_size: 10,
        page: this.page_number,
        // name: this.namesearch,
        content_type: 'ARTICLE',
      })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch(this.handleError);
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  handleChangePublic = (checked: boolean, record: any, callback) => {
    this.handleCancelModal();
    const { t } = this.props;
    this.setState({ loading: true });
    content
      .public(record._id)
      .then(() => {
        this.getData();
        if (callback) callback();
        if (checked) {
          showMessageSuccess(t('content.management.article.modal.public_success'));
        } else {
          showMessageSuccess(t('content.management.article.modal.unpublic_success'));
        }
      })
      .catch(this.handleError);
  };

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('content.management.article.column.title'),
        dataIndex: 'title',
        width: 450,
      },
      {
        title: t('content.management.article.column.status'),
        dataIndex: 'published',
        width: 200,
        render: (v: boolean, record: any) => (
          <SwitchBox
            checked={v}
            onChange={(v) => {
              if (!v) {
                this.handleWarningPublic(v, record);
              } else {
                this.handleModalPiblic(record);
              }
            }}
            size="small"
            text={
              v
                ? t('content.management.article.column.published')
                : t('content.management.article.column.unpublished')
            }
          />
        ),
      },
      {
        title: t('content.management.article.column.created_at'),
        dataIndex: 'createdAt',
        width: 200,
        render: (v) => moment(v).format('YYYY/MM/DD HH:mm'),
      },
      {
        title: t('content.management.article.column.date_published'),
        dataIndex: 'date_published',
        width: 200,
        render: (v) => (v ? moment(v).format('YYYY/MM/DD HH:mm') : ''),
      },
      {
        title: '',
        width: 140,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          return (
            <Fragment>
              <ModeEditOutlineOutlinedIcon
                fontSize="small"
                onClick={() => this.handleEdit(record)}
                style={{
                  userSelect: 'none',
                  cursor: !record.published ? 'pointer' : 'default',
                  opacity: !record.published ? 1 : 0.3,
                }}
              />
              <DeleteOutlineIcon
                fontSize="small"
                style={{
                  userSelect: 'none',
                  cursor: !record.published ? 'pointer' : 'default',
                  marginLeft: 30,
                  opacity: !record.published ? 1 : 0.3,
                }}
                onClick={() => this.handleWarningDelete(record)}
              />
            </Fragment>
          );
        },
      },
    ];
    return columns;
  };

  handleRemove = (record: any) => {
    if (record.published) return;
    const { t } = this.props;
    this.setState({ loading: true });
    this.handleCancelModal();
    content
      .delete(record._id)
      .then(() => {
        this.page_number = 1;
        this.namesearch = '';
        this.getData();
        showMessageSuccess(
          t('content.management.article.modal.warning.delete_article.delete_success')
        );
      })
      .catch(this.handleError);
  };

  handleCancelModal = () => {
    this.modalWarning?.destroy?.();
  };

  handleModalPiblic = (record: any) => {
    this.modaPublic?.handleVisible?.(record);
  };

  handleWarningPublic = (_checked, record: any, callback?: any) => {
    const { t } = this.props;
    const { published } = record;
    const config = {
      title: published
        ? t('content.management.article.modal.warning.unpublish_article.title')
        : t('content.management.article.modal.warning.publish_article.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>
              {published
                ? t('content.management.article.modal.warning.content_unpublic')
                : t('content.management.article.modal.warning.content_public')}
            </span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger={record.published}
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleChangePublic(_checked, record, callback)}
            >
              {!published
                ? t('content.management.button.publish')
                : t('content.management.button.unpublish')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelModal}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleWarningDelete = (record: any) => {
    const { t } = this.props;
    const { published } = record;
    if (published) return;
    const config = {
      title: t('content.management.article.modal.warning.delete_article.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('content.management.article.modal.warning.delete_article.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleRemove(record)}
            >
              {t('content.management.article.modal.warning.delete_article.button_delete')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelModal}>
              {t('account.management.modal.warning_turn_off_ip.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleOkAdd = (values, callback) => {
    const { t } = this.props;
    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.getData();
        showMessageSuccess(t('content.management.article.create_success'));
      }
    });
  };

  handleEdit = (record) => {
    const { published } = record;
    if (published) return;
    this.modalEdit?.handleVisible?.(record);
  };

  handlePublic = (record: any, callback: any) => {
    this.handleWarningPublic(true, record, callback);
  };

  render() {
    const { data, loading, totalDocs } = this.state;
    const { onRef } = this.props;
    if (onRef) onRef(this);

    return (
      <Fragment>
        <ModalEdit
          onRef={(ref) => {
            this.modalEdit = ref;
          }}
          reloadData={this.getData}
        />
        <ModalPublic
          handlePublic={this.handlePublic}
          onRef={(ref) => {
            this.modaPublic = ref;
          }}
          reloadData={this.getData}
        />
        <TableBase
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          formAdd={(props) => (
            <FormAdd
              isCreate
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
          onOkFormAdd={this.handleOkAdd}
          loading={loading}
          lableKeyI18n="content.management.button.add_new_article"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          allowAdd
        />
      </Fragment>
    );
  }
}

export default connect(({ user }: { user: { user: IUser } }) => ({ user: user.user }))(Article);
