import { Button, Modal, Spin } from 'antd';
import EmptyBase from 'components/Empty';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import cms from 'services/cms';
import throwException from 'utils/throwException';
import './history-log.scss';
import { get, isEqual } from 'lodash';
interface IProps {
  onRef?: (v: any) => any;
  t: any;
}

interface IState {
  visible: boolean;
  data: any[];
  record: any;
  loading: boolean;
}

class ModalHistory extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, data: [], loading: true, record: undefined };
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { loading, visible, data } = this.state;
    return loading !== nState.loading || visible !== nState.visible || data !== nState.data;
  }

  handleVisible = (record?: any) => {
    this.setState({ visible: !!record, data: [], record });
    if (record) this.handleGetListHistory(record);
  };

  handleGetListHistory = (record: any) => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    cms
      .getLogUser(record._id)
      .then(({ data }) => {
        this.setState({ loading: false, data: data.data });
      })
      .catch(throwException);
  };

  getDataUpdateArticle = (newValue, oldValue) => {
    const { t } = this.props;
    let result = '';
    Object.keys(newValue).forEach((key) => {
      if (!oldValue[key] || newValue[key] !== oldValue[key]) {
        if (key === 'file_url') {
          let oldFile = get(oldValue[key], '[0].fileKey');
          let newFile = get(newValue[key], '[0].fileKey');
          if (oldFile !== newFile) {
            result +=
              (oldFile || '') +
              t('content.management.text1') +
              newFile +
              t('content.management.text2');
          }
        } else {
          result +=
            (oldValue[key] || '') +
            t('content.management.text1') +
            newValue[key] +
            t('content.management.text2');
        }
      }
    });
    result = result.substring(0, result.length - 1);
    return result;
  };

  getDataUpdateAccount = (newValue, oldValue) => {
    const { t } = this.props;
    let result = '';
    newValue.forEach((item, index) => {
      if ((item || oldValue[index]) && item !== oldValue[index]) {
        result +=
          oldValue[index] + t('content.management.text1') + item + t('content.management.text2');
      }
    });
    result = result.substring(0, result.length - 1);
    return result;
  };

  getDataUpdateNotice = (newValue, oldValue) => {
    const { t } = this.props;
    let result = '';
    Object.keys(newValue).forEach((key) => {
      if (!oldValue[key] || newValue[key] !== oldValue[key]) {
        if (key === 'file_url') {
          let dataOldValue: any[] = [];
          let dataNewValue: any[] = [];
          oldValue[key].forEach((item, index) => {
            dataOldValue.push(get(item, 'original_name', ''));
          });
          newValue[key].forEach((item, index) => {
            dataNewValue.push(get(item, 'original_name', ''));
          });
          if (!isEqual(dataOldValue.sort(), dataNewValue.sort())) {
            result +=
              (dataOldValue.join(',') || '') +
              t('content.management.text1') +
              (dataNewValue.join(',') || '') +
              t('content.management.text2');
          }
        } else {
          result +=
            (oldValue[key] || '') +
            t('content.management.text1') +
            newValue[key] +
            t('content.management.text2');
        }
      }
    });
    result = result.substr(0, result.length - 1);
    return result;
  };

  getDataUpdateMaintance = (newValue, oldValue) => {
    const { t } = this.props;
    let result = '';
    Object.keys(newValue).forEach((key) => {
      if (!oldValue[key] || newValue[key] !== oldValue[key]) {
        if (key !== 'file_url' && key !== '_id' && key !== 'id') {
          if (key === 'end_schedule' || key === 'start_schedule') {
            result +=
              moment(oldValue[key] || '').format('YYYY-MM-DD HH:mm:ss') +
              t('content.management.text1') +
              moment(newValue[key]).format('YYYY-MM-DD HH:mm:ss') +
              t('content.management.text2');
          } else {
            result +=
              (oldValue[key] || '') +
              t('content.management.text1') +
              newValue[key] +
              t('content.management.text2');
          }
        }
      }
    });
    result = result.substring(0, result.length - 1);
    return result;
  };

  getDataNewTermPrivacy = (item) => {
    let dataResult = '';
    dataResult += item.version_id + '、';
    dataResult += item.title;
    dataResult += item.memo ? `、${item.memo}` : '';
    return dataResult;
  };
  renderLogTermsPravicy = (value) => {
    const { t } = this.props;
    const { type, device_app } = value;
    const dataNew = this.getDataNewTermPrivacy(value);
    switch (type) {
      case 'TERM':
        switch (device_app) {
          case 'ADMIN_SYS':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_term'),
              device_type: t('device_app.web_admin'),
              data_new: dataNew,
            });
          case 'MOBILE':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_term'),
              device_type: t('device_app.mobile_app'),
              data_new: dataNew,
            });
          case 'WEB_APP':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_term'),
              device_type: t('device_app.web_app'),
              data_new: dataNew,
            });
          default:
            break;
        }
        break;

      default:
        switch (device_app) {
          case 'ADMIN_SYS':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_privacy'),
              device_type: t('device_app.web_admin'),
              data_new: dataNew,
            });
          case 'MOBILE':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_privacy'),
              device_type: t('device_app.mobile_app'),
              data_new: dataNew,
            });
          case 'WEB_APP':
            return t('user.cms.history_log.term_pravicy', {
              type: t('type_privacy'),
              device_type: t('device_app.web_app'),
              data_new: dataNew,
            });
          default:
            break;
        }
        break;
    }
  };

  renderAction = (logItem: any, t: any) => {
    switch (logItem.action) {
      case 'LOG_IN_SUCCESS':
        return t('user.cms.history_log.login_success', {
          email: get(logItem, 'user_cms_id.email'),
          ip_address: get(logItem, 'extra_data.ip_address', ''),
        });
      case 'LOG_IN_FAILED':
        return t('user.cms.history_log.login_failure', {
          email: get(logItem, 'user_cms_id.email'),
          ip_address: get(logItem, 'extra_data.ip_address', ''),
        });
      case 'FORGOT_PASSWORD':
        return t('user.cms.history_log.forgot_password');
      case 'PASSWORD_RESET_SUCCESS':
        return t('user.cms.history_log.set_new_password');
      case 'CREATE_USER':
        return t('user.cms.history_log.created_user', {
          user_name: `${get(logItem, 'extra_data.createdUser.last_name')} ${get(
            logItem,
            'extra_data.createdUser.first_name'
          )}`,
        });
      case 'DELETE_USER':
        return t('user.cms.history_log.delete_user', {
          user_name: `${get(logItem, 'extra_data.userUpdated.last_name')} ${get(
            logItem,
            'extra_data.userUpdated.first_name'
          )}`,
        });
      case 'ADD_ARTICLE':
        return t('user.cms.history_log.add_article', {
          article_id: get(logItem, 'extra_data.new_value._id'),
          article_title: get(logItem, 'extra_data.new_value.title'),
          article_url: get(logItem, 'extra_data.new_value.source_url'),
          file_url: get(logItem, 'extra_data.new_value.file_url[0].fileKey', ''),
        });
      case 'ADD_MAINTANCE':
        return t('user.cms.history_log.add_maintance', {
          maintance_id: get(logItem, 'extra_data.new_value._id'),
          maintance_title: get(logItem, 'extra_data.new_value.title'),
          maintance_note: get(logItem, 'extra_data.new_value.content'),
          maintance_time: moment(
            get(logItem, 'extra_data.new_value.start_schedule', new Date())
          ).format('YYYY/MM/DD HH:mm'),
        });
      case 'ADD_NOTICE':
        return t('user.cms.history_log.create_notice', {
          notice_id: get(logItem, 'extra_data.new_value._id'),
          notice_title: get(logItem, 'extra_data.new_value.title'),
          notice_url: get(logItem, 'extra_data.new_value.source_url'),
          file_url: get(logItem, 'extra_data.new_value.file_url[0].fileKey', ''),
        });
      case 'PUBLISHED_ARTICLE':
        return t('user.cms.history_log.publish_article', {
          id_article: get(logItem, 'extra_data._id'),
          status_update: get(logItem, 'extra_data.published')
            ? t('status_update_publish')
            : t('status_update_un_publish'),
        });
      case 'PUBLISHED_MAINTANCE':
        return t('user.cms.history_log.notify_maintance', {
          maintance_id: get(logItem, 'extra_data._id'),
        });
      case 'PUBLISHED_NOTICE':
        return t('user.cms.history_log.publish_notice', {
          id_notice: get(logItem, 'extra_data._id'),
          status_update: get(logItem, 'extra_data.published')
            ? t('status_update_publish')
            : t('status_update_un_publish'),
        });
      case 'DELETE_ARTICLE':
        return t('user.cms.history_log.delete_article', {
          id_article: get(logItem, 'extra_data.contentDetail._id'),
        });
      case 'DELETE_MAINTANCE':
        return t('user.cms.history_log.delete_maintance', {
          maintance_id: get(logItem, 'extra_data.contentDetail._id'),
        });
      case 'DELETE_NOTICE':
        return t('user.cms.history_log.delete_notice', {
          id_notice: get(logItem, 'extra_data.contentDetail._id'),
        });
      case 'UPDATE_ARTICLE':
        const dataUpdate = this.getDataUpdateArticle(
          get(logItem, 'extra_data.new_value', {}),
          get(logItem, 'extra_data.old_value', {})
        );
        return t('user.cms.history_log.update_article', {
          id_article: get(logItem, 'extra_data.old_value._id'),
          data_update: dataUpdate,
        });
      case 'UPDATE_MAINTANCE':
        const dataUpdateMaintance = this.getDataUpdateMaintance(
          get(logItem, 'extra_data.new_value', {}),
          get(logItem, 'extra_data.old_value', {})
        );
        return t('user.cms.history_log.update_maintance', {
          data_update: dataUpdateMaintance,
          maintance_id: get(logItem, 'extra_data.old_value._id'),
        });
      case 'UPDATE_NOTICE':
        const dataUpdateNotice = this.getDataUpdateNotice(
          get(logItem, 'extra_data.new_value', {}),
          get(logItem, 'extra_data.old_value', {})
        );
        return t('user.cms.history_log.update_notice', {
          data_update: dataUpdateNotice,
          id_notice: get(logItem, 'extra_data.old_value._id'),
        });
      case 'FINISH_MAINTANCE':
        return 'FINISH_MAINTANCE';
      case 'CANCEL_MAINTANCE':
        return t('user.cms.history_log.cancel_maintance', {
          maintance_id: get(logItem, 'extra_data.contentDetail._id'),
        });
      case 'COMPLETED_MAINTANCE':
        return t('user.cms.history_log.finish_maintance', {
          maintance_id: get(logItem, 'extra_data.contentDetail._id'),
        });
      case 'URL_SUPPORT':
        return t('user.cms.history_log.url_support', {
          url_support: get(logItem, 'extra_data.new_value.url_support'),
        });
      case 'TERMS_PRIVACY':
        const titleLog = this.renderLogTermsPravicy(get(logItem, 'extra_data.new_value'));
        return titleLog;
      case 'CREATE_ACCOUNT_MANAGEMENT':
        return t('user.cms.history_log.create_account', {
          account_name: get(logItem, 'new_value.name'),
        });
      case 'UPDATE_ACCOUNT_MANAGEMENT':
        const dataUpdateAccount = this.getDataUpdateAccount(
          get(logItem, 'new_value', []),
          get(logItem, 'old_value', [])
        );
        return t('user.cms.history_log.update_account', { data_update: dataUpdateAccount });
      case 'TURN_OF_IP_RESTRICTION_ACCOUNT_MANAGEMENT':
        return t('user.cms.history_log.turn_off_ip', {
          account_name: get(logItem, 'target_id.name'),
        });
      case 'UPDATE_ACTIVE':
        return t('user.cms.history_log.update_active', {
          account_name: get(logItem, 'target_id.name'),
          value: get(logItem, 'extra_data.action'),
        });
      case 'ADD_NEW_ADMIN':
        return t('user.cms.history_log.new_admin', {
          account_name: get(logItem, 'target_id.name'),
          admin_name: get(logItem, 'extra_data.name'),
        });
      case 'DELETE_ACCOUNT':
        return t('user.cms.history_log.delete_account', {
          account_name: get(logItem, 'extra_data.name'),
        });
      case 'NOT_PERMISSION':
        const typeAction = t(
          `not_permission.type_action.${get(logItem, 'extra_data.action_type')}`
        );
        return t('user.cms.history_log.not_permission', {
          email: `${get(logItem, 'user_cms_id.email')}`,
          type_action: typeAction,
          ip_address: `${get(logItem, 'extra_data.ip_address')}`,
        });
      case 'PASSWORD_CREATE_SUCCESS':
        return t('user.cms.history_log.create_new_password');
      default:
        return logItem.action;
    }
  };

  handleExport = () => {
    const { record } = this.state;
    const params: any = {
      standby_token: localStorage.getItem('access_token'),
    };
    const queryString = new URLSearchParams(params).toString();
    window.open(
      process.env.REACT_APP_API_URL + `/admin-cms/log-admin-cms/export/${record._id}?` + queryString
    );
  };

  render() {
    const { onRef, t } = this.props;
    if (onRef) onRef(this);
    const { visible, loading, data } = this.state;
    return (
      <Modal
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={[
          <Button type="primary" style={{ height: 40, marginLeft: 12 }} onClick={this.handleExport}>
            {t('history_log.button.export.title')}
          </Button>,
          <Button
            onClick={() => this.handleVisible(undefined)}
            style={{ height: 40, marginLeft: 12 }}
          >
            {t('common.button.close')}
          </Button>,
        ]}
        title={t('account.management.modal.activity_log.title')}
        visible={visible}
        className="modal modal-log"
        width={791}
      >
        <Spin spinning={loading}>
          <div>
            <div style={{ margin: '0 24px 24px' }}>
              {!data?.length ? (
                <div style={{ paddingTop: 50 }}>
                  <EmptyBase />
                </div>
              ) : null}
              {data.map((logItem: any, index: number) => (
                <div
                  key={index.toString()}
                  style={{
                    backgroundColor: 'rgba(144, 147, 153, 0.1)',
                    borderRadius: 4,
                    padding: '11px 8px',
                    marginTop: 16,
                  }}
                >
                  <div>
                    <div style={{ fontSize: 15, display: 'flex', alignItems: 'center' }}>
                      {`${get(logItem, 'user_cms_id.last_name')} ${get(
                        logItem,
                        'user_cms_id.first_name'
                      )}`}
                      <div
                        style={{
                          backgroundColor: '#C4C4C4',
                          width: 4,
                          height: 4,
                          margin: '0 8px',
                          borderRadius: 50,
                        }}
                      />
                      <span style={{ color: '#818181', fontSize: 13 }}>
                        {moment(get(logItem, 'createdAt')).format('YYYY/MM/DD HH:mm')}
                      </span>
                    </div>
                    <div style={{ fontSize: 17, paddingTop: 18 }}>
                      {this.renderAction(logItem, t)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </Modal>
    );
  }
}

export default withTranslation()(ModalHistory);
