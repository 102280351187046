import axiosConfig from 'config/axios';

const exports = {
  async exportBulkIncident(dataReq) {
    try {
      const data = await axiosConfig.post('/admin-cms/export/log-bulk-incident-v2', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async listExportBulkIncident(dataReq) {
    try {
      const data = await axiosConfig.get('/admin-cms/export/log-bulk-incident-v2', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default exports;
