import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import historyLog from 'services/history-log';
import throwException from 'utils/throwException';
import TableBase from 'components/TableBase';
import { find, get } from 'lodash';
import moment from 'moment';

interface IProps {
  t: any;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
}

interface SESStatus {
  status: string;
  notification: any;
  sent?: any;
  delivery?: any;
}

class LogSms extends Component<IProps, IState> {
  page_number: number;
  namesearch: any;
  category_notify: any;
  start_date: any;
  end_date: any;
  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: false, totalDocs: 0 };
    this.page_number = 1;
    this.namesearch = '';
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
  }

  shouldComponentUpdate(_, nState: IState) {
    const { loading, totalDocs, data } = this.state;
    return loading !== nState.loading || totalDocs !== nState.totalDocs || data !== nState.data;
  }

  renderCategoryNotifi = (): any[] => {
    const { t } = this.props;
    return [
      {
        value: '',
        text: t('history_log.option.category_notify.all'),
      },
      {
        value: 'new_incident',
        text: t('history_log.option.category_notify.new_incident'),
      },
      {
        value: 'new_message',
        text: t('history_log.option.category_notify.new_message'),
      },
    ];
  };

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  getData = () => {
    const { loading } = this.state;
    // if (!this.start_date || !this.end_date) {
    //   this.setState({ loading: false, data: [] });
    //   return;
    // }
    if (!loading) this.setState({ loading: true });

    historyLog
      .list({
        page_size: 10,
        page: this.page_number,
        search: this.namesearch,
        type: 'SMS',
        notice_category: this.category_notify || '',
        start_date: this.start_date
          ? moment(this.start_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 00:00:00'
          : null,
        end_date: this.end_date
          ? moment(this.end_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 23:59:59'
          : null,
      })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch(this.handleError);
  };

  exportLog = () => {
    const { loading } = this.state;
    // if (!this.start_date || !this.end_date) {
    //   this.setState({ loading: false, data: [] });
    //   return;
    // }
    if (!loading) this.setState({ loading: true });

    const params: any = {
      page_size: 10,
      page: this.page_number,
      search: this.namesearch || '',
      type: 'SMS',
      notice_category: this.category_notify || '',
      start_date: this.start_date
        ? moment(this.start_date, 'YYYY/MM/DD').format('YYYY/MM/DD HH:mm') + ' 00:00:00'
        : '',
      end_date: this.end_date
        ? moment(this.end_date, 'YYYY/MM/DD').format('YYYY/MM/DD HH:mm') + ' 23:59:59'
        : '',
      standby_token: localStorage.getItem('access_token'),
    };
    const queryString = new URLSearchParams(params).toString();
    window.open(process.env.REACT_APP_API_URL + '/admin/email-sms-log/excel?' + queryString);
    this.setState({ loading: false });
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    let category = this.renderCategoryNotifi();
    const columns = [
      {
        title: t('history_log.sms.column.title'),
        dataIndex: 'phone_number',
        width: 450,
      },
      {
        title: t('history_log.account_name.column.title'),
        dataIndex: 'org_root_id',
        width: 200,
        render: (v) => <div>{get(v, 'name')}</div>,
      },
      {
        title: t('history_log.org.column.title'),
        dataIndex: 'org_id',
        width: 200,
        render: (v) => <div>{get(v, 'name')}</div>,
      },
      {
        title: t('history_log.incident_id.column.title'),
        dataIndex: 'incident_id',
        width: 200,
        render: (v) => <div>{get(v, 'sequence')}</div>,
      },
      {
        title: t('history_log.notice_category.column.title'),
        dataIndex: 'notice_category',
        width: 200,
        render: (v) => {
          let getCategoryNotifi = find(category, (item) => {
            return get(item, 'value', '') === v;
          });
          return <div>{get(getCategoryNotifi, 'text')}</div>;
        },
      },
      {
        title: t('history_log.sent_time_standby.column.title'),
        dataIndex: 'standby_to_server',
        width: 200,
        render: (v) => <div>{moment(v).format('YYYY/MM/DD, HH:mm')}</div>,
      },
      {
        title: t('history_log.sent_status_standby.column.title'),
        dataIndex: 'status_send_to_server',
        width: 200,
        render: (v) => (
          <div>
            {v === 'OK'
              ? t('history_log.sent_from_server.status.OK')
              : t('history_log.sent_from_server.status.NG')}
          </div>
        ),
      },
      {
        title: t('history_log.sent_time_sms.column.title'),
        dataIndex: 'server_to_customer_logs',
        width: 200,
        render: (v) => (
          <div>
            {v[0] ? moment(`${v[0].notification.timestamp}Z`).format('YYYY/MM/DD, HH:mm') : ''}
          </div>
        ),
      },
      {
        title: t('history_log.tranmission_result.column.title'),
        dataIndex: 'server_to_customer_logs',
        width: 200,
        render: (v) => {
          let last_status = this.getCurrenStatus(v);
          return (
            <div>
              {last_status
                ? t(`history_log.sns_status.${last_status.status}`)
                : t(`history_log.sns_status.Pending`)}
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleSearch = (e) => {
    const { value = '' } = e.target;
    this.namesearch = value.trim();
    const { data } = this.state;
    this.page_number = 1;
    if (this.namesearch.length < 4) {
      if (data.length) {
        this.setState({ data: [], totalDocs: 0 });
      }
      return;
    }
    this.getData();
  };

  handleSearchOption = (value) => {
    const { data } = this.state;
    this.category_notify = value;
    this.page_number = 1;
    if (this.namesearch.length < 4) {
      if (data.length) {
        this.setState({ data: [], totalDocs: 0 });
      }
      return;
    }
    this.getData();
  };

  handleSearchDateTime = (value) => {
    const { data } = this.state;
    this.start_date = value[0];
    this.end_date = value[1];
    this.page_number = 1;
    if (this.namesearch.length < 4) {
      if (data.length) {
        this.setState({ data: [], totalDocs: 0 });
      }
      return;
    }
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  getCurrenStatus = (data: SESStatus[]) => {
    let status_sms = ['SUCCESS', 'FAILURE'];
    for (let i = 0; i < status_sms.length; i++) {
      let value = data.find((el) => el.status === status_sms[i]);
      if (value) return value;
    }
    return null;
  };

  render() {
    const { loading, data, totalDocs } = this.state;
    return (
      <Fragment>
        <TableBase
          style={{ margin: 0 }}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          handleSearch={this.handleSearch}
          handleSearchOption={this.handleSearchOption}
          dataOption={this.renderCategoryNotifi()}
          handleSearchDateTime={this.handleSearchDateTime}
          loading={loading}
          lableKeyI18n="content.management.button.add_new_article"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          typeSearch="phone"
          showSearch={false}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(LogSms);
