import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import routes from 'routes';

interface IProps {
  pathname: any;
  t: any;
  values?: { [key: string]: string };
  extendRightComponent?: any;
}

interface IState {
  route: any;
}

class BreadcrumbBase extends Component<IProps & WithTranslation & RouteComponentProps, IState> {
  constructor(props: IProps & WithTranslation & RouteComponentProps) {
    super(props);
    this.state = { route: {} };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { route } = this.state;
    const { values } = this.props;
    return route.path !== nState || values !== _nProps.values;
  }

  componentDidMount() {
    this.handleGetBreadcrumb();
  }

  handleGetBreadcrumb = () => {
    const { pathname } = this.props;
    let route = routes.find((el) => pathname.includes(el.path)) || {};
    if (route.menuLeft) {
      if (Array.isArray(route.menuLeft)) {
        const routeMenu = route.menuLeft.find((e) =>
          e.routes?.find((ee: any) => ee.path === pathname)
        );
        route = routeMenu?.routes?.find((e: any) => e.path === pathname) || {};
      } else {
        route = route.menuLeft.routes?.find((e: any) => e.path === pathname) || {};
      }
    }
    this.setState({ route: route });
  };

  render() {
    const { route } = this.state;
    const { extendRightComponent } = this.props;
    if (!route.parents?.length && !route.breadcrumb) return null;
    return (
      <div style={{ position: 'relative' }}>
        {/* <Breadcrumb separator=">" style={{ height: 61 }}>
          {route.parents?.map?.((el: any, index: number) => {
            if (el.isCustom) {
              return <Breadcrumb.Item key={index.toString()}>{values?.[el.key]}</Breadcrumb.Item>;
            }
            return (
              <Breadcrumb.Item
                className={el.link ? 'link-click' : ''}
                key={index.toString()}
                onClick={() => {
                  if (el.link) history.push(el.link);
                }}
              >
                {t(el.text)}
              </Breadcrumb.Item>
            );
          })}
          {route.breadcrumb ? <Breadcrumb.Item>{t(route.breadcrumb)}</Breadcrumb.Item> : null}
        </Breadcrumb> */}
        {extendRightComponent ? (
          <div style={{ zIndex: 1000 }} className="extend-right-component">
            {extendRightComponent}
          </div>
        ) : null}
      </div>
    );
  }
}

const BreadcrumbBaseRoute = withTranslation()(BreadcrumbBase);

export default withRouter(BreadcrumbBaseRoute);
