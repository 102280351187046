import React from 'react';
import imports from 'services/import';
import BreadcrumbBase from 'components/Breadcrumb';
import throwException from 'utils/throwException';
import TableBase from 'components/TableBase';
import { withTranslation } from 'react-i18next';
import { showMessageError, showMessageSuccess } from 'utils';
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InfoIcon from '@mui/icons-material/Info';
import { Upload, Form, Button, Select, Col, Spin, Drawer, ConfigProvider } from 'antd';
import './styles.scss';
import account from 'services/account';
import { useForm } from 'antd/lib/form/Form';
import modal from 'antd/lib/modal';
import { IMPORT_STATUS } from './types';
import { Trans } from 'react-i18next';

interface IProps {
  history: any;
  pathname: string;
  id: string;
  t: any;
  form: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
  orgs: any[];
  isDrawerVisible: boolean;
  errors: any[];
  files: any[];
}

class ImportIncident extends React.Component<IProps, IState> {
  page_number: number;
  confirmModal: any;

  constructor(props: IProps) {
    super(props);
    this.page_number = 1;
    this.state = {
      totalDocs: 0,
      loading: true,
      data: [],
      orgs: [],
      isDrawerVisible: false,
      errors: [],
      files: [],
    };
  }

  componentDidMount() {
    this.getDataFirst();
    this.getRoorOrgs();
  }

  showDrawer = (record: any) => {
    this.setState({ errors: record.status_detail, isDrawerVisible: true });
  };

  onCloseDrawer = () => {
    this.setState({ isDrawerVisible: false });
  };

  getDataFirst = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    imports
      .list({
        page_size: 10,
        page_number: this.page_number,
      })

      .then((res) => {
        const { docs, totalDocs } = res.data.data;
        this.setState({
          data: docs,
          loading: false,
          totalDocs,
        });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  getRoorOrgs = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
    }
    account
      .listAccountRoot()
      .then(({ data }) => {
        const activeOrgs = data.data?.filter((o) => o.is_active);
        this.setState({ orgs: activeOrgs, loading: false });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  getListImports = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    imports
      .list({
        page_size: 10,
        page_number: this.page_number,
      })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  handleDeleteImport = async (importId: string) => {
    const { t } = this.props;
    try {
      await imports.delete({ import_id: importId });
      showMessageSuccess(t('data_report.import_incidents.confirmRemove.success'));
      const currentImportIndex = this.state.data.findIndex((i) => i._id === importId);
      const newData = [
        ...this.state.data.slice(0, currentImportIndex),
        { ...this.state.data[currentImportIndex], status: IMPORT_STATUS.deleted },
        ...this.state.data.slice(currentImportIndex + 1),
      ];
      this.setState({ data: newData });
    } catch (e) {
      showMessageError(t('import.file.error'));
      throwException(e);
    }
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('data_report.import_incidents.list.id'),
        dataIndex: 'sequence',
        key: 'sequence',
        width: 56,
      },
      {
        title: t('data_report.import_incidents.list.account'),
        dataIndex: 'org_id',
        key: 'org_id',
        width: 553,
        render: (_v, record: any) => {
          const { org_id } = record;
          return <span style={{ wordBreak: 'break-word' }}>{org_id?.name?.trim()}</span>;
        },
      },
      {
        title: t('data_report.import_incidents.list.status'),
        dataIndex: 'status',
        key: 'status',
        width: 169,
        render: (_v, record: any) => {
          const { status } = record;
          switch (status) {
            case IMPORT_STATUS.processing:
              return (
                <span style={{ wordBreak: 'break-word', color: '#FF9500' }}>
                  {t('data_report.import_incidents.status.DOING')}
                </span>
              );
            case IMPORT_STATUS.failed:
            case IMPORT_STATUS.delete_failed:
              return (
                <span
                  onClick={() => this.showDrawer(record)}
                  style={{ display: 'inline-block', wordBreak: 'break-word', color: '#FF3B30' }}
                >
                  <InfoIcon style={{ verticalAlign: 'middle', marginRight: '5px' }} />
                  <Trans
                    components={{
                      blue: (
                        <span
                          style={{
                            display: 'inline-block',
                            color: 'blue',
                          }}
                        />
                      ),
                    }}
                    i18nKey={'data_report.import_incidents.status.FAIL'}
                  />
                </span>
              );
            case IMPORT_STATUS.deleted:
              return (
                <span style={{ wordBreak: 'break-word', color: '#343434' }}>
                  {t('data_report.import_incidents.status.DELETED')}
                </span>
              );
            case IMPORT_STATUS.success:
              return (
                <span style={{ wordBreak: 'break-word', color: '#34C759' }}>
                  {t('data_report.import_incidents.status.SUCCESS')}
                </span>
              );
            default:
              return (
                <span style={{ wordBreak: 'break-word', color: '#FF9500' }}>
                  {t('data_report.import_incidents.status.DOING')}
                </span>
              );
          }
        },
      },
      {
        title: t('data_report.import_incidents.list.imported_date'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (_v, record: any) => {
          const { createdAt, cms_admin_id } = record;
          const { first_name, last_name } = cms_admin_id;
          return (
            <>
              <span style={{ wordBreak: 'break-word', display: 'block' }}>
                {moment(createdAt).format('YYYY/MM/DD HH:mm')}
              </span>
              <span
                style={{ wordBreak: 'break-word', display: 'block' }}
              >{`${last_name} ${first_name}`}</span>
            </>
          );
        },
      },
      {
        title: '',
        width: 48,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          if (
            record.status !== IMPORT_STATUS.success &&
            record.status !== IMPORT_STATUS.delete_failed
          )
            return null;
          return (
            <DeleteOutlineIcon
              fontSize="medium"
              style={{
                marginLeft: 30,
              }}
              onClick={() => {
                this.openConfirmModal(() => {
                  this.handleDeleteImport(record._id);
                }, true);
              }}
            />
          );
        },
      },
    ];
    return columns;
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getListImports();
  };

  handleOnChange = async ({ file }: any) => {
    const { form, t } = this.props;
    try {
      if (file.status === 'removed') {
        form.setFieldsValue({
          file: undefined,
        });
        this.setState({
          files: [],
        });
        return;
      }
      let allowedExtension = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (!allowedExtension.includes(file.type)) {
        return showMessageError(t('import.file.invalid'));
      }
      if (file.size <= 0) {
        return showMessageError(t('import.file.invalid'));
      }
      form.setFieldsValue({
        file,
      });
      this.setState({
        files: [file],
      });
    } catch (e) {
      throwException(e);
    }
  };

  openConfirmModal = (action, isConfirmDelete = false) => {
    const { t } = this.props;
    const config = {
      title: isConfirmDelete
        ? t('data_report.import_incidents.confirmRemove.title')
        : t('data_report.import_incidents.confirmImport.title'),
      content: (
        <React.Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>
              {isConfirmDelete
                ? t('data_report.import_incidents.confirmRemove.body')
                : t('data_report.import_incidents.confirmImport.body')}
            </span>
          </div>
          <div className="footer-bottom-modal">
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Button
                type="primary"
                style={{ marginRight: 12, height: 40 }}
                onClick={() => {
                  this.confirmModal?.destroy();
                  action && action();
                }}
              >
                {isConfirmDelete
                  ? t('data_report.import_incidents.confirmRemove.okBtn')
                  : t('data_report.import_incidents.confirmImport.okBtn')}
              </Button>
              <Button
                style={{ height: 40 }}
                onClick={() => {
                  this.confirmModal?.destroy();
                }}
              >
                {isConfirmDelete
                  ? t('data_report.import_incidents.confirmRemove.cancelBtn')
                  : t('data_report.import_incidents.confirmImport.cancelBtn')}
              </Button>
            </ConfigProvider>
          </div>
        </React.Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 700,
    };
    this.confirmModal = modal.confirm(config);
  };

  onFinish = (values: any) => {
    this.openConfirmModal(this.handleImport);
  };

  handleImport = async () => {
    const { t, form } = this.props;
    const values = form.getFieldValue();
    const { file, rootOrgId } = values;
    try {
      this.setState({ loading: true });
      const { data } = await imports.getUrlUpload({
        file_name: encodeURIComponent(file.name),
        root_org_id: rootOrgId,
      });
      const s3PresignedUrl = data?.data?.url;
      const fileKey = data?.data?.fileKey;
      if (s3PresignedUrl) {
        const headers = {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
        };
        await imports.upload(s3PresignedUrl, { headers }, file);
        await imports.import({
          file_name: encodeURIComponent(file.name),
          root_org_id: rootOrgId,
          file_key: fileKey,
        });
        showMessageSuccess(t('data_report.import_incidents.confirmImport.success'));
        this.handleChangePage(1);
        form.setFieldsValue({
          file: undefined,
          rootOrgId: undefined,
        });
        this.setState({
          files: [],
        });
      }
      this.setState({ loading: false });
    } catch (err) {
      throwException(err);
      showMessageError(t('import.file.error'));
      this.setState({ loading: false });
    }
  };

  render() {
    const { pathname, t, form } = this.props;
    const { loading, data, totalDocs } = this.state;
    return (
      <Spin spinning={loading}>
        <BreadcrumbBase values={{}} pathname={pathname} />
        <Form form={form} layout="vertical" style={{ padding: '16px' }} onFinish={this.onFinish}>
          <Col span={8} style={{ minWidth: '500px' }}>
            <Form.Item
              name="rootOrgId"
              label={t('data_report.import_incidents.list.account')}
              rules={[{ required: true, message: t('import.org.id.required') }]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                placeholder={t('reports.bulk.org_name.placeholder')}
              >
                {this.state.orgs.map((org, index) => (
                  <Select.Option key={index} value={org._id}>
                    {org.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="file" rules={[{ required: true, message: t('import.file.required') }]}>
              <Upload
                accept=".xlsx"
                name="avatar"
                className="upload-area"
                onChange={this.handleOnChange}
                showUploadList={true}
                customRequest={() => null}
                beforeUpload={() => false}
                maxCount={1}
                fileList={this.state.files}
              >
                <div className="upload-content">
                  <img id="img" alt="" src="/images/ic-upload.png" style={{ marginRight: '5px' }} />
                  <Trans
                    components={{
                      blue: (
                        <span
                          style={{
                            display: 'inline-block',
                            color: 'blue',
                            textDecoration: 'underline',
                          }}
                        />
                      ),
                    }}
                    i18nKey={'common.browse2'}
                  />
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('data_report.import_incidents.importBtn')}
            </Button>
          </Form.Item>
        </Form>

        <TableBase
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          loading={loading}
        />
        <Drawer
          title={t('import.errors.list.title')}
          placement="right"
          onClose={this.onCloseDrawer}
          visible={this.state.isDrawerVisible}
          width="650px"
          footer={
            <Button style={{ float: 'right' }} onClick={this.onCloseDrawer}>
              {t('import.errors.list.close')}
            </Button>
          }
        >
          {this.state.errors.map((error, index) => (
            <p key={index}>
              {error.code
                ? t(error.code, {
                  no: error.rowNumber,
                  value: error.value,
                })
                : t('import.invalid.file')}
            </p>
          ))}
        </Drawer>
      </Spin>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ImportIncident));
