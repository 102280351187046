import ReactDOM from 'react-dom';
import App from './App';
import './global.less';
import 'config/i18n';

export interface II18n {
  t: (t: string) => string;
}

ReactDOM.render(<App />, document.getElementById('root'));
