import { Button } from 'antd';
import { Component } from 'react';

interface IProps {
  t: any;
  backLogin: any;
}

class ForgotSuccess extends Component<IProps> {
  render() {
    const { t, backLogin } = this.props;
    return (
      <div className="login-forgot-success" style={{ marginTop: 40, textAlign: 'center' }}>
        <span style={{ fontSize: 21, fontWeight: 600 }}>{t('login.forgot.success.title')}</span>
        <div style={{ paddingTop: 32 }}>{t('login.forgot.success.description')}</div>
        <div style={{ paddingTop: 32 }}>
          <Button onClick={backLogin} style={{ height: 50 }} type="primary" block>
            {t('login.forgot.success.button.back_login')}
          </Button>
        </div>
      </div>
    );
  }
}

export default ForgotSuccess;
