import { Component } from 'react';
import BreadcrumbBase from 'components/Breadcrumb';
import account from 'services/account';
import { useParams } from 'react-router-dom';
import throwException from 'utils/throwException';
import TableBase from 'components/TableBase';
import { withTranslation } from 'react-i18next';
import FormAdd from './FormAdd';
import { showMessageError, showMessageSuccess } from 'utils';

interface IProps {
  history: any;
  pathname: string;
  id: string;
  t: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
  allowAdd: boolean;
  nameRoute: string;
}

class AccountUserManagement extends Component<IProps, IState> {
  page_number: number;
  namesearch: string;
  refFormAdd: any;

  constructor(props: IProps) {
    super(props);
    this.page_number = 1;
    this.namesearch = '';
    this.state = { totalDocs: 0, loading: true, data: [], allowAdd: false, nameRoute: '' };
  }

  componentDidMount() {
    this.getDataFirst();
  }

  getDataFirst = () => {
    const { id, history, t } = this.props;
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    const promiseDetail = account.detail(id);
    const promiseList = account.listUser({
      organization_id: id,
      page_size: 10,
      page: this.page_number,
    });

    Promise.all([promiseDetail, promiseList])
      .then((res) => {
        const dataDetail = res[0].data;
        const dataList = res[1].data;
        const { docs, totalDocs, is_exist_account } = dataList.data;
        const { name, deleted } = dataDetail.data;
        if (deleted) {
          showMessageError(t('account.management.message_warning.account_has_deleted'));
          return history.push('/account-management');
        }
        this.setState({
          data: docs,
          loading: false,
          totalDocs,
          nameRoute: name,
          allowAdd: !is_exist_account,
        });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  getData = () => {
    const { id } = this.props;
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    account
      .listUser({
        organization_id: id,
        page_size: 10,
        page: this.page_number,
        search: this.namesearch,
      })
      .then(({ data }) => {
        const { docs, totalDocs, is_exist_account } = data.data;
        this.setState({ data: docs, loading: false, totalDocs, allowAdd: !is_exist_account });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('account.management.user_admin.table.column.id'),
        dataIndex: '_id',
        key: 'id',
        width: 240,
        render: (_v, record: any) => {
          const { user_id } = record;
          const { _id } = user_id;
          return <span style={{ wordBreak: 'break-word' }}>{`${_id}`.trim()}</span>;
        },
      },
      {
        title: t('account.management.user_admin.table.column.user_name'),
        dataIndex: 'user_id.',
        key: 'user_id',
        width: 300,
        render: (_v, record: any) => {
          const { user_id } = record;
          const { first_name, last_name } = user_id;
          return (
            <span style={{ wordBreak: 'break-word' }}>{`${last_name} ${first_name}`.trim()}</span>
          );
        },
      },
      {
        title: t('account.management.user_admin.table.column.email_address'),
        dataIndex: ['user_id', 'email'],
        key: 'user_id.email',
      },
      {
        title: t('account.management.user_admin.table.column.title'),
        dataIndex: 'role_name',
        key: 'role_name',
      },
      {
        title: t('account.management.user_admin.table.column.phone_number'),
        dataIndex: ['user_id', 'phone'],
        key: 'user_id.phone',
      },
      {
        title: '',
        width: 115,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          return null;
        },
      },
    ];
    return columns;
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  handleOkAdd = (values, callback) => {
    const { t } = this.props;
    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.page_number = 1;
        this.namesearch = '';
        this.getData();
        showMessageSuccess(t('account.management.create_user.success'));
      }
    });
  };

  render() {
    const { pathname, id } = this.props;
    const { loading, data, totalDocs, allowAdd, nameRoute } = this.state;
    return (
      <div>
        <BreadcrumbBase values={{ name: nameRoute }} pathname={pathname} />
        <TableBase
          allowAdd
          disabledAdd={!allowAdd}
          lableKeyI18n="account.management.user_admin.button.add_new_admin"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          handleSearch={this.handleSearch}
          onOkFormAdd={this.handleOkAdd}
          loading={loading}
          formAdd={(props) => (
            <FormAdd
              org_id={id}
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
        />
      </div>
    );
  }
}

const wrapParams = (Component) => {
  return (props) => {
    const { id } = useParams<any>();
    return <Component id={id} {...props} />;
  };
};

export default withTranslation()(wrapParams(AccountUserManagement));
