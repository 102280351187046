import { Avatar, Menu, Dropdown } from 'antd';
import { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { IUser } from 'sagas/models/user';
import { logout } from 'utils';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import LogoIcon from 'components/icon-svg/logo';
import './styles.scss';

interface IProps {
  history: any;
  t: any;
  user: IUser;
}

interface IState {}

class HeaderBase extends Component<IProps & WithTranslation & RouteComponentProps, IState> {
  onClickLogo = () => {
    const { history } = this.props;
    history.push('/account-management');
  };

  handleChangeMenu = ({ key }) => {
    const { history } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (pathname === key) {
      return;
    }
    history.push(key);
  };

  handleCheckActive = (): string => {
    const { history } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (pathname.includes('/account-management')) {
      return '/account-management';
    } else if (pathname.includes('/user-management')) {
      return '/user-management';
    } else if (pathname.includes('/content-management')) {
      return '/content-management';
    } else if (pathname.includes('/history-log')) {
      return '/history-log/notification-log';
    } else if (pathname.includes('/other-setting')) {
      return '/other-setting/term-of-use/admin-system';
    } else if (pathname.includes('/report')) {
      return '/report/activation-log';
    }
    return '';
  };

  render() {
    const { t, user, history } = this.props;
    return (
      <div className="header">
        <div style={{ display: 'flex', alignItems: 'center', width: 'calc(100% - 150px)' }}>
          <div className="logo-container">
            <div className="logo" onClick={this.onClickLogo}>
              <LogoIcon />
            </div>
          </div>
          <Menu
            className="menu-custom"
            mode="horizontal"
            activeKey={this.handleCheckActive()}
            onClick={this.handleChangeMenu}
          >
            <Menu.Item key="/user-management">
              <div className="wrapper-menu">
                <div className="menu-standby">
                  <span>{t('menu.user_management')}</span>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item key="/account-management">{t('menu.account_management')}</Menu.Item>
            <Menu.Item key="/content-management">{t('menu.content_management')}</Menu.Item>
            <Menu.Item key="/other-setting/term-of-use/admin-system">
              {t('menu.other_setting')}
            </Menu.Item>
            <Menu.Item key="/history-log/notification-log">{t('menu.history_log')}</Menu.Item>
            <Menu.Item key="/report/activation-log">{t('menu.report')}</Menu.Item>
          </Menu>
        </div>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                icon={<AccountCircleIcon />}
                onClick={() => {
                  history.push('/my-profile');
                }}
              >
                {t('auth.my_profile.title')}
              </Menu.Item>
              <Menu.Item
                key="1"
                icon={<AddIcon />}
                onClick={() => {
                  logout();
                  history.push('/login');
                }}
              >
                {t('auth.logout.title')}
              </Menu.Item>
            </Menu>
          }
          placement="bottomLeft"
          arrow
          trigger={['click']}
        >
          <div className="avatar-user">
            <Avatar
              style={{
                width: 32,
                minWidth: 32,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
              }}
              icon={
                <img
                  style={{ width: 24, height: 24, margin: 'auto' }}
                  src="/images/ic-avatar.png"
                  alt=""
                />
              }
            />
            <span className="name-user">{(user.last_name + ' ' + user.first_name).trim()}</span>
          </div>
        </Dropdown>
      </div>
    );
  }
}

const Header = withTranslation()(HeaderBase);
export default withRouter(connect(({ user }: any) => ({ user: user.user }))(Header));
