import Breadcrumb from 'components/Breadcrumb';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Spin, Button } from 'antd';
import InputBase from 'components/Input';
import otherSetting from 'services/ortherSetting';
import throwException from 'utils/throwException';
import { get } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import { showMessageSuccess } from 'utils';

interface IProps {
  pathname: string;
  t: any;
  form: any;
}

interface IState {
  loading: boolean;
}

class AdminSystem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    otherSetting
      .list({ type: 'URL_SUPPORT', page_size: 10, page: 1, device_app: 'ADMIN_SYS' })
      .then(({ data }) => {
        const { docs } = data.data;
        const { form } = this.props;
        form.setFieldsValue({ url_support: get(docs[0], 'url_support') });
        this.setState({ loading: false });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  updateData = (url_support) => {
    const { loading } = this.state;
    const { t } = this.props;
    if (!loading) this.setState({ loading: true });
    otherSetting
      .create({ type: 'URL_SUPPORT', url_support, device_app: 'ADMIN_SYS' })
      .then(({ data }) => {
        this.getData();

        showMessageSuccess(`${t('other_setting.title.input.url_support.update.success')}`);
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  onFinish = (value) => {
    this.updateData(value.url_support);
  };

  isValidHttpUrl = (rule, value = '') => {
    let url;
    const { t } = this.props;
    if (value.trim()) {
      try {
        url = new URL(value);
      } catch (_) {
        // throw new Error(t('settingPage.sosSetUp.form.require.url'));
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return Promise.reject(new Error(t('settingPage.sosSetUp.form.require.url')));
      }
    }

    return Promise.resolve();
  };

  render() {
    const { pathname, t, form } = this.props;
    const { loading } = this.state;
    return (
      <Spin spinning={loading}>
        <div>
          <Breadcrumb pathname={pathname} />
          <div style={{ marginLeft: 16, marginTop: 32, fontSize: 17, fontFamily: 'Noto Sans JP' }}>
            {t('other_setting.title.input.url_support_required')}
            {/* Add URL link to Admin system support menu so that it redirect Admin user to online
            manual site */}
          </div>
          <Form
            form={form}
            layout="vertical"
            style={{ marginLeft: 16, marginTop: 30 }}
            onFinish={this.onFinish}
            scrollToFirstError
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t('other_setting.title.input.url_support_required'),
                },
                { validator: this.isValidHttpUrl },
              ]}
              name="url_support"
              label={t('other_setting.title.input.url_support')}
            >
              <InputBase
                placeholder={t('other_setting.title.input.url_support_place_holder')}
                style={{ maxWidth: 765 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" style={{ height: 40, marginTop: 30 }}>
                {t('account.management.modal.edit.button.save')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(AdminSystem));
