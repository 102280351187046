import { Button, Modal, Tabs } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { ReactComponent as SvgAttach } from './Attach.svg';
import upload from 'services/upload';
import throwException from 'utils/throwException';

interface IProps {
  onRef?: (v: any) => any;
  t: any;
  reloadData?: any;
  handlePublic: any;
}

interface IState {
  visible: boolean;
  dataView: any;
}

class ModalPublic extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: undefined };
    this.resizeIframe = debounce(this.resizeIframe, 300);
  }

  handleVisible = (record?: any) => {
    this.setState({ visible: !!record, dataView: record });
  };

  resizeIframe = (e) => {
    try {
      const iframeDocument = e.target.contentDocument || e.target.contentWindow.document;
      e.target.style.height = iframeDocument.body.scrollHeight + 'px';
    } catch (e) {
      console.log(e);
    }
  };

  handlePublic = () => {
    const { dataView } = this.state;
    const { handlePublic } = this.props;
    if (handlePublic) handlePublic(dataView, this.handleVisible);
  };

  handleCancel = () => {
    this.handleVisible();
  };

  handlePreviewFile = async (file: any) => {
    try {
      const { data } = await upload.preview({ files: [file.fileKey] });
      const url = data.data?.[0]?.url;
      console.log(url);
      if (url) {
        window.open(url);
      }
    } catch (e) {
      throwException(e);
    }
  };

  render() {
    const { visible, dataView } = this.state;
    const { onRef, t } = this.props;
    if (onRef) onRef(this);

    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={807}
      >
        <div style={{ padding: '16px 24px', minHeight: window.innerHeight - 180 }}>
          <div className="formAddAccount">
            <div className="title-modal">
              <div>{t('content.management.notice.modal.warning.publish_notice.title')}</div>
            </div>
          </div>
          <div style={{ padding: 16 }}>
            <Tabs defaultActiveKey="1" style={{ padding: '24px 16px' }}>
              <Tabs.TabPane tab={t('content.management.tab.modal.public.preview_web')} key="1">
                <div>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>{dataView?.title}</span>
                  {dataView?.description ? (
                    <div style={{ paddingTop: 16, fontSize: 17, whiteSpace: 'pre-wrap' }}>
                      {dataView?.description}
                    </div>
                  ) : null}
                  {dataView?.source_url ? (
                    <div style={{ color: '#007AFF', paddingTop: 24, fontSize: 17 }}>
                      {dataView?.source_url}
                    </div>
                  ) : null}
                  {dataView?.file_url?.map((e: any, index: number) => (
                    <div
                      onClick={() => {
                        this.handlePreviewFile(e);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        border: '1.5px solid #21C0F6',
                        borderRadius: 4,
                        padding: '10px 13px',
                        marginTop: 24,
                      }}
                      key={index.toString()}
                    >
                      <SvgAttach />
                      <span
                        className="text-split"
                        style={{
                          fontWeight: 600,
                          marginLeft: 13,
                          wordBreak: 'break-word',
                          width: 'calc(100% - 16px)',
                        }}
                      >
                        {decodeURIComponent(e.original_name)}
                      </span>
                    </div>
                  ))}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('content.management.tab.modal.public.preview_mobile')} key="2">
                <div style={{ width: 324, margin: 'auto' }}>
                  <span style={{ fontSize: 18, fontWeight: 600, wordBreak: 'break-word' }}>
                    {dataView?.title}
                  </span>
                  {dataView?.description ? (
                    <div
                      style={{
                        paddingTop: 16,
                        fontSize: 17,
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {dataView?.description}
                    </div>
                  ) : null}
                  {dataView?.source_url ? (
                    <div style={{ color: '#007AFF', paddingTop: 24, fontSize: 17 }}>
                      {dataView?.source_url}
                    </div>
                  ) : null}
                  {dataView?.file_url?.map((e: any, index: number) => (
                    <div
                      onClick={() => {
                        this.handlePreviewFile(e);
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        border: '1.5px solid #21C0F6',
                        borderRadius: 4,
                        padding: '10px 13px',
                        marginTop: 24,
                      }}
                      key={index.toString()}
                    >
                      <SvgAttach />
                      <span
                        className="text-split"
                        style={{
                          fontWeight: 600,
                          marginLeft: 13,
                          wordBreak: 'break-word',
                          width: 'calc(100% - 16px)',
                        }}
                      >
                        {decodeURIComponent(e.original_name)}
                      </span>
                    </div>
                  ))}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={t('content.management.tab.modal.public.preview_web_admin')}
                key="3"
              >
                <div>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>{dataView?.title}</span>
                  {dataView?.description ? (
                    <div style={{ paddingTop: 16, fontSize: 17, whiteSpace: 'pre-wrap' }}>
                      {dataView?.description}
                    </div>
                  ) : null}
                  {dataView?.source_url ? (
                    <div style={{ color: '#007AFF', paddingTop: 24, fontSize: 17 }}>
                      {dataView?.source_url}
                    </div>
                  ) : null}
                  {dataView?.file_url?.map((e: any, index: number) => (
                    <div
                      onClick={() => {
                        this.handlePreviewFile(e);
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1.5px solid #21C0F6',
                        borderRadius: 4,
                        padding: '10px 13px',
                        marginTop: 24,
                        cursor: 'pointer',
                      }}
                      key={index.toString()}
                    >
                      <SvgAttach />
                      <span
                        className="text-split"
                        style={{
                          fontWeight: 600,
                          marginLeft: 13,
                          wordBreak: 'break-word',
                          width: 'calc(100% - 16px)',
                        }}
                      >
                        {decodeURIComponent(e.original_name)}
                      </span>
                    </div>
                  ))}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
        <div
          style={{
            boxShadow: '0px -1px 0px #EFEFF4',
            height: 60,
            padding: '10px 12px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={this.handlePublic} type="primary" style={{ height: 40 }}>
            {t('content.management.button.publish')}
          </Button>
          <Button style={{ height: 40, marginLeft: 12 }} onClick={this.handleCancel}>
            {t('common.button.cancel')}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalPublic);
