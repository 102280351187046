import { Button, ButtonProps, Modal } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';

interface IProps {
  t: any;
}

interface IState {
  visible: boolean;
}

interface ConfigModal {
  width?: number | string;
  className?: string;
  i18nOk?: string;
  i18nCancel?: string;
  hiddenButtonOk?: boolean;
  hiddenButtonCancel?: boolean;
  content?: any;
  title?: any;
  buttonOkProps?: ButtonProps;
  buttonCancelProps?: ButtonProps;
  onOk?: () => any;
  onCancle?: () => any;
}

interface ConfigModalOpen {
  open: (config: ConfigModal) => any;
  close: () => any;
}

let configModal: ConfigModalOpen & ConfigModal = {
  open: () => null,
  close: () => null,
};

let initConfig = configModal;

class ModalWarning extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false };
    configModal.open = (config: ConfigModal) => {
      configModal = { ...configModal, ...config };
      this.setState({ visible: true });
    };
    configModal.close = () => {
      this.setState({ visible: false }, () => {
        configModal = initConfig;
      });
    };
    initConfig = configModal;
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  handleVisible = (flag?: boolean) => {
    this.setState({ visible: !!flag });
  };

  render() {
    const { visible } = this.state;
    const { t } = this.props;
    const {
      className,
      i18nOk,
      i18nCancel,
      hiddenButtonOk,
      hiddenButtonCancel,
      content,
      title,
      buttonOkProps,
      buttonCancelProps,
      onOk,
      onCancle,
    } = configModal;

    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className={`modal-table-add modal-custom-warning ${className}`}
        width={configModal.width}
      >
        <div style={{ padding: '16px 24px 0' }}>
          <div className="formAddAccount">
            <div className="title-modal">
              <div>{title}</div>
            </div>
            {content}
          </div>
        </div>
        {hiddenButtonOk && hiddenButtonCancel ? null : (
          <div className="footer">
            {!hiddenButtonOk ? (
              <Button type="primary" style={{ height: 40 }} onClick={onOk} {...buttonOkProps}>
                {t(i18nOk)}
              </Button>
            ) : null}
            {!hiddenButtonCancel ? (
              <Button
                style={{ height: 40, marginLeft: 12 }}
                onClick={onCancle}
                {...buttonCancelProps}
              >
                {t(i18nCancel)}
              </Button>
            ) : null}
          </div>
        )}
      </Modal>
    );
  }
}

export { configModal };

export default withTranslation()(ModalWarning);
