import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import accessLog from 'services/access-log';
import throwException from 'utils/throwException';
import TableBase from 'components/TableBase';
import { get } from 'lodash';
import moment from 'moment';

interface IProps {
  t: any;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
}

class ChatsLog extends Component<IProps, IState> {
  page_number: number;
  namesearch: any;
  start_date: any;
  end_date: any;
  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: false, totalDocs: 0 };
    this.page_number = 1;
    this.namesearch = '';
  }
  // componentDidMount() {
  //   this.getData();
  // }

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  getData = () => {
    const { loading } = this.state;
    // if (!this.start_date || !this.end_date) {
    //   this.setState({ loading: false, data: [] });
    //   return;
    // }
    if (!loading) this.setState({ loading: true });

    accessLog
      .list({
        page_size: 10,
        page: this.page_number,
        incident_id: this.namesearch,
        target: 'Incident',
        // start_date: moment(this.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD') + ' 00:00:00',
        // end_date: moment(this.end_date, 'DD/MM/YYYY').format('YYYY/MM/DD') + ' 23:59:59',
        start_date: this.start_date
          ? moment(this.start_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 00:00:00'
          : null,
        end_date: this.end_date
          ? moment(this.end_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 23:59:59'
          : null,
      })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch(this.handleError);
  };

  exportLog = () => {
    const { loading } = this.state;
    // if (!this.start_date || !this.end_date) {
    //   this.setState({ loading: false, data: [] });
    //   return;
    // }
    if (!loading) this.setState({ loading: true });

    const params: any = {
      page_size: 10,
      page: this.page_number,
      incident_id: this.namesearch || '',
      target: 'Incident',
      // start_date: moment(this.start_date, 'DD/MM/YYYY').format('YYYY/MM/DD') + ' 00:00:00',
      // end_date: moment(this.end_date, 'DD/MM/YYYY').format('YYYY/MM/DD') + ' 23:59:59',
      start_date: this.start_date
        ? moment(this.start_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 00:00:00'
        : '',
      end_date: this.end_date
        ? moment(this.end_date, 'YYYY/MM/DD').format('YYYY/MM/DD') + ' 23:59:59'
        : '',
      standby_token: localStorage.getItem('access_token'),
    };
    const queryString = new URLSearchParams(params).toString();
    window.open(
      process.env.REACT_APP_API_URL + '/admin/access-log/incident-log/export?' + queryString
    );
    this.setState({ loading: false });
  };
  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('access_log.incident.column.incident'),
        dataIndex: 'incident_number',
        width: 150,
      },
      {
        title: t('history_log.account_name.column.title'),
        dataIndex: 'org_root_id',
        width: 200,
        render: (v) => <div>{get(v, 'name')}</div>,
      },
      {
        title: t('history_log.org.column.title'),
        dataIndex: 'org_id',
        width: 200,
        render: (v) => <div>{get(v, 'name')}</div>,
      },
      {
        title: t('history_log.sent_time_standby.column.title'),
        dataIndex: 'createdAt',
        width: 250,
        render: (v) => <div>{moment(v).format('YYYY/MM/DD HH:mm')}</div>,
      },
      {
        title: t('access_log.incident.column.source_ip_address'),
        dataIndex: 'source_ip_address',
        width: 200,
      },
      {
        title: t('access_log.incident.column.destination_ip_address'),
        dataIndex: 'destination_ip_address',
        width: 200,
      },
    ];
    return columns;
  };
  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value.trim();
    const { data } = this.state;
    this.page_number = 1;
    if (this.namesearch.length < 1) {
      if (data.length) {
        this.setState({ data: [], totalDocs: 0 });
      }
      return;
    }
    this.getData();
  };

  handleSearchDateTime = (value) => {
    this.start_date = value[0];
    this.end_date = value[1];
    const { data } = this.state;
    this.page_number = 1;
    if (this.namesearch.length < 1) {
      if (data.length) {
        this.setState({ data: [], totalDocs: 0 });
      }
      return;
    }
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  render() {
    const { loading, data, totalDocs } = this.state;
    const { onRef, t } = this.props;
    if (onRef) onRef(this);
    return (
      <Fragment>
        <TableBase
          placeholderInputSearch={t('history_log.tab.incident_log.input.search')}
          style={{ margin: 0 }}
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          // onOkFormAdd={this.handleOkAdd}
          handleSearch={this.handleSearch}
          handleSearchDateTime={this.handleSearchDateTime}
          loading={loading}
          lableKeyI18n="content.management.button.add_new_article"
          widthModalAdd={791}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
        />
      </Fragment>
    );
  }
}

export default withTranslation()(ChatsLog);
