import TableBase from 'components/TableBase';
import { Component, Fragment } from 'react';
import otherSetting from 'services/ortherSetting';
import { showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';
import FormAdd from './FormAdd';
import moment from 'moment';
import DescriptionIcon from '@mui/icons-material/Description';
import ModalEdit from './ModalEdit';

interface IProps {
  t: any;
  device_app: 'MOBILE' | 'ADMIN_SYS' | 'WEB_APP' | 'SUPPORT';
  type: 'TERM' | 'PRIVACY' | 'URL_SUPPORT';
}

interface IState {
  data: any[];
  loading: boolean;
  totalDocs: number;
  stepAdd: number;
}

class TermOfUse extends Component<IProps, IState> {
  columns: any[];
  page_number: number;
  namesearch: string;
  refFormAdd: any;
  modalEdit: any;
  constructor(props: IProps) {
    super(props);
    const { t } = props;
    this.state = { data: [], loading: true, totalDocs: 0, stepAdd: 0 };
    this.page_number = 1;
    this.namesearch = '';
    this.columns = [
      {
        title: t('other_setting.table.column.title'),
        dataIndex: 'title',
      },
      {
        title: t('other_setting.table.column.version_id'),
        dataIndex: 'version_id',
      },
      {
        title: t('other_setting.table.column.upload_date'),
        dataIndex: 'createdAt',
        width: 140,
        render: (v) => <div>{moment(v).format('YYYY/MM/DD HH:mm')}</div>,
      },
      {
        title: '',
        width: 140,
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (_, record: any) => {
          return (
            <Fragment>
              <DescriptionIcon
                fontSize="small"
                onClick={() => this.handlePreview(record)}
                style={{
                  userSelect: 'none',
                  cursor: !record.published ? 'pointer' : 'default',
                  opacity: !record.published ? 1 : 0.3,
                }}
              />
            </Fragment>
          );
        },
      },
    ];
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    const { type, device_app } = this.props;
    otherSetting
      .list({ type, device_app, page_size: 10, page: this.page_number })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  generateMessageSuccess = () => {
    const { t, device_app, type } = this.props;
    // `'MOBILE' | 'ADMIN_SYS' | 'WEB_APP' | 'SUPPORT';`;
    // `"TERM" | "PRIVACY" | "URL_SUPPORT"`
    switch (type) {
      case 'TERM':
        switch (device_app) {
          case 'ADMIN_SYS':
            showMessageSuccess(t('other_setting.term_admin_sys.create_success'));
            break;
          case 'WEB_APP':
            showMessageSuccess(t('other_setting.term_admin_wed.create_success'));
            break;
          case 'MOBILE':
            showMessageSuccess(t('other_setting.term_admin_mobile.create_success'));
            break;
          default:
            break;
        }
        break;
      case 'PRIVACY':
        switch (device_app) {
          case 'ADMIN_SYS':
            showMessageSuccess(t('other_setting.privacy_sys.create_success'));
            break;
          case 'WEB_APP':
            showMessageSuccess(t('other_setting.privacy_web.create_success'));
            break;
          case 'MOBILE':
            showMessageSuccess(t('other_setting.privacy_mobile.create_success'));
            break;
          default:
            break;
        }
        break;
    }
  };

  handleOkAdd = (values, callback) => {
    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.getData();
        this.generateMessageSuccess();
      }
    });
  };

  handlePreview = (record) => {
    this.modalEdit?.handleVisible?.(record);
  };

  render() {
    const { data, loading, stepAdd, totalDocs } = this.state;
    const { type, device_app, t } = this.props;
    return (
      <div>
        <ModalEdit
          onRef={(ref) => {
            this.modalEdit = ref;
          }}
          type={type}
          device_app={device_app}
          reloadData={this.getData}
        />
        <TableBase
          scroll={{ x: 1000 }}
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          formAddhiddenFooter
          onOkFormAdd={this.handleOkAdd}
          lableKeyI18n={
            type === 'PRIVACY'
              ? 'other_setting.table.button.add_new_privacy'
              : 'other_setting.table.button.add_new_term'
          }
          widthModalAdd={791}
          rowKey="_id"
          allowAdd
          columns={this.columns}
          dataSource={data}
          loading={loading}
          okText={!stepAdd && t('other_setting.modal.add.button.next')}
          formAdd={(props) => (
            <FormAdd
              stepAdd={stepAdd}
              type={type}
              device_app={device_app}
              isCreate
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
        />
      </div>
    );
  }
}

export default TermOfUse;
