import { Button, Modal, Tabs } from 'antd';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { debounce } from 'lodash';

interface IProps {
  onRef?: (v: any) => any;
  t: any;
  reloadData?: any;
  handlePublic: any;
}

interface IState {
  visible: boolean;
  dataView: any;
}

class ModalPublic extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: undefined };
    this.resizeIframe = debounce(this.resizeIframe, 300);
  }

  handleVisible = (record?: any) => {
    this.setState({ visible: !!record, dataView: record });
  };

  resizeIframe = (e) => {
    try {
      const iframeDocument = e.target.contentDocument || e.target.contentWindow.document;
      e.target.style.height = iframeDocument.body.scrollHeight + 'px';
    } catch (e) {
      console.log(e);
    }
  };

  handlePublic = () => {
    const { dataView } = this.state;
    const { handlePublic } = this.props;
    if (handlePublic) handlePublic(dataView, this.handleVisible);
  };

  handleCancel = () => {
    this.handleVisible();
  };

  render() {
    const { visible, dataView } = this.state;
    const { onRef, t } = this.props;
    if (onRef) onRef(this);

    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={807}
      >
        <div style={{ padding: '16px 24px', minHeight: window.innerHeight - 180 }}>
          <div className="formAddAccount">
            <div className="title-modal">
              <div>{t('content.management.article.modal.warning.publish_article.title')}</div>
            </div>
          </div>
          <div style={{ padding: 16 }}>
            <Tabs defaultActiveKey="1" style={{ padding: '24px 16px' }}>
              <Tabs.TabPane tab={t('content.management.tab.modal.public.preview_web')} key="1">
                <div>
                  <span style={{ fontSize: 18, fontWeight: 600 }}>{dataView?.title}</span>
                  <div style={{ paddingTop: 12, display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundColor: '#21C0F6',
                        borderRadius: 4,
                        width: 41,
                        height: 23,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 8,
                        fontSize: 10,
                        color: '#fff',
                        textTransform: 'uppercase',
                        display: 'flex',
                      }}
                    >
                      {t('content.management.article.detail.news')}
                    </div>
                    <span style={{ fontSize: 10, color: '#777777' }}>
                      {moment().format('YYYY/MM/DD HH:mm')}
                    </span>
                  </div>
                  {dataView?.source_url && (
                    <iframe
                      title="title"
                      allowFullScreen
                      onLoad={this.resizeIframe}
                      style={{
                        width: '100%',
                        border: 0,
                        marginTop: 18,
                        background: '#fff',
                        minHeight: 500,
                      }}
                      src={dataView?.source_url}
                    />
                  )}
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab={t('content.management.tab.modal.public.preview_mobile')} key="2">
                <div style={{ width: 324, margin: 'auto' }}>
                  <span style={{ fontSize: 18, fontWeight: 600, wordBreak: 'break-word' }}>
                    {dataView?.title}
                  </span>
                  <div style={{ paddingTop: 12, display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundColor: '#21C0F6',
                        borderRadius: 4,
                        width: 41,
                        height: 23,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 8,
                        fontSize: 10,
                        color: '#fff',
                        textTransform: 'uppercase',
                        display: 'flex',
                      }}
                    >
                      {t('content.management.article.detail.news')}
                    </div>
                    <span style={{ fontSize: 10, color: '#777777' }}>
                      {moment().format('YYYY/MM/DD HH:mm')}
                    </span>
                  </div>
                  {dataView?.source_url && (
                    <iframe
                      title="title"
                      allowFullScreen
                      onLoad={this.resizeIframe}
                      style={{
                        width: '100%',
                        border: 0,
                        marginTop: 18,
                        background: '#fff',
                        minHeight: 500,
                      }}
                      src={dataView?.source_url}
                    />
                  )}
                </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
        <div
          style={{
            boxShadow: '0px -1px 0px #EFEFF4',
            height: 60,
            padding: '10px 12px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button onClick={this.handlePublic} type="primary" style={{ height: 40 }}>
            {t('content.management.button.publish')}
          </Button>
          <Button style={{ height: 40, marginLeft: 12 }} onClick={this.handleCancel}>
            {t('common.button.cancel')}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(ModalPublic);
