import axiosConfig from 'config/axios';

const surveySetting = {
  async list() {
    try {
      const data = await axiosConfig.get('/admin/survey-setting/list', {});
      return data;
    } catch (e) {
      throw e;
    }
  },
  async action(dataReq) {
    try {
      const data = await axiosConfig.post('/admin/survey-setting/action', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async visibility(dataReq) {
    try {
      const data = await axiosConfig.put('/admin/survey-setting/visibility', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async order(dataReq) {
    try {
      const data = await axiosConfig.post('/admin/survey-setting/order', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },

};

export default surveySetting;
