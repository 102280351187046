import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import InputBase from 'components/Input';
import { II18n } from 'index';
import { debounce } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import auth from 'services/auth';
import { login, validateEmail } from 'utils';
import throwException from 'utils/throwException';
import './styles.scss';

interface IProps extends II18n {
  history: any;
  form: any;
  dispatch: any;
  forgotPassword: any;
}

interface IState {
  loading: boolean;
}

class FormLogin extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleLogin = debounce(this.handleLogin, 300);
    this.state = { loading: false };
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleLogin = (values) => {
    this.setState({ loading: true });
    auth
      .login(values)
      .then(({ data }) => {
        const { access_token, refresh_token } = data.data;
        login(access_token, refresh_token);
        this.handleFetchData();
      })
      .catch((e) => {
        throwException(e, {
          callbackCheck: (err_c) => err_c === 'AD20001' || err_c === 'AD10001',
          callbackError: (err_c) => {
            const { form, t } = this.props;
            if (err_c === 'AD20001') {
              form.setFields([{ name: 'password', errors: [t('login.password.invalid')] }]);
            } else if (err_c === 'AD10001') {
              form.setFields([{ name: 'email', errors: [t('login.email.not_existed')] }]);
            }
          },
        });
        this.setState({ loading: false });
      });
  };

  handleFetchData = () => {
    const { history } = this.props;
    auth
      .getUser()
      .then(({ data }) => {
        const { dispatch } = this.props;
        dispatch({
          type: 'user/change',
          payload: { user: data?.data },
          callback: () => history.push('/account-management'),
        });
      })
      .catch(throwException);
  };

  render() {
    const { t, form, forgotPassword } = this.props;
    const { loading } = this.state;
    return (
      <Spin spinning={loading}>
        <Form
          form={form}
          onFinish={this.handleLogin}
          style={{ paddingTop: 42 }}
          colon={false}
          layout="vertical"
          autoComplete="off"
          className="form-login-success"
          scrollToFirstError
        >
          <Form.Item
            label={t('login.form.input.email.title')}
            name="email"
            rules={[
              {
                required: true,
                message: t('login.form.input.email.required'),
              },
              { validator: this.handleValidateEmail },
            ]}
          >
            <InputBase allowClear placeholder={t('login.form.input.email.placeholder')} />
          </Form.Item>

          <div style={{ position: 'relative' }}>
            <Form.Item
              label={t('login.form.input.password.title')}
              name="password"
              rules={[
                {
                  required: true,
                  message: t('login.form.input.password.required'),
                  whitespace: true,
                },
              ]}
            >
              <InputBase.Password placeholder={t('login.form.input.password.placeholder')} />
            </Form.Item>
            <div onClick={forgotPassword} className="forgot-password">
              <span style={{ cursor: 'pointer' }}>{t('login.forgot_password')}</span>
            </div>
          </div>

          <Form.Item name="button" style={{ paddingTop: 10 }}>
            <Button htmlType="submit" style={{ height: 50 }} type="primary" block>
              {t('login.form.button.login')}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default connect()(withUseFormHook(FormLogin));
