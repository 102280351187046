import axiosConfig from 'config/axios';

const accessLog = {
  async list(dataReq) {
    try {
      const data = await axiosConfig.get('/admin/access-log/incident-log', { params: dataReq });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async exportLog(dataReq) {
    try {
      const data = await axiosConfig.get('/admin/access-log/incident-log/export', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default accessLog;
