import { Layout } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Header from './Header';
import MenuLeft from './MenuLeft';
import './styles.scss';

const { Footer } = Layout;

interface IProps {
  children: any;
  history: any;
  t: any;
}

export interface LayoutHandle {
  removeLayout: () => any;
  addLayout: () => any;
}

const layout: LayoutHandle = {
  removeLayout: function () {
    throw new Error('Function not implemented.');
  },
  addLayout: function () {
    throw new Error('Function not implemented.');
  },
};

interface IState {
  layout: boolean;
}

class LayoutBase extends Component<IProps, IState> {
  static create: () => (Componet: any) => (props: any) => JSX.Element;
  constructor(props: IProps) {
    super(props);
    this.state = { layout: true };
    layout.removeLayout = this.handleRemoveLayout;
    layout.addLayout = this.handleAddLayout;
  }

  shouldComponentUpdate(nProps: IProps, nState: IState) {
    const { layout } = this.state;
    return layout !== nState.layout;
  }

  handleRemoveLayout = () => {
    const { layout } = this.state;
    if (layout) {
      this.setState({ layout: false });
    }
  };

  handleAddLayout = () => {
    const { layout } = this.state;
    if (!layout) {
      this.setState({ layout: true });
    }
  };

  render() {
    const { children, t } = this.props;
    const { layout } = this.state;
    return (
      <Layout className="layout" style={{ backgroundColor: '#fff' }}>
        {layout ? <Header /> : null}
        <div style={{ display: 'flex', alignItems: 'flex-start', height: '100%' }}>
          <MenuLeft t={t} />
          <div className="contentbody" style={{ paddingBottom: layout ? 80 : 0 }}>
            {children}
          </div>
        </div>
        {layout ? (
          <Footer className="footer">{t('footer.copyright', { name: '1.0.10' })}</Footer>
        ) : null}
      </Layout>
    );
  }
}

export { layout };
export default withTranslation()(LayoutBase);
