import { Provider } from 'react-redux';
import store from './sagas/store';
import Page from './Pages';
import Network from 'Network';
import { useTranslation } from 'react-i18next';
import { Spin, ConfigProvider } from 'antd';
import ModalWarning from 'components/ModalWarning';
import { useEffect } from 'react';

import locale from 'antd/es/locale/ja_JP';
import moment from 'moment';
import 'moment/locale/ja';
moment.updateLocale('ja', { week: { dow: 0 } });

const App = () => {
  const { t, ready } = useTranslation();

  useEffect(() => {
    window.addEventListener(
      'load',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    window.addEventListener(
      'resize',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    return () => {
      window.removeEventListener(
        'load',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
      window.removeEventListener(
        'resize',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
    };
  }, []);

  if (!ready) return <Spin spinning />;
  return (
    <Provider store={store}>
      <ConfigProvider locale={locale} autoInsertSpaceInButton={false}>
        <Page />
        <ModalWarning />
        <Network t={t} />
      </ConfigProvider>
    </Provider>
  );
};
export default App;
