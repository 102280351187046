import { Component } from 'react';
import { Switch } from 'antd';

interface IProps {
  onChange?: (v: boolean) => any;
  text: any;
  checked?: boolean;
  size?: 'small';
  style?: any;
  disabled?: boolean;
}

class SwitchBox extends Component<IProps> {
  render() {
    const { checked, text, size, onChange, style, disabled } = this.props;
    return (
      <div
        onClick={() => {
          if (disabled) {
            return;
          }
          onChange?.(!checked);
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          userSelect: 'none',
          ...style,
        }}
      >
        <Switch disabled={disabled} checked={!!checked} size={size} style={{ marginRight: 12 }} />
        {text}
      </div>
    );
  }
}

export default SwitchBox;
