import { Form, Spin } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { validateEmail } from 'utils';
import throwException from 'utils/throwException';
import content from 'services/content';
import InputBase from 'components/Input';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;

interface IProps {
  t: any;
  onRef?: (r: any) => any;
  i18n: any;
  form: any;
  isCreate: boolean;
  status?: string;
}

interface IState {
  loading: boolean;
  rangDatePicker: Array<any>;
}

class FormAddAccount extends Component<IProps, IState> {
  modalWarning: any;
  constructor(props: IProps) {
    super(props);
    this.state = { loading: false, rangDatePicker: [] };
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { loading } = this.state;
    return loading !== nState.loading;
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleOk = (values, callback) => {
    values.start_schedule = values.schedue?.[0];
    values.end_schedule = values.schedue?.[1];
    delete values.schedue;
    const { loading } = this.state;
    if (loading) return;
    content
      .create({
        ...values,
        content_type: 'MAINTANCE',
      })
      .then(() => {
        if (callback) callback(true);
      })
      .catch((e) => {
        throwException(e);
        if (callback) callback(false);
      });
  };

  setLoading = (flag: boolean) => {
    this.setState({ loading: flag });
  };

  changRangeDate = (date: any) => {
    this.setState({ rangDatePicker: date });
  };
  onBlurRangeDate = () => {
    const { rangDatePicker } = this.state;
    if (rangDatePicker[0] && !rangDatePicker[1]) {
      this.setState({ rangDatePicker: [] });
    }
  };

  disabledDate = (current) => {
    if (this.state.rangDatePicker && this.state.rangDatePicker[0]) {
      return current.diff(this.state.rangDatePicker[0]) <= 0;
    }
    return current < moment().add(-1, 'days').endOf('seconds');
  };

  checkStartDate = () => {
    const { t } = this.props;
    if (this.state.rangDatePicker && this.state.rangDatePicker[0]) {
      let current = moment();
      if (current.isAfter(this.state.rangDatePicker[0])) {
        return Promise.reject(new Error(t('maintenance.create.scheduleAfterNow')));
      }
    }
    return Promise.resolve();
  };

  render() {
    const { loading } = this.state;
    const { t, onRef, isCreate } = this.props;
    if (onRef) onRef(this);
    return (
      <Spin spinning={loading}>
        <div className="formAddAccount">
          <div className="title-modal">
            <div>
              {isCreate
                ? t('content.management.modal.create_new_maintance.title')
                : t('content.management.modal.edit_maintance.title')}
            </div>
          </div>
          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('content.management.maintenance.column.title.required'),
              },
            ]}
            name="title"
            label={t('content.management.maintenance.column.title')}
          >
            {isCreate ? (
              <InputBase
                placeholder={t('content.management.maintenance.column.title.placeholder')}
                maxLength={70}
              />
            ) : (
              <InputBase
                disabled={this.props.status !== '' && this.props.status !== 'READY_TO_NOTIFYRM'}
                placeholder={t('content.management.maintenance.column.title.placeholder')}
                maxLength={256}
              />
            )}
          </Form.Item>

          <Form.Item name="id" style={{ display: 'none' }}>
            <InputBase type="hidden" />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                whitespace: true,
                message: t('content.management.modal.create_new.field.content.required'),
              },
            ]}
            name="content"
            label={t('content.management.modal.create_new.field.content')}
          >
            {isCreate ? (
              <InputBase.TextArea
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder={t('content.management.modal.create_new.field.content.placeholder')}
                maxLength={12000}
              />
            ) : (
              <InputBase.TextArea
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={this.props.status !== '' && this.props.status !== 'READY_TO_NOTIFYRM'}
                placeholder={t('content.management.modal.create_new.field.content.placeholder')}
                maxLength={12000}
              />
            )}
          </Form.Item>

          <Form.Item name="memo" label={t('content.management.modal.create_new.field.memo')}>
            {isCreate ? (
              <InputBase.TextArea
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder={t('content.management.modal.create_new.field.memo.placeholder')}
                maxLength={12000}
              />
            ) : (
              <InputBase.TextArea
                autoSize={{ minRows: 6, maxRows: 6 }}
                placeholder={t('content.management.modal.create_new.field.memo.placeholder')}
                disabled={this.props.status !== '' && this.props.status !== 'READY_TO_NOTIFYRM'}
                maxLength={12000}
              />
            )}
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: t('content.management.modal.create_new.field.schedue.required'),
              },
              { validator: this.checkStartDate },
            ]}
            name="schedue"
            label={t('content.management.modal.create_new.field.schedue')}
          >
            <RangePicker
              style={{ height: 42, borderRadius: 4 }}
              placeholder={[t('field.date.placeholder'), t('field.date.placeholder')]}
              // showTime
              format="YYYY/MM/DD HH:mm"
              onCalendarChange={this.changRangeDate}
              disabledDate={this.disabledDate}
              showTime={{ format: 'HH:mm' }}
              onBlur={() => {
                this.onBlurRangeDate();
              }}
              disabled={
                !isCreate && this.props.status !== '' && this.props.status !== 'READY_TO_NOTIFYRM'
              }
              // disabled
            />
          </Form.Item>
        </div>
      </Spin>
    );
  }
}

export default withTranslation()(FormAddAccount);
