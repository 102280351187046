import axiosConfig from 'config/axios';

const auth = {
  async login(dataReq) {
    try {
      const data = await axiosConfig.post('/admin/login', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async getUser() {
    try {
      const data = await axiosConfig.get('/admin-cms/info');
      return data;
    } catch (e) {
      throw e;
    }
  },
  async removeUser(id: string) {
    try {
      await axiosConfig.delete(`/admin-cms/${id}`);
    } catch (e) {
      throw e;
    }
  },
  async resetPassword(email: string) {
    try {
      const data = await axiosConfig.post('/admin/forgot_password', { email });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async checkToken(token: string) {
    try {
      const data = await axiosConfig.get(`/admin/check-token?token=${token}`);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async updatePassword(dataReq) {
    try {
      const data = await axiosConfig.post('/admin/set_password', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default auth;
