import { InfoCircleFilled, MenuOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Space, Spin, Switch, Table, Tooltip, Typography } from "antd";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import React from 'react';
import { withTranslation } from "react-i18next";
import surveySetting from "services/surveySetting";
import { arrayMoveImmutable } from 'array-move';
import './styles.scss';
import FormSurveyQs, { EQuestionType } from "./components/FormSurveyQs";
import { EActionIncidentQuestion } from "interfaces";
import { trim } from "lodash";
import { showMessageError, showMessageSuccess } from 'utils';
import modal from "antd/lib/modal";
import throwException from "utils/throwException";

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
));
export const SortableItem = SortableElement((props: any) => <tr {...props} />);
export const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

interface IProps {
  pathname: string;
  t: any;
  form: any;
}

interface IState {
  isLoading: boolean;
  dataSource: any[];
  isVisibleForm: boolean;
  selectedItem?: any;
  formRef: any;
  childFormRef: any;
  t: any;
}

class SurveySetting extends React.Component<IProps, IState> {
  confirmModal: any;

  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      t: t,
      isLoading: false,
      dataSource: [],
      isVisibleForm: false,
      formRef: React.createRef(),
      childFormRef: React.createRef()
    };

  }

  getAll = async () => {

    this.setState({ isLoading: true })
    surveySetting.list().then(({ data }) => {
      this.setState({ dataSource: data.data })
    }).catch((e) => {
      throwException(e);
    }).finally(() => { this.setState({ isLoading: false }) });

  }
  order = async (dataReq) => {
    surveySetting.order(dataReq)
      .then(({ data }) => {
        this.getAll();
      }).catch((e) => {
        throwException(e);
      });

  }
  componentDidMount() {
    this.getAll();
  }
  handleWarningDelete = async (record: any) => {
    if (this.state.dataSource.filter(x => x.visibility == true || (x._id === record._id && x.visibility == false)).length > 1) {
      const payload = {
        action: EActionIncidentQuestion.REMOVE,
        id: record?._id
      }
      surveySetting.action(payload).then(({ data }) => {
        showMessageSuccess(this.props.t("survey_setting.confirmRemove.success"))
        this.getAll();
      }).catch((e) => {
        throwException(e);
      });
    } else {
      this.openMessageMinRecordModal()

    }

  }
  handleChangeVisibility = async (checked: boolean, question_id: string) => {
    if (this.state.dataSource.filter(x => x.visibility == true).length > 1 || checked == true) {
      const payload = {
        id: question_id,
        status: checked,
      }
      // setState({ loading: true });
      await surveySetting.visibility(payload)
        .then(({ data }) => {
          if (data.status == "OK") {
            this.getAll();
          } else {
            showMessageError(data.error_message)
          }
        }).catch((e) => {
          throwException(e);
        });
    } else {
      this.openMessageMinRecordModal()

    }

  };
  openConfirmModal = (action, isConfirmDelete = false) => {
    const { t } = this.props;
    const config = {
      title: isConfirmDelete
        ? t('survey_setting.confirmRemove.title')
        : t('survey_setting.confirmRemove.title'),
      content: (
        <React.Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 24 }}>
            <span>
              {isConfirmDelete
                ? t('survey_setting.confirmRemove.body')
                : t('dasurvey_setting.order.body')}
            </span>
          </div>
          <div className="footer-bottom-modal">
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Button
                type="primary"
                danger

                style={{ marginRight: 12, height: 40 }}
                onClick={() => {
                  this.confirmModal?.destroy();
                  action && action();
                }}
              >
                {isConfirmDelete
                  ? t('survey_setting.confirmRemove.okBtn')
                  : t('data_report.import_incidents.confirmImport.okBtn')}
              </Button>
              <Button
                style={{ height: 40 }}
                onClick={() => {
                  this.confirmModal?.destroy();
                }}
              >
                {isConfirmDelete
                  ? t('survey_setting.confirmRemove.cancelBtn')
                  : t('survey_setting.confirmRemove.cancelBtn')}
              </Button>
            </ConfigProvider>
          </div>
        </React.Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 516,
    };
    this.confirmModal = modal.confirm(config);
  };

  openOrderConfirmModal = (action) => {
    const { t } = this.props;
    const config = {
      title: t('survey_setting.ordermodal.title'),
      content: (
        <React.Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 24 }}>
            <span>
              {t('survey_setting.order.body')}
            </span>
          </div>
          <div className="footer-bottom-modal">
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Button
                type="primary"
                style={{ marginRight: 12, height: 40, background: '#18BCF3' }}
                onClick={() => {
                  this.confirmModal?.destroy();
                  action && action();
                }}
              >
                {t('account.management.modal.edit.button.save')}
              </Button>
              <Button
                style={{ height: 40 }}
                onClick={() => {
                  this.confirmModal?.destroy();
                }}
              >
                {t('survey_setting.confirmRemove.cancelBtn')}
              </Button>
            </ConfigProvider>
          </div>
        </React.Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 516,
    };
    this.confirmModal = modal.confirm(config);
  };

  openMessageMinRecordModal = () => {
    const { t } = this.props;
    const config = {
      title: t('survey_setting.modal.min.title'),
      content: (
        <React.Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 24 }}>
            <span>
              {t('survey_setting.modal.min.body')}
            </span>
          </div>
          <div className="footer-bottom-modal" style={{ textAlign: 'center' }} >
            <ConfigProvider autoInsertSpaceInButton={false}>
              <Button
                type="primary"


                style={{ marginRight: 12, height: 40, background: '#18BCF3' }}
                onClick={() => {
                  this.confirmModal?.destroy();
                }}
              >
                {t('survey_setting.modal.min.ok')}
              </Button>

            </ConfigProvider>
          </div>
        </React.Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 516,
      height: 262
    };
    this.confirmModal = modal.confirm(config);
  };

  render() {
    const columns: any = [
      {
        dataIndex: 'sort',
        width: 48,
        align: 'center',
        className: 'drag-visible',
        render: () => (
          <Tooltip placement="topLeft" title={'dragIcon'}>
            <div>
              <DragHandle />
            </div>
          </Tooltip>
        ),
      },
      {
        title: <Space>
          <Typography.Text>質問</Typography.Text>
          <Tooltip title="STANDBYアプリの報告相談画面に質問を追加することができます。複数の質問を設定できますが、質問数を多くしすぎると報告相談数の減少をまねく可能性があります。あなたの組織にあった質問内容、質問数を設定してください。">
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>,
        dataIndex: 'question_name',
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: any) => {
          const questionName: string = record?.question_name;

          return (
            <Tooltip
              placement="bottomLeft"
              title={<span style={{ whiteSpace: 'pre-wrap' }}>{questionName}</span>}
            >
              <Typography.Text
                onClick={() => handleVisibleForm(record)}
                className="txt-question text-ellipsis"
              >
                {questionName}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title:
          <Space>
            <Typography.Text>質問の種類</Typography.Text>
            <Tooltip title="テキストボックス、プルダウン (単一選択) 、チェックボックス (複数選択) などの質問の種類があります。">
              <InfoCircleFilled className="ic-info" />
            </Tooltip>
          </Space>
        ,
        dataIndex: 'type',
        width: 220,
        render: (_: any, record: any) => {

          console.log(record?.question_type)
          return this.props.t(`survey_setting.default.question.type.${record?.question_type.toLocaleLowerCase?.()}`)
        }

      },
      {
        title: '必須回答',
        dataIndex: 'is_required',
        width: 90,
        align: 'center',
        render: (_: any, record: any) => {
          const isRequired: boolean = record?.is_required;

          if (isRequired) {
            return <img alt="" src="/images/ic-check-grey.png" />;
          }

          return null;
        },
      },
      {
        title: 'アプリでの表示',
        dataIndex: 'visibility',
        width: 190,
        align: 'center',
        render: (_: any, record: any) => {
          return (
            <Switch
              defaultChecked={!!record?.visibility}
              checked={!!record?.visibility}
              size="small"
              className="visibility"
              onChange={(checked: boolean) => {
                this.handleChangeVisibility(checked, record?._id)
              }}
            />
          );
        },
      },
      {
        title: () => {

          return (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="btn-add-question"
              onClick={onClickAddQuestion}
            >
              質問を追加
            </Button>
          );
        },
        dataIndex: 'delete',
        width: 180,
        align: 'right',
        render: (_: any, record: any) => {

          return (
            <Space size={15}>
              <Tooltip placement="topLeft" title={'編集'}>
                <img
                  alt=""
                  src="/images/ic-edit.png"
                  className="ic-edit"
                  onClick={() => handleVisibleForm(record)}
                />
              </Tooltip>
              {this.state.dataSource.filter(x => x.visibility == true).length == 1 && this.state.dataSource.findIndex(x => x.visibility == true && x._id == record._id) > -1 ? (

                <Tooltip placement="topLeft" title={'削除'}  >
                  <img
                    alt=""
                    src="/images/ic-delete.png"
                    className="ic-delete"
                    style={{ cursor: 'not-allowed' }}
                  />

                </Tooltip>
              ) : (
                <Tooltip placement="topLeft" title={'削除'}  >
                  <img
                    alt=""
                    src="/images/ic-delete.png"
                    className="ic-delete"
                    onClick={() => {
                      this.openConfirmModal(() => {
                        this.handleWarningDelete(record);
                      }, true);
                    }}
                  />
                </Tooltip>

              )}

            </Space>
          );
        },
      },
    ];



    const onSortEndFinish = ({ oldIndex, newIndex }) => {
      console.log(1)
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([].concat(...this.state.dataSource), oldIndex, newIndex).filter(
          (el) => !!el
        );
        const newOrders: string[] = newData.map((e: any) => e?._id);
        this.order({ orders: newOrders });
        console.log(newOrders);
        this.setState({ dataSource: newData })

        console.log(oldIndex, newIndex);
      }
    };
    const onSortEnd = ({ oldIndex, newIndex }) => {
      this.openOrderConfirmModal(() => { onSortEndFinish({ oldIndex, newIndex }) })
    };
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = this.state.dataSource.findIndex((x) => x._id === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
    };
    const DraggableContainer = (props: any) => (
      <SortableContainers
        useDragHandle
        helperClass="row-dragging survey-question-drag"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
    const onClickAddQuestion = () => {
      this.setState({ isVisibleForm: true })
    };
    const handleCancelForm = () => {
      this.setState({ selectedItem: null, isVisibleForm: false, isLoading: false })
    };
    const onFinishForm = async ({
      visibility,
      question_name,
      question_type,
      is_required,
    }: any) => {
      const { t } = this.props;
      const isEdited: boolean = !!this.state.selectedItem;
      this.setState({ isLoading: true })

      const containDataAnswers: EQuestionType[] = [
        EQuestionType.DROPDOWN,
        EQuestionType.CHECKBOXLIST,
        EQuestionType.RATING,
      ];
      let dataAnswers: string | string[] = '';
      if (containDataAnswers.includes(question_type)) {

        dataAnswers = this.state.childFormRef.current.getDataAnswers();
        if (dataAnswers == '') {
          // seIsLoading(false);
          showMessageError(this.state.t("response.data.error_message"));

          return;
        }
      }
      if (isEdited) {

        const payload = {
          id: this.state.selectedItem._id,
          action: EActionIncidentQuestion.UPDATE,
          visibility,
          question_name: trim(question_name),
          question_type,
          answer_value: dataAnswers,
          is_required
        }
        // setState({ loading: true });

        surveySetting.action(payload).then(({ data }) => {
          if (data.status == "OK") {
            handleCancelForm();
            showMessageSuccess(t("survey_setting.edit.success"))
            this.getAll();
          } else {
            showMessageError(data.error_message)
          }
        }).catch((e) => {
          throwException(e);
        });

      }
      else {
        const payload = {

          action: EActionIncidentQuestion.ADD,
          visibility,
          question_name: trim(question_name),
          question_type,
          answer_value: dataAnswers,
          is_required
        }
        // setState({ loading: true });
        await surveySetting.action(payload).then(({ data }) => {
          if (data.status == "OK") {
            handleCancelForm();
            showMessageSuccess(t("survey_setting.add.success"))
            this.getAll();
          } else {
            showMessageError(data.error_message)
          }
        }).catch((e) => {
          throwException(e);
        });

      }
    };

    const handleVisibleForm = async (item: any) => {
      this.setState({ selectedItem: item, isVisibleForm: true })
    };

    return (
      <Spin spinning={this.state.isLoading}>
        <div
          className="survey-question" >
          <Table
            pagination={false}
            dataSource={this.state.dataSource}
            columns={columns}
            rowKey={(item: any) => item?._id || item?.question_detail?._id}
            components={
              this.state.dataSource.length
                ? {
                  body: {
                    wrapper: DraggableContainer,
                    row: DraggableBodyRow,
                  },
                }
                : undefined
            }
            className="table-question"
            scroll={{ y: 'calc(100vh - 233px)' }}
          />
          <FormSurveyQs
            isVisible={this.state.isVisibleForm}
            onCancel={handleCancelForm}

            isLoading={this.state.isLoading}
            onFinish={onFinishForm}
            formRef={this.state.formRef}
            ref={this.state.childFormRef}
            selectedItem={this.state.selectedItem}
          />

        </div >
      </Spin>
    );
  }
};
export default withTranslation()(SurveySetting); 