const LogoIcon = () => {
  return (
    <>
      <svg width="150" height="32" style={{position: "absolute"}}  viewBox="0 0 150 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_122_28145)">
          <path d="M43.8125 7.91138C43.5879 6.38899 42.9826 4.94794 42.0527 3.72181C41.1228 2.49568 39.8984 1.52416 38.493 0.897315L38.3303 0.818757C37.9273 0.651077 37.5129 0.512315 37.0902 0.403525L37.0341 0.375468C35.3244 -0.061838 33.5247 0.00479326 31.852 0.567327C30.1794 1.12986 28.7051 2.16427 27.6072 3.54583C27.4276 3.80955 27.2368 4.10695 27.046 4.35946C27.0281 4.37556 27.0129 4.39452 27.0011 4.41557C24.7857 7.88541 21.6075 10.6347 17.8548 12.3274C17.84 12.3306 17.8247 12.3306 17.8099 12.3274C17.6303 12.4116 17.4452 12.4902 17.2488 12.5631L17.4396 12.9166C17.9483 13.9267 18.3906 14.9689 18.7638 16.0365C19.1566 16.9455 21.8051 22.8485 27.6184 25.5307C30.4657 26.8069 33.6308 27.1979 36.703 26.653L36.6188 26.7259L36.5178 26.8157C36.097 27.1636 35.6649 27.4947 35.2216 27.8145C35.6649 27.4947 36.097 27.1636 36.5178 26.8157C39.9805 23.9682 42.4872 20.1279 43.7002 15.812L43.7339 15.6886C44.334 13.1338 44.3608 10.4778 43.8125 7.91138Z" fill="#FFA4C9"/>
          <path d="M36.6919 26.6866C33.6196 27.2315 30.4545 26.8405 27.6072 25.5644C21.794 22.8766 19.1455 16.9736 18.7527 16.0645C18.3795 14.9969 17.9372 13.9548 17.4284 12.9447L17.2377 12.5912C15.6994 13.2324 14.091 13.6901 12.4456 13.9547C10.0142 14.5189 7.48734 14.5323 5.05001 13.994C4.48218 13.921 3.91982 13.8104 3.36664 13.6629H3.33297C2.22182 13.2378 1.16511 12.6821 0.185058 12.0076C0.0751874 12.5188 0.0131956 13.0392 -0.000112891 13.5619C-0.00488211 13.9272 0.0101031 14.2925 0.0447771 14.6561C0.0447771 14.6561 0.0447771 14.701 0.0447771 14.7178C0.144418 15.3172 0.275528 15.911 0.437565 16.4966C2.12094 22.6241 7.21596 27.9941 14.1739 30.4798H14.2356C15.0116 30.7257 15.8002 30.9298 16.598 31.0915L17.1254 31.2318C18.666 31.6222 20.2447 31.8424 21.8333 31.8883C22.3158 31.8883 22.7984 31.8883 23.281 31.8883L23.8758 31.8546L24.5603 31.8041C28.9443 31.3774 33.1006 29.65 36.4955 26.8437L36.5965 26.754L36.6919 26.6866Z" fill="#18BCF3"/>
          <path d="M3.33299 13.6573H3.36666C3.91984 13.8048 4.4822 13.9154 5.05003 13.9884C7.48472 14.5295 10.0095 14.5199 12.4401 13.9603C14.0854 13.6957 15.6939 13.238 17.2321 12.5968C17.1535 12.4509 17.0637 12.2994 16.9796 12.1366C15.891 10.2457 13.9607 7.38953 10.6164 6.43562C10.2853 6.33461 5.40354 4.9823 2.27246 8.0741C1.83357 8.51735 1.45612 9.01747 1.15021 9.56108C0.988371 9.83987 0.844069 10.1285 0.718147 10.4252C0.496085 10.934 0.32686 11.4642 0.213135 12.0076C1.18371 12.6719 2.22895 13.22 3.32738 13.6405L3.33299 13.6573Z" fill="#18C9F3"/>
          <path d="M51.9151 11.9964C52.3977 7.92821 58.5532 7.19314 61.4543 9.4152C62.8795 10.5711 61.3701 12.4004 60.1132 11.4745C58.5589 10.4869 55.0294 10.0268 54.5861 12.2096C54.025 15.6549 63.1545 13.6797 63.003 19.2629C62.8571 24.5992 55.1472 24.7227 52.2294 22.3267C51.9423 22.0813 51.7543 21.7399 51.7006 21.366C51.6469 20.9922 51.7311 20.6117 51.9376 20.2954C52.0432 20.1524 52.1783 20.0338 52.3337 19.9476C52.4892 19.8615 52.6613 19.8097 52.8386 19.7959C53.0158 19.782 53.1939 19.8064 53.3608 19.8675C53.5277 19.9285 53.6796 20.0247 53.8061 20.1495C55.5288 21.3223 59.9785 22.2032 60.2703 19.2237C60.001 16.0926 51.2642 17.9611 51.9151 11.9964Z" fill="white"/>
          <path d="M69.366 11.4745H65.6907C65.5069 11.4864 65.3226 11.4605 65.1492 11.3983C64.9759 11.3362 64.8171 11.2391 64.6827 11.1131C64.5484 10.9872 64.4413 10.835 64.3681 10.666C64.2949 10.497 64.2571 10.3148 64.2571 10.1306C64.2571 9.94647 64.2949 9.76425 64.3681 9.59526C64.4413 9.42627 64.5484 9.27409 64.6827 9.14814C64.8171 9.02218 64.9759 8.92512 65.1492 8.86296C65.3226 8.8008 65.5069 8.77486 65.6907 8.78674H75.6226C75.8064 8.77486 75.9906 8.8008 76.164 8.86296C76.3374 8.92512 76.4961 9.02218 76.6305 9.14814C76.7649 9.27409 76.872 9.42627 76.9452 9.59526C77.0184 9.76425 77.0562 9.94647 77.0562 10.1306C77.0562 10.3148 77.0184 10.497 76.9452 10.666C76.872 10.835 76.7649 10.9872 76.6305 11.1131C76.4961 11.2391 76.3374 11.3362 76.164 11.3983C75.9906 11.4605 75.8064 11.4864 75.6226 11.4745H72.1324V22.0798C72.1324 23.9035 69.366 23.9483 69.366 22.0798V11.4745Z" fill="white"/>
          <path d="M76.9188 23.3816C76.7407 23.2946 76.5818 23.1729 76.4514 23.0236C76.321 22.8743 76.2218 22.7004 76.1596 22.5122C76.0974 22.324 76.0734 22.1252 76.0892 21.9276C76.1049 21.73 76.1599 21.5376 76.2511 21.3616L82.2776 9.57792C82.3879 9.32435 82.5698 9.10851 82.8011 8.95692C83.0324 8.80532 83.3029 8.72456 83.5794 8.72456C83.8559 8.72456 84.1264 8.80532 84.3577 8.95692C84.5889 9.10851 84.7709 9.32435 84.8812 9.57792L90.8011 21.3616C91.6596 23 89.1177 24.3018 88.3658 22.6634L87.4399 20.8173H79.6796L78.7762 22.6634C78.4563 23.3816 77.6764 23.5275 76.9188 23.3816ZM85.9473 18.0509L83.6074 12.8605L80.9926 18.0509H85.9473Z" fill="white"/>
          <path d="M92.7314 10.3186C92.7314 9.5611 92.8997 8.82602 93.9883 8.82602C94.7458 8.82602 94.9142 9.0168 95.4809 9.5611L102.433 18.2585V10.111C102.437 9.74923 102.573 9.40134 102.816 9.13341C103.059 8.86548 103.392 8.69618 103.752 8.65768C104.133 8.68164 104.494 8.84137 104.767 9.10796C105.041 9.37455 105.21 9.73038 105.245 10.111V21.9283C105.263 22.265 105.16 22.5972 104.954 22.8641C104.748 23.131 104.453 23.3147 104.122 23.3816C103.804 23.3943 103.488 23.3348 103.196 23.2075C102.905 23.0801 102.646 22.8884 102.439 22.6465L95.4809 13.8256V21.9283C95.5017 22.1133 95.4828 22.3005 95.4254 22.4776C95.368 22.6547 95.2735 22.8174 95.1481 22.955C95.0227 23.0926 94.8694 23.2018 94.6985 23.2754C94.5275 23.349 94.3428 23.3852 94.1567 23.3816C93.9656 23.3873 93.7754 23.3533 93.598 23.2819C93.4207 23.2105 93.26 23.1032 93.1262 22.9667C92.9923 22.8302 92.8881 22.6675 92.8202 22.4888C92.7523 22.3101 92.722 22.1192 92.7314 21.9283V10.3186Z" fill="white"/>
          <path d="M109.61 23.3816C109.428 23.3995 109.244 23.3793 109.07 23.3222C108.896 23.265 108.735 23.1723 108.599 23.0498C108.463 22.9273 108.354 22.7778 108.279 22.6108C108.203 22.4438 108.164 22.263 108.162 22.0798V10.1503C108.194 9.79015 108.361 9.45535 108.629 9.21365C108.898 8.97196 109.249 8.84142 109.61 8.84846H114.419C124.014 8.84846 123.807 23.3816 114.61 23.3816H109.61ZM110.934 11.4128V20.8173H114.419C120.086 20.8173 120.339 11.4128 114.419 11.4128H110.934Z" fill="white"/>
          <path d="M125.271 23.3816C125.101 23.3833 124.933 23.3504 124.777 23.2851C124.62 23.2197 124.479 23.1233 124.36 23.0015C124.242 22.8797 124.15 22.7353 124.09 22.5768C124.029 22.4184 124.002 22.2493 124.008 22.0798V10.1503C124 9.97893 124.027 9.80768 124.088 9.64732C124.149 9.48697 124.242 9.341 124.362 9.21865C124.483 9.0963 124.627 9.00022 124.786 8.9365C124.946 8.87278 125.116 8.8428 125.288 8.84845H131.191C133.94 8.84845 135.433 10.717 135.433 12.8605C135.451 13.3595 135.356 13.8562 135.156 14.3137C134.956 14.7712 134.656 15.1778 134.277 15.5034C135.001 15.7471 135.625 16.2191 136.058 16.8486C136.49 17.478 136.706 18.2308 136.673 18.9936C136.673 21.4064 135.035 23.3816 131.881 23.3816H125.271ZM131.174 11.4128H126.741V14.5383H131.174C132.077 14.5383 132.644 13.9771 132.644 12.8549C132.638 12.4694 132.481 12.1018 132.205 11.8318C131.93 11.5618 131.56 11.4113 131.174 11.4128ZM126.741 20.8173H131.864C134.299 20.8173 134.299 17.1026 131.864 17.1026H126.741V20.8173Z" fill="white"/>
          <path d="M137.262 10.8236C136.14 9.41519 138.502 7.79915 139.613 9.33102L143.474 15.0882L147.402 9.33102C148.524 7.79915 150.802 9.33102 149.714 10.7394L144.798 17.7311V22.0573C144.989 23.881 142.026 23.881 142.026 22.0573V17.7311L137.262 10.8236Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_122_28145">
            <rect width="150" height="31.8102" fill="white" transform="translate(0 0.0949097)"/>
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LogoIcon;
