import { Component } from 'react';
import BreadcrumbBase from 'components/Breadcrumb';
import { Tabs } from 'antd';
import Article from './Article';
import { withTranslation } from 'react-i18next';
import Notice from './Notice';
import Maintance from './Maintance';

interface IProps {
  history: any;
  pathname: string;
  id: string;
  t: any;
  location: any;
}

interface IState {
  tab: string;
}

class ContentManagement extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { location } = props;
    const query = new URLSearchParams(location.search);
    this.state = { tab: query.get('tab') || '1' };
  }

  UNSAFE_componentWillReceiveProps(nProps: IProps) {
    const { location } = this.props;
    if (location.search !== nProps.location.search) {
      const query = new URLSearchParams(nProps.location.search);
      this.setState({ tab: query.get('tab') || '1' });
    }
  }

  render() {
    const { pathname, t, history } = this.props;
    const { tab } = this.state;
    return (
      <div>
        <BreadcrumbBase pathname={pathname} />
        <Tabs
          activeKey={tab}
          onChange={(tab) => history.replace({ search: `?tab=${tab}` })}
          style={{ padding: 16 }}
        >
          <Tabs.TabPane tab={t('content.management.tab.standby_news')} key="1">
            <Article t={t} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('content.management.tab.notice_from_operator')} key="2">
            <Notice t={t} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('content.management.tab.system_maintence_notice')} key="3">
            <Maintance t={t} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(ContentManagement);
