import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import throwException from 'utils/throwException';
import moment from 'moment';
import account from 'services/account';
import cms from 'services/cms';
import EmptyBase from 'components/Empty';
import { DatePicker, Select, Switch, Table, Spin } from 'antd';
import { orderBy, sumBy, includes } from 'lodash';

import '../styles.scss';
const { Option } = Select;

interface IProps {
  t: any;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  dataAcount: any[];
  dataActivationLogAcount: any[];
  rangeState: string;
  dataAccountSelect: any[];
  isShowAccountHidden: boolean;
}

class IncidentTestByAccount extends Component<IProps, IState> {
  page_number: number;
  namesearch: any;
  category_notify: any;
  start_date: any;
  end_date: any;
  constructor(props: IProps) {
    super(props);
    const { t } = this.props;
    this.state = {
      data: [],
      loading: false,
      dataAcount: [],
      dataActivationLogAcount: [],
      rangeState: 'first',
      dataAccountSelect: [t('incident_test.account.select.total')],
      isShowAccountHidden: false,
    };
    this.page_number = 1;
    this.namesearch = '';
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
    this.getDataAccount(() => this.getData());
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleChangeRangeState = (rangeState) => {
    this.setState({ rangeState }, () => this.getData());
  };

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  getDataAccount = (callback) => {
    const { loading } = this.state;
    const { t } = this.props;
    if (!loading) this.setState({ loading: true });
    account
      .listAccountRoot()
      .then(({ data }) => {
        let dataAcountList = [] as any;
        dataAcountList.push({
          value: t('incident_test.account.select.total'),
          text: t('incident_test.account.select.total'),
        });
        data.data.map((item) => {
          dataAcountList.push({ value: item._id, text: item.name });
          return item;
        });
        this.setState({ dataAcount: dataAcountList });
      })
      .catch(this.handleError)
      .finally(() => {
        callback();
      });
  };

  getData = () => {
    let { dataAccountSelect, isShowAccountHidden } = this.state;
    const { t } = this.props;
    if (!dataAccountSelect.length) {
      this.setState({ dataActivationLogAcount: [] });
      return;
    }
    if (includes(dataAccountSelect, t('incident_test.account.select.total'))) {
      dataAccountSelect = [];
    }
    this.setState({ loading: true });

    let params = {
      org_ids: dataAccountSelect,
      get_type: 'ACCOUNT',
      showHidden: isShowAccountHidden,
    };
    let end_date = moment(this.start_date ? this.start_date : new Date()).format('YYYY/MM/DD');
    let start_date = moment('1970/01/01').format('YYYY/MM/DD');
    params = { ...params, ...{ start_date: start_date, end_date: end_date } };
    cms
      .getReportTestIncident(params)
      .then(({ data }) => {
        let dataResult: any = [
          {
            totalUserActive: sumBy(data.data, 'totalUserActive'),
            userPlatformMobile: sumBy(data.data, 'userPlatformMobile'),
            userPlatformWeb: sumBy(data.data, 'userPlatformWeb'),
          },
        ];
        data.data = orderBy(data.data, 'accountId');
        dataResult = dataResult.concat(data.data);
        this.setState({ dataActivationLogAcount: dataResult });
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current.valueOf() > Date.now();
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('incident_test.table.title.account'),
        dataIndex: 'accountName',
        width: 450,
        render: (v, record) => (
          <div>{v ? <div>{`${v}`}</div> : t('incident_test.account.total')}</div>
        ),
      },
      {
        title: t('incident_test.table.title.account.total'),
        dataIndex: 'totalUserActive',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },
      {
        title: t('incident_test.table.title.account.web'),
        dataIndex: 'userPlatformWeb',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },
      {
        title: t('incident_test.table.title.account.mobile'),
        dataIndex: 'userPlatformMobile',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },
    ];
    return columns;
  };

  handeChangeDateRange = (value) => {
    console.log(value);
  };

  handleSearchMutilOption = (value) => {
    this.setState({ dataAccountSelect: value }, () => this.getData());
  };

  handleSearchDateTime = (value) => {
    this.start_date = value[0];
    this.end_date = value[1];

    this.getData();
  };

  exportLog = () => {
    const { loading, dataAccountSelect, isShowAccountHidden } = this.state;
    const { t } = this.props;
    if (!dataAccountSelect.length) return;
    if (!loading) this.setState({ loading: true });
    const paramsFilter = new URLSearchParams();
    paramsFilter.append('get_type', 'ACCOUNT');
    paramsFilter.append('standby_token', localStorage.getItem('access_token') || '');
    paramsFilter.append('showHidden', isShowAccountHidden.toString());
    if (includes(dataAccountSelect, t('incident_test.account.select.total'))) {
    } else {
      if (dataAccountSelect.length) {
        dataAccountSelect.map((item) => {
          paramsFilter.append('org_ids', item);
        });
      }
    }

    let end_date = moment(this.start_date ? this.start_date : new Date()).format('YYYY/MM/DD');
    let start_date = moment('1970/01/01').format('YYYY/MM/DD');

    paramsFilter.append('start_date', start_date);
    paramsFilter.append('end_date', end_date);

    const queryString = paramsFilter.toString();
    window.open(process.env.REACT_APP_API_URL + '/admin-cms/export/incident-test?' + queryString);
    this.setState({ loading: false });
  };

  render() {
    const { dataAcount, dataActivationLogAcount, isShowAccountHidden, loading, dataAccountSelect } =
      this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Spin spinning={loading}>
          <div className="report-admin">
            <div style={{ display: 'flex' }}>
              <Select
                optionFilterProp="children"
                onChange={(e) => this.handleSearchMutilOption(e)}
                placeholder={t('incident_test.search.date_time.placeholder')}
                mode="multiple"
                maxTagTextLength={10}
                maxTagCount={2}
                showArrow
                defaultValue={dataAccountSelect}
                style={{
                  width: 400,
                  marginBottom: 12,
                  height: 42,
                  marginLeft: 12,
                  borderRadius: 4,
                }}
              >
                {dataAcount?.length &&
                  dataAcount.map((item) => {
                    return (
                      <Option value={item.value} key={item.value}>
                        {item.value === t('incident_test.account.select.total') ? (
                          <div style={{ color: '#007AFF' }}>{item.text}</div>
                        ) : (
                          item.text
                        )}
                      </Option>
                    );
                  })}
              </Select>
              <div style={{ marginTop: 8, marginLeft: 15 }}>
                <Switch
                  size="small"
                  checked={isShowAccountHidden}
                  onChange={(value) => {
                    this.setState({ isShowAccountHidden: value }, () => this.getData());
                  }}
                />{' '}
                {t('incident_test.show_hidden.title')}
              </div>
              <div style={{ marginLeft: 15 }}>
                <DatePicker
                  placeholder={t('bulk_incident.label.date_time.placeholder.start')}
                  onChange={(value) => {
                    this.start_date = value;
                    this.getData();
                  }}
                  style={{ width: 250, height: 40 }}
                  allowClear
                  disabledDate={this.disabledDate}
                  format="YYYY/MM/DD"
                />
              </div>
            </div>
            <Table
              scroll={{ x: 1334 }}
              locale={{ emptyText: <EmptyBase /> }}
              rowKey={'_id'}
              dataSource={dataActivationLogAcount}
              columns={this.renderColumns()}
              pagination={false}
              rowClassName={(record, index) => (index === 0 ? 'table-row-total-account' : '')}
            />
          </div>
        </Spin>
      </Fragment>
    );
  }
}

export default withTranslation()(IncidentTestByAccount);
