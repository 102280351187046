import Breadcrumb from 'components/Breadcrumb';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import TermOfUse from './TermOfUse';

interface IProps {
  pathname: string;
  t: any;
}

class OtherSetting extends Component<IProps> {
  renderDevice = () => {
    const { pathname } = this.props;
    if (pathname.includes('admin-system')) return 'ADMIN_SYS';
    if (pathname.includes('mobile-app')) return 'MOBILE';
    if (pathname.includes('web-app')) return 'WEB_APP';
    return 'SUPPORT';
  };

  renderType = () => {
    const { pathname } = this.props;
    if (pathname.includes('-privacy')) return 'PRIVACY';
    if (pathname.includes('support-menu')) return 'URL_SUPPORT';
    return 'TERM';
  };

  render() {
    const { pathname, t } = this.props;
    return (
      <div>
        <Breadcrumb pathname={pathname} />
        <TermOfUse type={this.renderType()} device_app={this.renderDevice()} t={t} />
      </div>
    );
  }
}

export default withTranslation()(OtherSetting);
