import { Modal, Form, Button } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FormAdd from '../FormAdd';

interface IProps {
  onRef?: any;
  t: any;
}

interface IState {
  visible: boolean;
  dataView: any;
}

class ModalDetail extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: {} };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = (record: any) => {
    this.setState({ visible: !!record, dataView: record || {} });
  };

  render() {
    const { visible, dataView } = this.state;
    const { onRef, t } = this.props;
    if (onRef) onRef(this);
    const { email, first_name, last_name } = dataView;
    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
      >
        {visible ? (
          <Form
            initialValues={{ email, first_name, last_name }}
            colon={false}
            layout="vertical"
            autoComplete="off"
            scrollToFirstError
          >
            <div style={{ padding: 16 }}>
              <FormAdd editable={false} />
            </div>
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 16px',
              }}
            >
              <Button
                onClick={() => this.handleVisible(undefined)}
                style={{ height: 40, marginLeft: 12 }}
              >
                {t('common.button.close')}
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    );
  }
}

export default withTranslation()(ModalDetail);
