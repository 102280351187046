import { Modal, Form, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FormAdd from '../FormAdd';

interface IProps {
  onRef?: any;
  t: any;
  form: any;
  type: any;
  device_app: any;
  reloadData?: () => any;
}

interface IState {
  visible: boolean;
  dataView: any;
}

class ModalEdit extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: {} };
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = (record: any) => {
    const { form } = this.props;
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
    this.setState({ visible: !!record, dataView: record || {} });
  };

  render() {
    const { visible } = this.state;
    const { form, t, type, device_app } = this.props;
    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={791}
      >
        <Form form={form} colon={false} layout="vertical" autoComplete="off">
          <div style={{ padding: 16 }}>
            <FormAdd type={type} device_app={device_app} form={form} disabled />
          </div>
          <Form.Item
            style={{
              textAlign: 'right',
              margin: 0,
              boxShadow: '0px -1px 0px #EFEFF4',
              padding: '10px 16px',
            }}
          >
            <Button
              onClick={() => this.handleVisible(undefined)}
              style={{ height: 40, marginLeft: 12 }}
            >
              {t('account.management.modal.edit.button.close')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ModalEdit));
