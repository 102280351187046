import { DeleteOutlined } from '@ant-design/icons';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import { Button } from 'antd';
import modal from 'antd/lib/modal';
import BreadcrumbBase from 'components/Breadcrumb';
import TableBase from 'components/TableBase';
import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IUser, PERMISSION_ROOT } from 'sagas/models/user';
import auth from 'services/auth';
import cms from 'services/cms';
import { showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';
import FormAddUser from './FormAdd';
import ModalDetail from './ModalDetail';
import './styles.scss';
import ModalHistory from './ModalHistory';

interface IProps {
  t: any;
  user: IUser;
  history: any;
  pathname: string;
}

interface IData {
  first_name: string;
  last_name: string;
  phone?: string;
  language: string;
  email: string;
  permission?: string;
  deleted?: boolean;
}

interface IState {
  data: IData[];
  loading: boolean;
  totalDocs: number;
}

class UserManagement extends Component<IProps, IState> {
  refFormAdd: any;
  page_number: number;
  namesearch: any;
  modalWarning: any;
  modalDetail: any;
  modalHistory: any;

  constructor(props: IProps) {
    super(props);
    this.state = { data: [], loading: true, totalDocs: 0 };
    this.page_number = 1;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });
    cms
      .usersCms({ page_size: 10, page: this.page_number, name: this.namesearch })
      .then(({ data }) => {
        const { docs, totalDocs } = data.data;
        this.setState({ data: docs, loading: false, totalDocs });
      })
      .catch((e) => {
        throwException(e);
        this.setState({ loading: false });
      });
  };

  renderColumns = (): any[] => {
    const { t, user } = this.props;
    const { permission } = user;
    const columns = [
      {
        title: t('user.management.table.column.user_name'),
        dataIndex: 'name',
        key: 'name',
        width: window.outerWidth / 2,
        render: (_v: any, record: IData) => {
          const { first_name, last_name } = record;
          return (
            <div
              style={{
                maxWidth: window.outerWidth / 2,
                color: record.deleted ? 'darkgray' : 'rgb(0, 122, 255)',
                cursor: 'pointer',
              }}
              className="text-split"
              onClick={() => this.handleDetail(record)}
            >
              {`${last_name} ${first_name}`.trim()}
            </div>
          );
        },
      },
      {
        title: t('login.form.input.email.title'),
        dataIndex: 'email',
        key: 'email',
        render: (_, record) => {
          return <div style={{ color: record.deleted ? 'darkgray' : '' }}>{_}</div>;
        },
      },
      {
        title: '',
        width: 40,
        dataIndex: 'action',
        key: 'action',
        render: (_, record: IData) => {
          if (record.email === user.email || record.permission === 'root' || record.deleted)
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <HistoryOutlinedIcon
                  onClick={() => this.modalHistory?.handleVisible?.(record)}
                  style={{ marginLeft: 28, cursor: 'pointer' }}
                  fontSize="small"
                />
              </div>
            );
          return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {permission === PERMISSION_ROOT ? (
                <DeleteOutlined
                  style={{ userSelect: 'none', marginLeft: 28 }}
                  onClick={() => this.handleRemove(record)}
                />
              ) : null}
              <HistoryOutlinedIcon
                onClick={() => this.modalHistory?.handleVisible?.(record, false)}
                style={{ marginLeft: 28, cursor: 'pointer' }}
                fontSize="small"
              />
            </div>
          );
        },
      },
    ];
    return columns;
  };

  handleDetail = (record: any) => {
    this.modalDetail?.handleVisible?.(record);
  };

  handleDelete = (record: any) => {
    const { t } = this.props;
    this.modalWarning?.destroy();
    auth
      .removeUser(record._id)
      .then(() => {
        this.getData();
        showMessageSuccess(t('user.management.delete_user.success'));
      })
      .catch(throwException);
  };

  handleCancelDelete = () => {
    this.modalWarning?.destroy();
  };

  handleRemove = (record: IData) => {
    const { t } = this.props;
    const config = {
      title: t('user.management.modal.warning_remove.title'),
      content: (
        <Fragment>
          <div style={{ padding: '16px 24px', marginBottom: 116 }}>
            <span>{t('user.management.modal.warning_remove.content')}</span>
          </div>
          <div className="footer-bottom-modal">
            <Button
              type="primary"
              danger
              style={{ marginRight: 12, height: 40 }}
              onClick={() => this.handleDelete(record)}
            >
              {t('user.management.modal.warning_remove.button.delete')}
            </Button>
            <Button style={{ height: 40 }} onClick={this.handleCancelDelete}>
              {t('user.management.modal.warning_remove.button.cancel')}
            </Button>
          </div>
        </Fragment>
      ),
      className: 'custom-class-modal-confirm',
      closable: true,
      centered: true,
      width: 807,
    };
    this.modalWarning = modal.confirm(config);
  };

  handleOkAdd = (values, callback) => {
    const { t } = this.props;
    this.refFormAdd?.handleOk(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      if (success) {
        this.page_number = 1;
        this.namesearch = '';
        this.getData();
        showMessageSuccess(t('user.management.create_user.success'));
      }
    });
  };

  handleSearch = (e) => {
    const { value } = e.target;
    this.namesearch = value;
    this.page_number = 1;
    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  render() {
    const { data, loading, totalDocs } = this.state;
    const { user, pathname } = this.props;
    return (
      <div>
        <ModalDetail
          onRef={(ref) => {
            this.modalDetail = ref;
          }}
        />
        <ModalHistory
          onRef={(ref) => {
            this.modalHistory = ref;
          }}
        />
        <BreadcrumbBase pathname={pathname} />
        <TableBase
          pagination={{
            pageSize: 10,
            onChange: this.handleChangePage,
            total: totalDocs,
            current: this.page_number,
          }}
          handleSearch={this.handleSearch}
          onOkFormAdd={this.handleOkAdd}
          widthModalAdd={807}
          formAdd={(props) => (
            <FormAddUser
              onRef={(ref) => {
                this.refFormAdd = ref;
              }}
              {...props}
            />
          )}
          loading={loading}
          rowKey="_id"
          dataSource={data}
          columns={this.renderColumns()}
          allowAdd={user.permission === PERMISSION_ROOT}
          lableKeyI18n="user.management.button.add"
        />
      </div>
    );
  }
}

export default connect(({ user }: { user: { user: IUser } }) => ({ user: user.user }))(
  withTranslation()(UserManagement)
);
