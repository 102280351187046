import { CheckCircleFilled, LeftOutlined } from '@ant-design/icons';
import { layout } from 'components/Layout';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FormForgot from './ForgotPassword';
import ForgotSuccess from './ForgotSucess';
import FormLogin from './FormLogin';
import './styles.scss';

interface IProps {
  history: any;
  t: any;
}

interface IState {
  forgotPassword: boolean;
  forgotSuccess: boolean;
}

class Login extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { forgotPassword: false, forgotSuccess: false };
  }

  componentDidMount() {
    layout.removeLayout();
  }

  componentWillUnmount() {
    layout.addLayout();
  }

  forgotPassword = (flag) => {
    this.setState({ forgotPassword: flag });
  };

  backLogin = () => {
    this.setState({ forgotPassword: false, forgotSuccess: false });
  };

  forgotSuccess = (flag) => {
    this.setState({ forgotSuccess: flag });
  };

  render() {
    const { history, t } = this.props;
    const { forgotPassword, forgotSuccess } = this.state;
    return (
      <div className="login" style={{ backgroundImage: 'url(/images/background.png)' }}>
        <div className="formLogin">
          {forgotPassword && !forgotSuccess ? (
            <div className="icon-back" onClick={() => this.forgotPassword(false)}>
              <LeftOutlined />
              <span style={{ marginLeft: 3, cursor: 'pointer', userSelect: 'none' }}>
                {t('login.form.button.login')}
              </span>
            </div>
          ) : null}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {forgotSuccess ? (
              <CheckCircleFilled
                className="icon-forgot-success"
                height={83}
                style={{ color: '#34C759', marginTop: 20 }}
              />
            ) : !forgotPassword ? (
              <img style={{ paddingTop: 48 }} src="/images/logoLogin.png" alt="" />
            ) : (
              <img height={180} src="/images/headerForgot.png" alt="" />
            )}
          </div>
          {!forgotSuccess ? (
            !forgotPassword ? (
              <FormLogin t={t} forgotPassword={this.forgotPassword} history={history} />
            ) : (
              <FormForgot forgotSuccess={this.forgotSuccess} t={t} history={history} />
            )
          ) : (
            <ForgotSuccess backLogin={this.backLogin} t={t} />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Login);
