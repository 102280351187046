import { Modal, Form, Button, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import content from 'services/content';
import upload from 'services/upload';
import { showMessageSuccess } from 'utils';
import throwException from 'utils/throwException';
import FormAdd from '../FormAdd';

interface IProps {
  onRef?: any;
  t: any;
  form: any;
  reloadData?: () => any;
  record?: any;
  status?: string;
}

interface IState {
  visible: boolean;
  dataView: any;
  loading: boolean;
}

class ModalEdit extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { visible: false, dataView: {}, loading: false };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { visible } = this.state;
    return visible !== nState.visible;
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = async (record: any) => {
    const { form } = this.props;
    if (record) {
      record.schedue = [moment(record.start_schedule), moment(record.end_schedule)];
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
    this.setState({ visible: !!record, dataView: record || {} });
  };

  handleGetUrl = async (files: any[] = []) => {
    if (!Array.isArray(files) || !files.length) return {};
    try {
      const { data } = await upload.preview({ files });
      return data;
    } catch (e) {
      throwException(e);
      return {};
    }
  };

  handleEdit = (values) => {
    const { t } = this.props;
    const { loading } = this.state;
    values.start_schedule = values.schedue?.[0];
    values.end_schedule = values.schedue?.[1];
    delete values.schedue;
    if (loading) return;
    const { form, reloadData } = this.props;
    const file_url = form.getFieldValue('file_url');

    let dataUpdate = {
      ...values,
      file_url,
    };
    delete dataUpdate.id;
    content
      .edit(dataUpdate, values.id)
      .then(() => {
        this.handleVisible(undefined);
        if (reloadData) reloadData();
        showMessageSuccess(t('content.management.maintenance.edit_success'));
      })
      .catch((e) => {
        throwException(e);
        this.handleVisible(undefined);
      });
  };

  render() {
    const { visible, loading } = this.state;
    const { onRef, t, form, status } = this.props;
    if (onRef) onRef(this);
    return (
      <Modal
        maskClosable={false}
        onCancel={() => this.handleVisible(undefined)}
        destroyOnClose
        centered
        footer={null}
        title={null}
        visible={visible}
        className="modal-table-add"
        width={791}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            autoComplete="off"
            onFinish={this.handleEdit}
            scrollToFirstError
          >
            <div style={{ padding: 16 }}>
              <FormAdd form={form} isCreate={false} status={status} />
            </div>
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 16px',
              }}
            >
              {(status === 'READY_TO_NOTIFYRM' || status === '') && (
                <Button type="primary" style={{ height: 40 }} htmlType="submit">
                  {t('account.management.modal.edit.button.save')}
                </Button>
              )}

              <Button
                onClick={() => this.handleVisible(undefined)}
                style={{ height: 40, marginLeft: 12 }}
              >
                {t('account.management.modal.edit.button.cancel')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ModalEdit));
