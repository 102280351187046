import axiosConfig from 'config/axios';

const historyLog = {
  async list(dataReq) {
    try {
      const data = await axiosConfig.get('/admin/email-sms-log/list', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async exportExcel(dataReq) {
    try {
      const data = await axiosConfig.get('/admin/email-sms-log/excel', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default historyLog;
