import { Component, Fragment } from 'react';
import { ReactComponent as SvgNoNetwork } from './svg/network_disconnected.svg';
import { ReactComponent as SvgNetwork } from './svg/network_connected.svg';
import { connect } from 'react-redux';
import './styles.scss';

interface IProps {
  t: any;
  network: boolean;
  dispatch: any;
}

interface IState {
  bottomConnect: number;
  bottomDisconnected: number;
}

class Network extends Component<IProps, IState> {
  timeoutDisconnected?: NodeJS.Timeout;
  timeoutConected?: NodeJS.Timeout;
  constructor(props: IProps) {
    super(props);
    window.addEventListener('offline', this.handleOffLine);
    window.addEventListener('online', this.handleOnline);
    const network = window.navigator.onLine;
    this.state = { bottomConnect: -100, bottomDisconnected: !network ? 50 : -100 };
  }

  shouldComponentUpdate(_nProps: IProps, nState: IState) {
    const { bottomDisconnected, bottomConnect } = this.state;
    return (
      bottomDisconnected !== nState.bottomDisconnected || bottomConnect !== nState.bottomConnect
    );
  }

  UNSAFE_componentWillReceiveProps(nProps: IProps) {
    const { network } = this.props;
    if (network !== nProps.network) {
      if (nProps.network) {
        if (this.timeoutDisconnected) {
          clearTimeout(this.timeoutDisconnected);
          this.timeoutDisconnected = undefined;
        }
        this.setState({ bottomConnect: 50, bottomDisconnected: -100 }, () => {
          this.timeoutConected = setTimeout(() => {
            this.setState({ bottomConnect: -100 });
          }, 5000);
        });
      } else {
        if (this.timeoutConected) {
          clearTimeout(this.timeoutConected);
          this.timeoutConected = undefined;
        }
        this.setState({ bottomDisconnected: 50, bottomConnect: -100 }, () => {
          this.timeoutDisconnected = setTimeout(() => {
            this.setState({ bottomDisconnected: -100 });
          }, 5000);
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleOffLine);
    window.removeEventListener('online', this.handleOnline);
  }

  handleOffLine = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'network/change', payload: { connected: false } });
  };

  handleOnline = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'network/change', payload: { connected: true } });
  };

  render() {
    const { t } = this.props;
    const { bottomDisconnected, bottomConnect } = this.state;
    return (
      <Fragment>
        <div className="network" style={{ bottom: bottomDisconnected }}>
          <SvgNoNetwork fill="#fff" style={{ width: 20, height: 20 }} />
          <span style={{ color: '#fff', marginLeft: 10 }}>{t('network.disconnected')}</span>
        </div>
        <div className="network" style={{ bottom: bottomConnect }}>
          <SvgNetwork fill="#fff" style={{ width: 20, height: 20 }} />
          <span style={{ color: '#fff', marginLeft: 10 }}>{t('network.reconnected')}</span>
        </div>
      </Fragment>
    );
  }
}

export default connect(({ network }: any) => ({ network: network.connected }))(Network);
