import { Button, Form, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import InputBase from 'components/Input';
import { II18n } from 'index';
import { debounce } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import auth from 'services/auth';
import { validateEmail } from 'utils';
import throwException from 'utils/throwException';

interface IProps extends II18n {
  history: any;
  form: any;
  dispatch: any;
  forgotSuccess: any;
}

interface IState {
  loading: boolean;
}

class FormForgotBase extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleForgotPassword = debounce(this.handleForgotPassword, 300);
    this.state = { loading: false };
  }

  handleValidateEmail = (_, v) => {
    const { t } = this.props;
    if (v && !validateEmail(v)) {
      return Promise.reject(new Error(t('login.form.input.email.format.invalid')));
    } else {
      return Promise.resolve();
    }
  };

  handleForgotPassword = (values) => {
    this.setState({ loading: true });
    auth
      .resetPassword(values.email)
      .then(() => {
        const { forgotSuccess } = this.props;
        if (forgotSuccess) forgotSuccess(true);
      })
      .catch((e) => {
        throwException(e, {
          callbackCheck: (err_c) => err_c === 'AD10001',
          callbackError: () => {
            const { form, t } = this.props;
            form.setFields([{ name: 'email', errors: [t('login.email.not_existed')] }]);
          },
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { t, form } = this.props;
    const { loading } = this.state;
    return (
      <div style={{ paddingTop: 42, position: 'relative' }}>
        <Spin spinning={loading}>
          <div style={{ fontWeight: 600, fontSize: 18, textAlign: 'center' }}>
            {t('login.forgot_password')}
          </div>
          <div style={{ paddingTop: 30 }}>{t('login.form.forgot.password.description')}</div>
          <Form
            form={form}
            onFinish={this.handleForgotPassword}
            style={{ paddingTop: 30 }}
            colon={false}
            layout="vertical"
            autoComplete="off"
            className="form-login-success"
            scrollToFirstError
          >
            <Form.Item
              label={t('login.form.input.email.title')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('login.form.input.email.required'),
                },
                { validator: this.handleValidateEmail },
              ]}
            >
              <InputBase maxLength={256} placeholder={t('login.form.input.email.placeholder')} />
            </Form.Item>
            <Form.Item name="button" style={{ paddingTop: 10 }}>
              <Button htmlType="submit" style={{ height: 50 }} type="primary" block>
                {t('login.form.button.forgot')}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

const FormForgot = connect()(withUseFormHook(FormForgotBase));

export default FormForgot;
