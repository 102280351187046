import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import throwException from 'utils/throwException';
import moment from 'moment';
import account from 'services/account';
import exportsService from 'services/export';
import { Button, DatePicker, Form, Select, Spin, Table } from 'antd';
import './styles.scss';
import { get, includes } from 'lodash';
import { useForm } from 'antd/lib/form/Form';
import { ReloadOutlined } from '@ant-design/icons';
import upload from 'services/upload';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  t: any;
  onRef?: any;
  form: any;
}

interface IState {
  data: any[];
  loading: boolean;
  dataAcount: any[];
  dataListExport: any[];
  totalDocs: number;
  tableLoading: boolean;
}

class BulkIncidentLog extends Component<IProps, IState> {
  category_notify: any;
  start_date: any;
  end_date: any;
  pageSize: any;
  current_page: number;
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      dataAcount: [],
      dataListExport: [],
      totalDocs: 0,
      tableLoading: false,
    };
    this.pageSize = 10;
    this.current_page = 1;
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
    this.getDataAccount();
    this.getListExportIncident();
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };
  reloadDataExport = () => {
    this.current_page = 1;
    this.getListExportIncident();
  };
  getListExportIncident = () => {
    this.setState({ tableLoading: true });
    exportsService
      .listExportBulkIncident({ page: this.current_page, page_size: this.pageSize })
      .then(({ data }) => {
        this.setState({ dataListExport: data.data.docs, totalDocs: data.data.totalDocs });
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ tableLoading: false });
      });
  };

  handleChangePage = (page) => {
    this.current_page = page;
    this.getListExportIncident();
  };

  getDataAccount = (account_type?) => {
    const { loading } = this.state;
    const { t } = this.props;
    if (!loading) this.setState({ loading: true });
    account
      .listAccountRoot({ account_type })
      .then(({ data }) => {
        let dataAcountList = [] as any;
        dataAcountList.push({
          value: t('account_activation.account.select.total'),
          text: t('account_activation.account.select.total'),
        });
        data.data.map((item) => {
          dataAcountList.push({ value: item._id, text: item.name });
          return item;
        });
        this.setState({ dataAcount: dataAcountList });
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  renderValueBussinessType = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'EDUCATIONAL':
        return t('title.type.account.education');
      case 'ENTERPRISE':
        return t('title.type.account.enterprise');
      case 'MEDICAL':
        return t('title.type.account.medical');
      default:
        return '';
    }
  };

  renderStatus = () => {
    const { t } = this.props;
    return {
      processing: t('bulk_incident.status.processing'),
      success: t('bulk_incident.status.success'),
      failed: t('bulk_incident.status.failed'),
      deleted: t('bulk_incident.status.deleted'),
      new: t('bulk_incident.status.new'),
    };
  };

  downLoadFile = (fileKey) => {
    this.setState({ loading: true });
    upload
      .preview({ files: [fileKey], is_download: true })
      .then(({ data }) => {
        window.open(data?.data[0]?.url);
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const columns = [
      {
        title: t('data_report.import_incidents.list.id'),
        dataIndex: '_id',
        width: 200,
        render: (v, record) => (
          <div>
            <div>{`${v}`}</div>
          </div>
        ),
      },
      {
        title: t('bulk_incident.label.date_time'),
        dataIndex: 'metadata',
        width: 200,
        render: (v) => (
          <div>
            {moment(v?.start_date).format('YYYY/MM/DD')} -{' '}
            {moment(v?.end_date).format('YYYY/MM/DD')}
          </div>
        ),
      },
      {
        title: t('bulk_incident.label.account_type'),
        dataIndex: 'metadata',
        width: 200,
        render: (v) => <div>{this.renderValueBussinessType(v?.account_type)}</div>,
      },
      {
        title: t('account.management.table.column.account_id'),
        dataIndex: 'metadata',
        width: 200,
        render: (v) => (
          <div>
            {v?.org_id ? v?.org_id.join(', ') : t('account_activation.account.select.total')}
          </div>
        ),
      },
      {
        title: t('bulk_incident.status.title'),
        dataIndex: 'status',
        width: 200,
        render: (v) => <div>{this.renderStatus()[v]}</div>,
      },
      {
        title: t('bulk_incident.original_file_name'),
        dataIndex: 'original_file_name',
        width: 200,
        render: (v, record) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" onClick={() => this.downLoadFile(record?.file_key)}>
            {v}
          </a>
        ),
      },
      {
        title: t('bulk_incident.start_date'),
        dataIndex: 'start_date',
        width: 200,
        render: (v) => <div>{v ? moment(v).format('YYYY/MM/DD HH:mm') : ''}</div>,
      },
      {
        title: t('bulk_incident.end_date'),
        dataIndex: 'end_date',
        width: 200,
        render: (v) => <div>{v ? moment(v).format('YYYY/MM/DD HH:mm') : ''}</div>,
      },
      {
        title: t('content.management.article.column.created_at'),
        dataIndex: 'createdAt',
        width: 200,
        render: (v) => <div>{moment(v).format('YYYY/MM/DD HH:mm')}</div>,
      },
    ];
    return columns;
  };

  extraRanges = (): any => {
    const { t } = this.props;
    return {
      [t('reports.bulkIncidents.today')]: [moment(), moment()],
      [t('reports.bulkIncidents.yesterday')]: [
        moment().subtract(1, 'days'),
        moment().subtract(1, 'days'),
      ],
      [t('reports.bulkIncidents.last7days')]: [moment().subtract(7, 'days'), moment()],
      [t('reports.bulkIncidents.last30days')]: [moment().subtract(30, 'days'), moment()],
      [t('reports.bulkIncidents.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
      [t('reports.bulkIncidents.lastMonth')]: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
      [t('reports.bulkIncidents.onceYear')]: [moment().startOf('year'), moment().endOf('year')],
    };
  };

  handleChangeAccountType = (value) => {
    this.getDataAccount(value);
  };

  renderDataType = () => {
    const { t } = this.props;
    return [
      { value: 'EDUCATIONAL', title: t('title.type.account.education') },
      { value: 'ENTERPRISE', title: t('title.type.account.enterprise') },
      { value: 'MEDICAL', title: t('title.type.account.medical') },
    ];
  };

  exportLogV2 = (value) => {
    const { loading } = this.state;
    const { t } = this.props;
    if (!loading) this.setState({ loading: true });
    const accountValue = get(value, 'account', []);
    let paramsExport = {
      start_date: moment(get(value, 'date_time[0]')).format('YYYY/MM/DD'),
      end_date: moment(get(value, 'date_time[1]')).format('YYYY/MM/DD'),
      account_type: value.account_type,
    };
    if (includes(accountValue, t('account_activation.account.select.total'))) {
    } else {
      paramsExport = { ...paramsExport, ...{ org_id: accountValue } };
    }

    exportsService
      .exportBulkIncident(paramsExport)
      .then((result) => {})
      .catch(this.handleError)
      .finally(() => {
        this.getListExportIncident();
        this.setState({ loading: false });
      });
  };

  exportLog = (value) => {
    const paramsFilter = new URLSearchParams();
    const { t } = this.props;
    const { loading } = this.state;
    if (!loading) this.setState({ loading: true });

    paramsFilter.append('standby_token', localStorage.getItem('access_token') || '');
    if (get(value, 'date_time[0]') && get(value, 'date_time[1]')) {
      paramsFilter.append('start_date', moment(get(value, 'date_time[0]')).format('YYYY/MM/DD'));
      paramsFilter.append('end_date', moment(get(value, 'date_time[1]')).format('YYYY/MM/DD'));
    }
    const accountValue = get(value, 'account', []);
    if (includes(accountValue, t('account_activation.account.select.total'))) {
    } else {
      if (accountValue.length) {
        paramsFilter.append('org_id', '');
        // eslint-disable-next-line array-callback-return
        get(value, 'account', []).map((item) => {
          paramsFilter.append('org_id', item);
        });
      }
    }

    paramsFilter.append('account_type', get(value, 'account_type'));
    const queryString = paramsFilter.toString();

    window.open(
      process.env.REACT_APP_API_URL + '/admin-cms/export/log-bulk-incident?' + queryString
    );
    this.setState({ loading: false });
  };

  render() {
    const { dataAcount, loading, dataListExport, totalDocs } = this.state;
    const { t, form } = this.props;
    return (
      <Fragment>
        <Spin spinning={loading}>
          <div style={{ marginTop: 20, marginLeft: 20, width: 500 }} className="bulkIncident">
            <Form
              form={form}
              colon={false}
              layout="vertical"
              autoComplete="off"
              onFinish={(data) => {
                this.exportLogV2(data);
              }}
            >
              <Form.Item
                name="date_time"
                label={t('bulk_incident.label.date_time')}
                rules={[
                  {
                    required: true,
                    message: t('bulk_incident.messge.required.date'),
                  },
                ]}
              >
                <RangePicker
                  className="range-picker"
                  ranges={this.extraRanges()}
                  dropdownClassName="dropdown-range-picker range-bulk dropdown-range-picker-custom"
                  style={{ height: 40, width: 395 }}
                  format="YYYY/MM/DD"
                  placeholder={[
                    t('bulk_incident.label.date_time.placeholder.start'),
                    t('bulk_incident.label.date_time.placeholder.end'),
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="account_type"
                label={t('bulk_incident.label.account_type')}
                rules={[
                  {
                    required: true,
                    message: t('bulk_incident.messge.required.account_type'),
                  },
                ]}
              >
                <Select
                  showArrow
                  optionFilterProp="children"
                  placeholder={t('bulk_incident.label.account_type.placeholder')}
                  // mode="multiple"
                  // maxTagTextLength={10}
                  // maxTagCount={2}
                  onSelect={(account) => {
                    this.getDataAccount(account);
                    form.setFieldsValue({
                      account: [t('account_activation.account.select.total')],
                    });
                  }}
                  style={{
                    width: 400,
                    height: 40,
                    borderRadius: 4,
                  }}
                >
                  {this.renderDataType().map((el: any, index: number) => (
                    <Select.Option value={el.value} key={index.toString()}>
                      {el.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="account" label={t('bulk_incident.label.account')}>
                <Select
                  allowClear
                  showArrow
                  optionFilterProp="children"
                  placeholder={t('bulk_incident.label.account.placeholder')}
                  mode="multiple"
                  maxTagCount={2}
                  maxTagTextLength={10}
                  defaultValue={[t('account_activation.account.select.total')]}
                  style={{
                    width: 400,
                    height: 40,
                    borderRadius: 4,
                  }}
                >
                  {dataAcount?.length &&
                    dataAcount.map((item) => {
                      return (
                        <Option value={item.value} key={item.value}>
                          {item.value === t('account_activation.account.select.total') ? (
                            <div style={{ color: '#007AFF' }}>{item.text}</div>
                          ) : (
                            item.text
                          )}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Button type="primary" htmlType="submit">
                {t('bulk_incident.button_export.title')}
              </Button>
            </Form>
          </div>
          <div style={{ marginTop: 20 }}>
            <Button
              type="primary"
              icon={<ReloadOutlined style={{ color: 'primary' }} />}
              style={{ marginLeft: 20, marginBottom: 10 }}
              onClick={() => this.reloadDataExport()}
            ></Button>
            <Table
              loading={this.state.tableLoading}
              columns={this.renderColumns()}
              dataSource={dataListExport}
              pagination={{
                pageSize: this.pageSize,
                onChange: this.handleChangePage,
                total: totalDocs,
                current: this.current_page,
              }}
            ></Table>
          </div>
        </Spin>
      </Fragment>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withTranslation()(withUseFormHook(BulkIncidentLog));
