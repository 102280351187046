import { getHeaders } from 'utils';

const axios = require('axios');

const intaince = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: process.env.TIME_OUT || 500000,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
});

intaince.interceptors.request.use(
  function (config: any) {
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

intaince.interceptors.response.use(
  function (response: any) {
    return response;
  },
  async function (e: any) {
    return Promise.reject(e);
  }
);

const axiosConfig = {
  get: (url, request?: any) => {
    const headers = getHeaders();
    return intaince.get(url, { ...request, headers });
  },
  post: (url, request?: any) => {
    const headers = getHeaders();
    return intaince.post(url, request, { headers });
  },
  put: (url, request?: any) => {
    const headers = getHeaders();
    return intaince.put(url, request, { headers });
  },
  delete: (url, request?: any) => {
    const headers = getHeaders();
    return intaince.delete(url, { ...request, headers });
  },
};

export { intaince };

export default axiosConfig;
