import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import InputBase from 'components/Input';
import { II18n } from 'index';
import { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import auth from 'services/auth';
import { logout, showMessageError, showMessageSuccess } from 'utils';
import { layout } from 'components/Layout';

interface IProps extends II18n {
  history: any;
  form: any;
  dispatch: any;
  forgotPassword: any;
  network: boolean;
}

interface IState {
  loading: boolean;
  invalidToken: boolean;
  type: 'forgot' | 'new';
}

class FormNewPassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { history } = props;
    const query = new URLSearchParams(history.location.search);
    const token = query.get('token');
    const type = query.get('type');
    this.state = {
      loading: false,
      invalidToken: !token,
      type: type !== 'SET_NEW' ? 'forgot' : 'new',
    };
  }

  componentDidMount() {
    layout.removeLayout();
    this.checkToken();
  }

  checkToken = () => {
    const { history, network, t } = this.props;
    if (!network) {
      return showMessageError(t('network.disconnected'));
    }
    const query = new URLSearchParams(history.location.search);
    const token = query.get('token');
    if (token) {
      this.setState({ loading: true });
      auth
        .checkToken(token)
        .then((res) => {
          this.setState({ loading: false });
        })
        .catch((e) => {
          if (e.message === 'Network Error') {
            this.setState({ loading: false });
            return showMessageError(t('network.disconnected'));
          }
          this.setState({ loading: false, invalidToken: true });
        });
    } else {
      this.setState({ invalidToken: true });
    }
  };

  handleValidatePassword = (_, value = '') => {
    const { t, form } = this.props;
    const password = form.getFieldValue('password');
    if (password !== value && value.trim()) {
      return Promise.reject(new Error(t('login.form.input.confirm_password.not_match')));
    } else {
      return Promise.resolve();
    }
  };

  handleUpdatePassword = (value) => {
    const { history, t, network } = this.props;
    if (!network) {
      return showMessageError(t('network.disconnected'));
    }
    const query = new URLSearchParams(history.location.search);
    const token = query.get('token');

    const typeQs: string = query.get('type') || '';
    const isTypeSetNew: boolean = typeQs === 'SET_NEW';

    this.setState({ loading: true });
    const dataRequest = {
      token,
      new_password: value.password,
      confirm_password: value.confirm_password,
      is_create: isTypeSetNew,
    };
    auth
      .updatePassword(dataRequest)
      .then((res) => {
        showMessageSuccess(t('auth.create_new_password.success'));
        this.setState({ loading: false });
        logout();
        history.push('/login');
      })
      .catch((e) => {
        if (e.message === 'Network Error') {
          this.setState({ loading: false });
          return showMessageError(t('network.disconnected'));
        }
        this.setState({ invalidToken: true });
      });
  };

  render() {
    const { t, form, history } = this.props;
    const { loading, invalidToken, type } = this.state;
    return (
      <div className="spin-login">
        <Spin spinning={loading}>
          <div className="login" style={{ backgroundImage: 'url(/images/background.png)' }}>
            <div className="formLogin">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {!invalidToken && <img height={180} src="/images/headerForgot.png" alt="" />}
              </div>
              <div>
                {invalidToken ? (
                  <div className="lock-block">
                    <div>
                      <div>
                        <div className="block-ip-icon">
                          <img src="/images/ic-link-expired.png" alt="Link Expired" />
                        </div>
                        <div>
                          <Typography.Text className="txt-title">
                            {t('content.management.maintaince.modal.warning.title')}
                          </Typography.Text>
                        </div>
                        <div className="description">
                          <Typography.Text className="txt-description">
                            {t('content.management.maintaince.modal.warning.public.announcement')}
                          </Typography.Text>
                        </div>
                        <div className="description">
                          <Typography.Text className="txt-description">
                            {t('content.management.maintaince.modal.warning.public.description')}
                          </Typography.Text>
                        </div>
                        <Button
                          type="primary"
                          block
                          size="large"
                          className="btn-ok"
                          onClick={() => {
                            logout();
                            history.push('/login');
                          }}
                        >
                          {t('content.management.maintenance.modal.warning.public.okBtn')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Fragment>
                    <div
                      style={{ fontSize: 18, fontWeight: 700, textAlign: 'center', marginTop: 10 }}
                    >
                      {type === 'new' ? t('fgpwPage.setPassword') : t('auth.create_new_password')}
                    </div>
                    <Form
                      form={form}
                      onFinish={this.handleUpdatePassword}
                      style={{ paddingTop: 42 }}
                      colon={false}
                      layout="vertical"
                      autoComplete="off"
                      className="form-login-success"
                      scrollToFirstError
                    >
                      <Form.Item
                        label={
                          type === 'new'
                            ? t('fgpwPage.setPassword')
                            : t('auth.create_new_password.new_password')
                        }
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t('login.form.input.password.required'),
                          },
                          {
                            pattern: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/),
                            message: t('auth.password.wrong_format'),
                          },
                        ]}
                      >
                        <InputBase.Password
                          placeholder={
                            type === 'new'
                              ? t('fgpwPage.setPassword')
                              : t('auth.create_new_password.new_password')
                          }
                          maxLength={16}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          type === 'new'
                            ? t('fgpwPage.reEnterSetPassword')
                            : t('fgpwPage.reEnterNewPassword')
                        }
                        name="confirm_password"
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: t('fgpwPage.reEnterNewPasswordRequired'),
                          },
                          { validator: this.handleValidatePassword },
                        ]}
                      >
                        <InputBase.Password
                          suffix={<CheckOutlined style={{ color: '#34C759' }} />}
                          placeholder={
                            type === 'new'
                              ? t('fgpwPage.reEnterSetPassword')
                              : t('fgpwPage.reEnterNewPassword')
                          }
                          maxLength={16}
                        />
                      </Form.Item>

                      <Form.Item name="button" style={{ paddingTop: 10 }}>
                        <Button htmlType="submit" style={{ height: 50 }} type="primary" block>
                          {type === 'new'
                            ? t('fgpwPage.setPassword')
                            : t('auth.create_new_password.button.update_password')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default connect(({ network }: any) => ({ network: network.connected }))(
  withUseFormHook(withTranslation()(FormNewPassword))
);
