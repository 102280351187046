import { Upload } from 'antd';
import { Component, Fragment } from 'react';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import upload from 'services/upload';
import throwException from 'utils/throwException';
import './styles.scss';
import { showMessageError } from 'utils';

interface IProps {
  t: any;
  setFieldsValue: (v: any) => any;
  file_url: any[];
  setLoading: (flag: boolean) => any;
  required?: boolean;
  message?: string;
  setFields?: any;
}
interface IState {
  heightImge: any;
  widthImge: any;
  error?: any;
}

class UploadFile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { heightImge: 0, widthImge: 0, error: false };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  shouldComponentUpdate(nProps: IProps, nState: IState) {
    const { file_url } = this.props;
    const { heightImge, widthImge, error } = this.state;
    return (
      file_url !== nProps.file_url ||
      heightImge !== nState ||
      widthImge !== nState ||
      error !== nState.error
    );
  }

  validate = () => {
    const { file_url, required } = this.props;
    if (required && !file_url?.length) {
      this.setState({ error: true });
      return { error: true, value: file_url };
    }
    return { error: false, value: file_url };
  };

  getError = () => {
    const { error } = this.state;
    return error;
  };

  handleOnChange = async ({ file }: any) => {
    const { setFieldsValue, setLoading, t } = this.props;
    try {
      let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!allowedExtension.includes(file.type)) {
        return showMessageError(t('upload.file.type.invalid'));
      }
      if (file.size > 104857600) {
        return showMessageError(t('upload.file.size.to_lager'));
      }
      if (file.size <= 0) {
        return showMessageError(t('upload.file.size.to_empty'));
      }
      setLoading(true);
      const { data } = await upload.getUrlUpload({ files: [encodeURIComponent(file.name)] });
      const dataUrl = data?.data?.[0];
      if (dataUrl) {
        const headers = {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
        };
        await upload.upload(dataUrl.url, { headers }, file.originFileObj);
        const { data: dataUrlGet } = await this.handleGetUrl(data?.data);
        setFieldsValue({
          file_url: [
            {
              ...dataUrl,
              url_preview: dataUrlGet?.[0]?.url,
              size: file.size,
              fileKey: dataUrlGet?.[0]?.fileKey,
              original_name: decodeURIComponent(dataUrl?.original_name),
            },
          ],
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throwException(e);
    }
  };

  handleGetUrl = async (files = []) => {
    if (!Array.isArray(files) || !files.length) return {};
    const { data } = await upload.preview({ files: files.map((e: any) => e.fileKey) });
    return data;
  };

  handleRemoveImage = () => {
    const { required, setFields } = this.props;
    setFields([{ name: 'file_url', value: [] }]);
    if (required) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
    }
  };

  handleRenderSize = (size) => {
    const { t } = this.props;
    const kb = size / 1024;
    if (kb < 1024) {
      return t('content.management.article.size.KB', { number: kb.toFixed(2) });
    }
    const mb = kb / 1024;
    return t('content.management.article.size.MB', { number: mb.toFixed(2) });
  };

  onImgLoad({ target: img }) {
    this.setState({ heightImge: img?.offsetHeight, widthImge: img?.offsetWidth });
  }

  render() {
    const { t, file_url, message } = this.props;
    const { heightImge, widthImge, error } = this.state;

    return (
      <Fragment>
        <div style={{ fontWeight: 600, fontSize: 17, marginBottom: 16 }}>
          {t('content.management.modal.create_new.field.file_url')}:
          {t('content.management.modal.create_new.field.file_url.size')}
        </div>
        {!file_url?.length ? (
          <Fragment>
            <Upload
              showUploadList={false}
              customRequest={() => null}
              accept="image/*"
              name="avatar"
              listType="picture-card"
              className="avatar-uploader-article"
              onChange={this.handleOnChange}
            >
              <div style={{ paddingTop: 25 }}>
                <AddPhotoAlternateOutlinedIcon
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  fontSize="small"
                />
                <div style={{ padding: '0 20px', fontSize: 13 }}>
                  {t('content.management.modal.create_new.field.file_url.description')}
                </div>
              </div>
            </Upload>
            {error ? <span style={{ height: 22, color: '#ff4d4f' }}>{message}</span> : null}
          </Fragment>
        ) : (
          <div className="preview-image-article">
            <img
              onLoad={this.onImgLoad}
              alt=""
              src={file_url[0].url_preview}
              className="view-image-upload-article"
            />
            <div style={{ marginLeft: 22 }}>
              <span>
                {`${t('content.management.article.file_size')}: ${this.handleRenderSize(
                  file_url[0].size
                )}`}
              </span>
              <div>
                {t('content.management.article.file_width_height', {
                  size: `${heightImge}x${widthImge}`,
                })}
              </div>
            </div>
            <img
              alt=""
              onClick={this.handleRemoveImage}
              className="icon-remove-image-article"
              src="images/remove-image.png"
              width={24}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

export default UploadFile;
