import AccountManagement from 'Pages/AccountManagement';
import AccountUserManagement from 'Pages/AccountUserManagement';
import ContentManagement from 'Pages/ContentManagement';
import Login from 'Pages/Login';
import UserManagement from 'Pages/UserManagement';
import HistoryLog from 'Pages/HistoryLog';
import OtherSetting from 'Pages/OtherSetting';
import AccessLog from 'Pages/AccessLog';
import AdminSystem from 'Pages/OtherSetting/AdminSystem';
import NewPassword from 'Pages/Login/NewPassword';
import MyProfile from 'Pages/MyProfile';
import RegisterAccountLog from 'Pages/Report/RegisterAccount';
import BulkIncidentLog from 'Pages/BulkIncidentLog';
import Import from 'Pages/Import';
import SurveySetting from 'Pages/OtherSetting/SurveySetting';
import IncidentTest from 'Pages/Report/IncidentTest';

export interface IRoute {
  path?: string;
  component?: any;
  layout?: boolean;
  exact?: boolean;
  parents?: {
    link?: string;
    text?: string;
    isCustom?: boolean;
    key?: string;
  }[];
  breadcrumb?: string;
  menuLeft?: IMenu[] | IMenu;
  auth?: boolean;
}

export interface IMenu {
  title: string;
  routes: {
    path?: string;
    component?: any;
    layout?: boolean;
    exact?: boolean;
    menuText: string;
    breadcrumb?: string;
    parents?: {
      link?: string;
      text?: string;
      isCustom?: boolean;
      key?: string;
    }[];
  }[];
}

const routes: IRoute[] = [
  {
    path: '/login',
    component: Login,
    exact: true,
    auth: false,
  },
  {
    path: '/auth/new-password',
    component: NewPassword,
    auth: false,
  },
  {
    path: '/user-management',
    component: UserManagement,
    layout: true,
    breadcrumb: 'breadcrum.page.user_management',
  },
  {
    path: '/my-profile',
    component: MyProfile,
    layout: true,
    breadcrumb: 'breadcrum.page.user_management',
  },
  {
    path: '/account-management/:id',
    component: AccountUserManagement,
    layout: true,
    parents: [
      {
        link: '/account-management',
        text: 'breadcrum.page.account_management',
      },
      {
        isCustom: true,
        key: 'name',
      },
    ],
    breadcrumb: 'breadcrum.page.account_management',
  },
  {
    path: '/account-management',
    component: AccountManagement,
    layout: true,
    breadcrumb: 'breadcrum.page.account_management',
  },
  {
    path: '/content-management',
    component: ContentManagement,
    layout: true,
    breadcrumb: 'breadcrum.page.content_management',
  },
  {
    path: '/report',
    layout: true,
    menuLeft: {
      title: 'menu.left.title.report',
      routes: [
        {
          path: '/report/activation-log',
          component: RegisterAccountLog,
          menuText: 'menu.left.title.register_number',
          breadcrumb: 'breadcrum.activetionlog',
          parents: [
            {
              text: 'breadcrum.page.activetion',
            },
          ],
        },
        {
          path: '/report/incident-test',
          component: IncidentTest,
          menuText: 'menu.left.title.incident_test',
          breadcrumb: 'breadcrum.activetionlog',
          parents: [
            {
              text: 'breadcrum.page.activetion',
            },
          ],
        },
        {
          path: '/report/bulk-incident-log',
          component: BulkIncidentLog,
          menuText: 'menu.left.title.bulk_incident_report',
          breadcrumb: 'breadcrum.activetionlog',
          parents: [
            {
              text: 'breadcrum.page.activetion',
            },
          ],
        },
        {
          path: '/report/import',
          component: Import,
          menuText: 'menu.left.title.import',
          breadcrumb: 'breadcrum.page.import',
          parents: [
            {
              text: 'breadcrum.page.activetion',
            },
          ],
        },
      ],
    },
  },
  {
    path: '/history-log',
    layout: true,
    menuLeft: {
      title: 'menuleft.history_log.title',
      routes: [
        {
          path: '/history-log/notification-log',
          component: HistoryLog,
          menuText: 'menuleft.history_log.notification_log',
          breadcrumb: 'breadcrum.page.notification_log',
          parents: [
            {
              text: 'breadcrum.page.history_log',
            },
          ],
        },
        {
          path: '/history-log/access-log',
          component: AccessLog,
          menuText: 'menuleft.history_log.access_log',
          breadcrumb: 'breadcrum.page.app_user_access_log',
          parents: [
            {
              text: 'breadcrum.page.history_log',
            },
          ],
        },
      ],
    },
  },
  {
    path: '/other-setting',
    layout: true,
    menuLeft: [
      {
        title: 'menuleft.other_setting.term_of_privacy',
        routes: [
          {
            path: '/other-setting/term-of-use/admin-system',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.term_of_privacy.for_admin',
            breadcrumb: 'breadcrum.page.other_setting',
          },
          {
            path: '/other-setting/term-of-use/mobile-app',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.term_of_privacy.for_mobile',
            breadcrumb: 'breadcrum.page.other_setting',
          },
          {
            path: '/other-setting/term-of-use/web-app',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.term_of_privacy.for_web',
            breadcrumb: 'breadcrum.page.other_setting',
          },
          {
            path: '/other-setting/admin-system-privacy',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.admin_system_privacy',
            breadcrumb: 'breadcrum.page.other_setting',
          },
          {
            path: '/other-setting/mobile-app-privacy',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.mobile_app_privacy',
            breadcrumb: 'breadcrum.page.other_setting',
          },
          {
            path: '/other-setting/web-app-privacy',
            component: OtherSetting,
            menuText: 'menuleft.other_setting.web_app_privacy',
            breadcrumb: 'breadcrum.page.other_setting',
          },
        ],
      },
      {
        title: 'menuleft.other_setting.admin_system',
        routes: [
          {
            path: '/other-setting/admin-system/support-menu',
            component: AdminSystem,
            menuText: 'menuleft.other_setting.admin_system_support_menu',
            breadcrumb: 'breadcrum.page.other_setting',
          },
        ],
      },
      {
        title: 'menuleft.survey_setting.survey_setting',
        routes: [
          {
            path: '/other-setting/survey_setting/survey_setting',
            component: SurveySetting,
            menuText: 'menuleft.survey_setting.survey_setting_menu',
            breadcrumb: 'breadcrum.page.survey_setting',
          },
        ],
      },
    ],
  },
];

export default routes;
