import { CallEffect } from 'redux-saga/effects';

const network = {
  namespace: 'network',
  state: {
    connected: window.navigator.onLine,
  },
  effects: {
    *change(
      { payload, callback }: { payload: any; callback?: any },
      { put }: { call: CallEffect; put: any }
    ) {
      yield put({
        type: 'network/save',
        payload,
      });
      if (callback) {
        callback();
      }
    },
  },
  reducers: {
    save(state: any, action: any) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export default network;
