/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Modal, Radio, Space, Switch, Table, Tooltip, Typography } from 'antd';
import React, { useImperativeHandle, useState } from 'react';
import { DragHandle, SortableContainers, SortableItem } from '..';
import { MenuOutlined } from '@ant-design/icons';
import { trim } from 'lodash';
import { arrayMoveImmutable } from 'array-move';

interface IProps {
  form?: any;
  onRef?: (r: any) => any;
  onCancel?: (r: any) => any;
  isEdited?: boolean;
  handleRemapOkText?: (v?: any) => any;
  isDisabled?: boolean;
  onFinish?: (r: any) => any;
  isVisible?: boolean;
  isLoading?: boolean;
  formRef?: any;
  selectedItem?: any;
  loadingQuestions?: any;
  ref: any

}
export enum EQuestionType {
  TEXTBOX = 'TEXTBOX',
  DROPDOWN = 'DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  CHECKBOXLIST = 'CHECKBOXLIST',
  DATE = 'DATE',
  EMAIL = 'EMAIL',
  RATING = 'RATING',
}
const dataAnswerDefault: any[] = [
  {
    index: 0,
    answer: 'A',
  },
  {
    index: 1,
    answer: 'B',
  },
  {
    index: 2,
    answer: 'C',
  },
  {
    index: 3,
    answer: 'D',
  },
  {
    index: 4,
    answer: 'E',
  },
];

const dataAnswerRating: any[] = [
  {
    index: 0,
    answer: '★',
  },
  {
    index: 1,
    answer: '★★',
  },
  {
    index: 2,
    answer: '★★★',
  },
  {
    index: 3,
    answer: '★★★★',
  },
  {
    index: 4,
    answer: '★★★★★',
  },
];
const FormSurveyQs = React.forwardRef((props: IProps, ref) => {
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => ({
    getDataAnswers: () => {
      const newDataAnswers: string[] = [...dataAnswers]
        .map((e: any) => e?.answer)
        .filter((e: any) => !!e);

      return newDataAnswers;
    },
  }))
  const getQuestion = React.useCallback(() => {

    form.setFieldsValue({
      ...props.selectedItem?.question_name,
      visibility: props.selectedItem?.visibility,
    });


  }, [props.selectedItem]);
  React.useEffect(() => {
    setValueInput('');
    // getQuestion();
    if (props.selectedItem) {
      form.setFieldsValue({
        question_name: props.selectedItem?.question_name, question_type: props.selectedItem?.question_type,
        is_required: props.selectedItem?.is_required
      })
      console.log()
      if (Array.isArray(props.selectedItem?.answer_value)) {

        const { answer_value } = props.selectedItem;

        const newDataAnswers = answer_value.map((answer: string, index: number) => ({
          index,
          answer,
        }));

        setDataAnswers(newDataAnswers);
      }
    } else {
      form.resetFields();
    }

    return () => {
      if (props.selectedItem) {
        form.resetFields();
      }
    };
  }, [props.selectedItem, props.isVisible]);
  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () =>
        !isDisabled ? (
          <Tooltip placement="topLeft" title={"移動"}>
            <div>
              <DragHandle />
            </div>
          </Tooltip>
        ) : (
          <MenuOutlined style={{ cursor: 'not-allowed', color: '#999' }} />
        ),
    },
    {
      dataIndex: 'answer',
      width: 540,
      render: (record: string) => (
        <Input className="input-answer" size="large" disabled value={record} />
      ),
    },
    {
      dataIndex: 'delete',
      render: (_: any, record: any) => (
        <img
          alt=""
          src="/images/ic-delete.png"
          onClick={() => handleDeleteAnswer(record?.index)}
          className="ic-delete"
        />
      ),
    },
  ];

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>('');
  const inputRef: any = React.useRef();
  const [dataAnswers, setDataAnswers] = React.useState<any[]>(dataAnswerDefault);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(...dataAnswers), oldIndex, newIndex).filter(
        (el) => !!el
      );
      setDataAnswers(newData);
    }
  };
  const DraggableContainer = (props: any) => (
    <SortableContainers
      useDragHandle
      helperClass="option-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataAnswers.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };
  const handleChangeQuestionType = (event: any) => {
    const { value } = event.target;

    if (value === EQuestionType.RATING) {
      setDataAnswers(dataAnswerRating);
    } else {
      setDataAnswers(dataAnswerDefault);
    }
  };
  const handleChangeVisibility = (checked: boolean) => {
    // form.setFieldsValue({ visibility: !checked });

    // const payload: IPayloadChangeVisibilityIncidentQuestion = {
    //   values: {
    //     status: checked,
    //     question_id: selectedItem?._id,
    //   },
    //   onSuccess: () => {
    //     form.setFieldsValue({ visibility: checked });
    //     loadingQuestions();
    //   },
    // };

    // return dispatch(changeVisibilityIncidentQuestionAction(payload));
  };
  const handleDeleteAnswer = (id: string) => {
    if (isDisabled) return;

    const newDataAnswers = [...dataAnswers];

    const findIndex: number = newDataAnswers.findIndex((e: any) => e?.index === id);
    if (findIndex >= 0) {
      newDataAnswers.splice(findIndex, 1);
    }

    setDataAnswers(newDataAnswers);
  };
  return (
    <>
      <Modal
        maskClosable={false}
        title={!!props.selectedItem ? "質問の修正" : "質問の追加"}
        centered
        visible={props.isVisible}
        onCancel={props.onCancel}
        footer={[

          <Button
            className="btn-save"
            type="primary"
            onClick={() => { setIsConfirm(true) }}
            size="large"
            loading={props.isLoading}
          >
            {!!props.selectedItem ? "保存" : "作成"}
          </Button>
          ,
          <Button key="cancel" className="btn-cancel" size="large" onClick={props.onCancel}>
            キャンセル
          </Button>,
        ]}
        className="modal md-form-incident-question"
      >
        <Form
          onFinish={props.onFinish}
          layout="vertical"
          scrollToFirstError
          ref={props.formRef}
          form={form}
          autoComplete="off"
          initialValues={{
            is_required: false,
            question_type: EQuestionType.TEXTBOX,
            editable: false,
            visibility: false,
            question_name: '',
          }}
        >



          <Form.Item
            name="question_name"
            label="質問"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "質問を入力してください。",
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 5, minRows: 3 }}
              placeholder="質問を入力"
              className="input-question"
              maxLength={256}
              disabled={isDisabled}
            />
          </Form.Item>

          <Form.Item className="form-required">
            <Form.Item name="is_required" valuePropName="checked">
              <Switch
                className="switch-required tiny-switch-common"
                size="small"
              //   disabled={disabledRequired()}
              />
            </Form.Item>
            <div className="wrap-txt">
              <Typography.Text>
                必須回答 (この質問の回答を必須にしたい場合、オンにしてください)
              </Typography.Text>
            </div>
          </Form.Item>

          {props.isEdited && (
            <Form.Item className="form-visibility">
              <div className="wrap-txt">
                <Typography.Text>
                  アプリでの表示
                </Typography.Text>
              </div>
              <Form.Item name="visibility" valuePropName="checked">
                <Switch
                  className="switch-visibility  tiny-switch-common"
                  size="small"
                  onChange={handleChangeVisibility}
                  //  loading={isLoading}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Form.Item>
          )}

          <div className="answer-block">
            <Form.Item
              name="question_type"
              label="質問の種類"
              className="answer-type"
              rules={[{ required: true }]}
            >
              <Radio.Group
                className="radio-answer-type"
                onChange={handleChangeQuestionType}
                disabled={isDisabled}
              >
                <Space direction="vertical" size={1} className="space-answer-type">
                  <Radio value={EQuestionType.TEXTBOX}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-textbox.png" />
                      <Typography.Text>
                        {"テキストボックス"}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.DROPDOWN}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-dropdown.png" />
                      <Typography.Text>
                        {"プルダウン (単一選択)"}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.CHECKBOX}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-checkbox.png" />
                      <Typography.Text>
                        {"確認チェック"}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.CHECKBOXLIST}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-checkbox-checked.png" />
                      <Typography.Text>
                        {"チェックボックス (複数選択)"}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.DATE}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-calendar.png" />
                      <Typography.Text>{"日付"}</Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.EMAIL}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-mail.png" />
                      <Typography.Text>{"メールアドレス"}</Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.RATING}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-star.png" />
                      <Typography.Text>{"レーティング"}</Typography.Text>
                    </Space>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(p, n) => p.question_type !== n.question_type}>
              {({ getFieldValue, setFields }) => {
                const answerType: string = getFieldValue('question_type');

                const renderContent = () => {
                  switch (answerType) {
                    case EQuestionType.TEXTBOX:
                      return (
                        <Typography.Text className="txt-answer">
                          テキストを入力できます。 (短文回答)
                        </Typography.Text>
                      );
                    case EQuestionType.CHECKBOX:
                      return (
                        <Typography.Text className="txt-answer">
                          チェックするかしないか選択できます。
                        </Typography.Text>
                      );
                    case EQuestionType.DATE:
                      return (
                        <Typography.Text className="txt-answer">
                          日付を選択できます。
                        </Typography.Text>
                      );
                    case EQuestionType.EMAIL:
                      return (
                        <Typography.Text className="txt-answer">
                          メールアドレスを入力できます。
                        </Typography.Text>
                      );
                    case EQuestionType.CHECKBOXLIST:
                    case EQuestionType.RATING:
                    case EQuestionType.DROPDOWN: {
                      return (
                        <div className="form-list-answers">
                          <Space className="space-input-add">
                            <Input
                              placeholder={"選択肢を追加"}
                              className="input-add-new"
                              size="large"
                              ref={inputRef}
                              maxLength={256}
                              value={valueInput}
                              onChange={(e) => setValueInput(e.target.value)}
                            />
                            <Button
                              onClick={() => {
                                const trimInput: string = trim(valueInput || '');
                                if (trimInput.length > 0) {
                                  const newDataAnswers: any[] = [...dataAnswers];
                                  const index: number = new Date().valueOf();

                                  const checkExistAnswer: boolean = newDataAnswers.some(
                                    (item: any) =>
                                      item.answer.toLowerCase() === trimInput.toLowerCase()
                                  );

                                  if (checkExistAnswer) {
                                    return setFields([
                                      {
                                        name: 'extra',
                                        errors: [
                                          "この項目は既に使用されています。",
                                        ],
                                      },
                                    ]);
                                  }

                                  setFields([{ name: 'extra', errors: [] }]);

                                  newDataAnswers.push({ index, answer: trimInput });
                                  setDataAnswers(newDataAnswers);
                                  setValueInput('');
                                }
                                return inputRef?.current?.focus?.();
                              }}
                              type="primary"
                              size="large"
                              className="btn-add"
                              disabled={isDisabled}
                            >
                              追加
                            </Button>
                          </Space>

                          <Table
                            pagination={false}
                            dataSource={dataAnswers}
                            columns={columns}
                            rowKey="index"
                            components={{
                              body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                              },
                            }}
                            className="table-answers"
                            scroll={{ y: 270 }}
                            locale={{
                              emptyText: "回答オプション",
                            }}
                          />
                        </div>
                      );
                    }
                    default:
                      return null;
                  }
                };

                return (
                  <Form.Item
                    className="answer"
                    label={"回答オプション"}
                    name="extra"
                  >
                    <div className="wrap-answer">{renderContent()}</div>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <Modal
        //maskClosable={false}
        title={"ご注意ください"}
        centered
        visible={isConfirm}
        onCancel={() => { setIsConfirm(false) }}
        footer={[

          <Button
            className="btn-save"
            type="primary"
            onClick={() => {
              setIsConfirm(false)
              form.submit()
            }}
            size="large"
            loading={props.isLoading}
          >
            {!!props.selectedItem ? "保存" : "作成"}
          </Button>
          ,
          <Button key="cancel" className="btn-cancel" size="large" onClick={() => { setIsConfirm(false) }}>
            キャンセル
          </Button>,
        ]}
        className="modal "
      >
        {
          !!props.selectedItem ?
            "アンケート回答画面の質問が編集されます。本当に質問を編集しますか？" :
            "アンケート回答画面に質問が追加されます。本当に質問を作成しますか？"}
      </Modal >
    </>
  )
});
export default FormSurveyQs;