import { Button, Form, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { Component, FC, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import './styles.scss';

interface IProps {
  content?: (v?: any) => FC | JSX.Element;
  onOk?: any;
  okText?: string;
  cancelText?: string;
  t: any;
  onRef?: (v: any) => any;
  widthModalAdd?: number | string;
  form: any;
}

interface IState {
  visible: boolean;
  loading: boolean;
  footer?: any;
  okState?: any;
  cancelState?: any;
}

class ModalCreate extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: false,
      loading: false,
      footer: undefined,
      okState: undefined,
      cancelState: undefined,
    };
    const { onRef } = props;
    if (onRef) onRef(this);
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleVisible = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({
      visible: true,
      loading: false,
      cancelState: undefined,
      okState: undefined,
      footer: undefined,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleFooter = () => {
    const { okText, cancelText, t } = this.props;
    const { okState, cancelState } = this.state;
    return (
      <Fragment>
        <Button type="primary" style={{ height: 40 }} htmlType="submit">
          {okState || okText || t('common.button.add')}
        </Button>
        <Button onClick={this.handleCancel} style={{ height: 40, marginLeft: 12 }}>
          {cancelState || cancelText || t('common.button.cancel')}
        </Button>
      </Fragment>
    );
  };

  handleRemapFooter = (footer?: any) => {
    this.setState({ footer });
  };

  handleRemapOkText = (text: any) => {
    this.setState({ okState: text });
  };

  handleRemapCancelText = (text: any) => {
    this.setState({ cancelState: text });
  };

  handleFinish = (values) => {
    const { onOk } = this.props;
    this.setState({ loading: true });
    if (typeof onOk === 'function') {
      onOk(values, (success: boolean) => {
        if (success) {
          this.setState({
            loading: false,
            visible: false,
            cancelState: undefined,
            okState: undefined,
            footer: undefined,
          });
        } else {
          this.setState({ loading: false });
        }
      });
    }
  };

  render() {
    const { visible, loading, footer } = this.state;
    const { content, onOk, widthModalAdd, form } = this.props;
    return (
      <Modal
        destroyOnClose
        centered
        footer={null}
        onCancel={this.handleCancel}
        onOk={onOk}
        title={null}
        visible={visible}
        width={widthModalAdd}
        className="modal-table-add"
        maskClosable={false}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            autoComplete="off"
            onFinish={this.handleFinish}
            scrollToFirstError
          >
            <div style={{ padding: '16px 24px' }}>
              {content?.({
                form,
                handleRemapFooter: this.handleRemapFooter,
                handleRemapOkText: this.handleRemapOkText,
                handleRemapCancelText: this.handleRemapCancelText,
              })}
            </div>
            <Form.Item
              style={{
                textAlign: 'right',
                margin: 0,
                boxShadow: '0px -1px 0px #EFEFF4',
                padding: '10px 24px',
              }}
            >
              {footer || this.handleFooter()}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const withUseFormHook = (Component) => {
  return (props) => {
    const [form] = useForm();
    return <Component {...props} form={form} />;
  };
};

export default withUseFormHook(withTranslation()(ModalCreate));
