import axiosConfig from 'config/axios';

const otherSetting = {
  async list(dataReq) {
    try {
      const data = await axiosConfig.get('/admin-cms/term-privacy/list', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async create(dataReq) {
    try {
      const data = await axiosConfig.post('/admin-cms/term-privacy/create', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default otherSetting;
