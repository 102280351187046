import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import throwException from 'utils/throwException';
import moment from 'moment';
import account from 'services/account';
import cms from 'services/cms';
import { Select, Spin, Table, DatePicker, Button } from 'antd';
import { sumBy, includes } from 'lodash';
import EmptyBase from 'components/Empty';
import { CloseCircleFilled } from '@ant-design/icons';
import '../styles.scss';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  t: any;
  onRef?: any;
}

interface IState {
  data: any[];
  loading: boolean;
  dataAcount: any[];
  dataActivationLogAcount: any[];
  rangeState: string;
  dataAccountSelect: any[];
}

class RegisterTimeLine extends Component<IProps, IState> {
  page_number: number;
  namesearch: any;
  category_notify: any;
  start_date: any;
  end_date: any;
  constructor(props: IProps) {
    super(props);
    const { t } = this.props;
    this.state = {
      data: [],
      loading: false,
      dataAcount: [],
      dataActivationLogAcount: [],
      rangeState: 'first',
      dataAccountSelect: [t('account_activation.account.select.total')],
    };
    this.page_number = 1;
    this.namesearch = '';
  }

  componentDidMount() {
    const { onRef } = this.props;
    if (onRef) onRef(this);
    this.getDataAccount(() => this.getData());
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    if (onRef) onRef(undefined);
  }

  handleChangeRangeState = (rangeState) => {
    this.setState({ rangeState }, () => this.getData());
  };

  handleError = (e) => {
    this.setState({ loading: false });
    throwException(e);
  };

  getDataAccount = (callback) => {
    const { t } = this.props;
    this.setState({ loading: true });
    account
      .listAccountRoot()
      .then(({ data }) => {
        let dataAcountList = [] as any;
        dataAcountList.push({
          value: t('account_activation.account.select.total'),
          text: t('account_activation.account.select.total'),
        });
        data.data.map((item) => {
          dataAcountList.push({ value: item._id, text: item.name });
          return item;
        });
        this.setState({ dataAcount: dataAcountList });
      })
      .catch(this.handleError)
      .finally(() => {
        callback();
      });
  };

  getData = () => {
    let { dataAccountSelect, rangeState } = this.state;
    const { t } = this.props;
    if (!dataAccountSelect.length) {
      this.setState({ dataActivationLogAcount: [] });
      return;
    }
    if (includes(dataAccountSelect, t('account_activation.account.select.total'))) {
      dataAccountSelect = [];
    }
    this.setState({ loading: true });
    let params = {
      org_ids: dataAccountSelect,
      get_type: 'TIMELINE',
      range_time: rangeState === 'first' ? 'MONTH' : 'WEEK',
    };
    if (this.start_date && this.end_date) {
      params = { ...params, ...{ start_date: this.start_date, end_date: this.end_date } };
    }
    cms
      .getActivationLog(params)
      .then(({ data }) => {
        const dataResult = data.data;
        const sumData = {
          totalUserActive: sumBy(data.data, 'totalUserActive'),
          userPlatformMobile: sumBy(data.data, 'userPlatformMobile'),
          userPlatformWeb: sumBy(data.data, 'userPlatformWeb'),
        };
        dataResult.push(sumData);
        this.setState({ dataActivationLogAcount: dataResult });
      })
      .catch(this.handleError)
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  extraRanges = (): any => {
    const thisYear: number = moment().year();
    const thisMonth: number = moment().month();
    const { t } = this.props;

    return {
      [t('reports.activationVolume.last3Month')]: [moment().subtract(3, 'months'), moment()],
      [t('reports.activationVolume.last6Month')]: [moment().subtract(6, 'months'), moment()],
      [t('reports.activationVolume.thisAcademic')]: [
        moment(`${thisMonth >= 4 ? thisYear : thisYear - 1} /04/01`, 'YYYY/MM/DD'),
        moment(),
      ],
    };
  };

  renderColumns = (): any[] => {
    const { t } = this.props;
    const { rangeState } = this.state;
    const columns = [
      {
        title: t('account_activation.table.title.stage'),
        dataIndex: 'start_time',
        width: 450,
        render: (v, record) =>
          v ? (
            <div>
              {rangeState === 'first' ? <div>{moment(v).format('YYYY年MMM')}</div> : null}
              <div>{`${v} - ${record.end_time}`}</div>
            </div>
          ) : (
            t('reports.activationVolume.acumulate')
          ),
      },
      {
        title: t('account_activation.table.title.total'),
        dataIndex: 'totalUserActive',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },

      {
        title: t('account_activation.table.title.web'),
        dataIndex: 'userPlatformWeb',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },
      {
        title: t('account_activation.table.title.mobile'),
        dataIndex: 'userPlatformMobile',
        width: 200,
        align: 'right',
        render: (v) => <div>{v}</div>,
      },
    ];
    return columns;
  };
  handleSearch = (e) => {
    const { value = '' } = e.target;
    this.namesearch = value.trim();
    const { data } = this.state;
    this.page_number = 1;
    if (this.namesearch.length < 5) {
      if (data.length) {
        this.setState({ data: [] });
      }
      return;
    }
    this.getData();
  };

  handeChangeDateRange = (value) => {
    console.log(value);
  };

  handleSearchMutilOption = (value) => {
    this.setState({ dataAccountSelect: value }, () => this.getData());
  };

  handleSearchDateTime = (value) => {
    this.start_date = value[0];
    this.end_date = value[1];

    this.getData();
  };

  handleChangePage = (page) => {
    this.page_number = page;
    this.getData();
  };

  exportLog = () => {
    const { loading, dataAccountSelect, rangeState } = this.state;
    const { t } = this.props;
    if (!dataAccountSelect.length) return;
    if (!loading) this.setState({ loading: true });
    const paramsFilter = new URLSearchParams();
    paramsFilter.append('get_type', 'TIMELINE');
    paramsFilter.append('standby_token', localStorage.getItem('access_token') || '');
    paramsFilter.append('showHidden', 'true');
    paramsFilter.append('range_time', rangeState === 'first' ? 'MONTH' : 'WEEK');
    if (includes(dataAccountSelect, t('account_activation.account.select.total'))) {
    } else {
      if (dataAccountSelect.length) {
        paramsFilter.append('org_ids', '');
        dataAccountSelect.map((item) => {
          paramsFilter.append('org_ids', item);
          return item;
        });
      }
    }

    if (this.start_date && this.end_date) {
      paramsFilter.append('start_date', this.start_date);
      paramsFilter.append('end_date', this.end_date);
    }
    const queryString = paramsFilter.toString();
    window.open(process.env.REACT_APP_API_URL + '/admin-cms/export/log-active-user?' + queryString);
    this.setState({ loading: false });
  };

  render() {
    const {
      loading,
      dataAcount,
      dataActivationLogAcount,
      rangeState,
      dataAccountSelect,
    } = this.state;
    const { t } = this.props;
    return (
      <Fragment>
        <Spin spinning={loading}>
          <div className="report-admin">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => this.handleSearchMutilOption(e)}
                  placeholder={t('account_activation.search.date_time.placeholder')}
                  mode="multiple"
                  maxTagTextLength={10}
                  maxTagCount={2}
                  showArrow
                  defaultValue={dataAccountSelect}
                  style={{
                    width: 400,
                    marginBottom: 12,
                    minHeight: 42,
                    marginLeft: 12,
                    borderRadius: 4,
                  }}
                >
                  {dataAcount?.length &&
                    dataAcount.map((item) => {
                      return (
                        <Option value={item.value} key={item.value}>
                          {item.value === t('account_activation.account.select.total') ? (
                            <div style={{ color: '#007AFF' }}>{item.text}</div>
                          ) : (
                            item.text
                          )}
                        </Option>
                      );
                    })}
                </Select>
                <RangePicker
                  className="range-picker"
                  style={{ width: 400, height: 40, marginLeft: 12 }}
                  size="large"
                  placeholder={[
                    t('bulk_incident.label.date_time.placeholder.start'),
                    t('bulk_incident.label.date_time.placeholder.end'),
                  ]}
                  ranges={this.extraRanges()}
                  dropdownClassName="dropdown-range-picker range-bulk dropdown-range-picker-custom"
                  format="YYYY/MM/DD"
                  onChange={(date, dateString) => {
                    this.handleSearchDateTime(dateString);
                  }}
                  disabledDate={(current) => moment() <= current}
                  defaultPickerValue={[
                    moment().subtract(1, 'months'),
                    moment().subtract(1, 'months'),
                  ]}
                  allowClear
                  clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                />
              </div>
              <div>
                <Button
                  className={
                    rangeState === 'first'
                      ? 'button-range-state-active'
                      : 'button-range-state-not-active'
                  }
                  onClick={() => {
                    this.handleChangeRangeState('first');
                  }}
                >
                  {t('account_activation.range.title.month')}
                </Button>
                <Button
                  onClick={() => {
                    this.handleChangeRangeState('second');
                  }}
                  className={
                    rangeState === 'second'
                      ? 'button-range-state-active'
                      : 'button-range-state-not-active'
                  }
                >
                  {t('account_activation.range.title.week')}
                </Button>
              </div>
            </div>
            <Table
              scroll={{ x: 1000 }}
              locale={{ emptyText: <EmptyBase /> }}
              rowKey={'_id'}
              dataSource={dataActivationLogAcount}
              columns={this.renderColumns()}
              pagination={false}
              rowClassName={(record, index) =>
                index === dataActivationLogAcount.length - 1
                  ? 'table-row-total'
                  : index % 2 === 0
                  ? ''
                  : 'table-row-light'
              }
            />
          </div>
        </Spin>
      </Fragment>
    );
  }
}

export default withTranslation()(RegisterTimeLine);
