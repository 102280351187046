import { Button, Table, DatePicker, Select } from 'antd';
import { TableProps } from 'antd/lib/table';
import { DefaultRecordType } from 'rc-table/lib/interface';
import React, { Component, FC } from 'react';
import { CloseCircleFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import ModalCreate from './ModalAdd';
import debounce from 'lodash/debounce';
import './styles.scss';
import EmptyBase from 'components/Empty';
import InputBase from 'components/Input';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ja';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  allowAdd?: boolean;
  lableKeyI18n?: string;
  t: any;
  formAdd?: (v?: any) => FC | JSX.Element;
  widthModalAdd?: number | string;
  onOkFormAdd?: (v?: any, callback?: any) => any;
  handleSearch?: (v: string) => any;
  style?: any;
  resetSearch?: (v: any) => any;
  handleSearchOption?: (v: string) => any;
  handleSearchDateTime?: (v: string) => any;
  dataOption?: any[];
  formAddhiddenFooter?: boolean;
  okText?: string;
  cancelText?: string;
  typeSearch?: string;
  disabledAdd?: boolean;
  placeholderInputSearch?: string;
  showSearch?: boolean;
  notSearchValue?: boolean;
  handleSearchMutilOption?: (v?: any) => any;
  showState?: boolean;
  rangeState?: string;
  handleChangeRangeState?: (v: string) => any;
}

interface IState {
  valueSearch: string;
}

class TableBase extends Component<IProps & TableProps<DefaultRecordType>, IState> {
  modalCreate: any;

  constructor(props: IProps & TableProps<DefaultRecordType>) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
    this.state = { valueSearch: '' };
    const { resetSearch } = props;
    if (typeof resetSearch === 'function') {
      resetSearch(() => {
        this.setState({ valueSearch: '' });
      });
    }
  }
  componentWillUnmount() {
    const { resetSearch } = this.props;
    if (typeof resetSearch === 'function') {
      resetSearch(undefined);
    }
  }

  handleModalCreate = () => {
    const { disabledAdd } = this.props;
    if (disabledAdd) {
      return;
    }
    this.modalCreate?.handleVisible?.();
  };

  handleSearch = (e) => {
    const { handleSearch } = this.props;
    if (handleSearch) handleSearch(e);
  };

  handleSearchOption = (e) => {
    const { handleSearchOption } = this.props;
    if (handleSearchOption) handleSearchOption(e);
  };

  handleSearchDateTime = (e) => {
    const { handleSearchDateTime } = this.props;
    if (handleSearchDateTime) handleSearchDateTime(e);
  };

  handleSearchMutilOption = (e) => {
    const { handleSearchMutilOption } = this.props;
    if (handleSearchMutilOption) handleSearchMutilOption(e);
  };

  handleOke = (values, callback) => {
    const { onOkFormAdd } = this.props;
    onOkFormAdd?.(values, (success: boolean) => {
      if (typeof callback === 'function') callback(success);
      this.setState({ valueSearch: '' });
    });
  };

  extraRanges = (): any => {
    const { t } = this.props;
    return {
      [t('reports.bulkIncidents.today')]: [moment(), moment()],
      [t('reports.bulkIncidents.yesterday')]: [
        moment().subtract(1, 'days'),
        moment().subtract(1, 'days'),
      ],
      [t('reports.bulkIncidents.last7days')]: [moment().subtract(7, 'days'), moment()],
      [t('reports.bulkIncidents.last30days')]: [moment().subtract(30, 'days'), moment()],
      [t('reports.bulkIncidents.thisMonth')]: [moment().startOf('month'), moment().endOf('month')],
      [t('reports.bulkIncidents.lastMonth')]: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
      [t('reports.bulkIncidents.onceYear')]: [moment().startOf('year'), moment().endOf('year')],
    };
  };

  handleChangeRangeState = (value) => {
    const { handleChangeRangeState } = this.props;
    handleChangeRangeState?.(value);
  };

  getPlaceHolder = (type) => {
    const { t } = this.props;
    switch (type) {
      case 'email':
        return t('history.log.email_search.placeholder');
      case 'phone':
        return t('history.log.phone_number_search.placeholder');
      default:
        return t('table_base.input.search.placeholder');
    }
  };

  render() {
    const {
      dataOption,
      handleSearchDateTime,
      handleSearchOption,
      columns,
      dataSource = [],
      rowKey,
      formAdd,
      widthModalAdd,
      onOkFormAdd,
      handleSearch,
      t,
      lableKeyI18n,
      allowAdd,
      style,
      pagination,
      scroll,
      okText,
      cancelText,
      typeSearch,
      disabledAdd,
      placeholderInputSearch,
      notSearchValue,
      handleSearchMutilOption,
      showState,
      rangeState,
      ...props
    } = this.props;
    const { valueSearch } = this.state;

    return (
      <div className="table-base" style={{ margin: 16, ...style }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {handleSearch && (
              <div style={{ display: 'flex' }}>
                {notSearchValue ? null : (
                  <InputBase
                    onChange={(e) => {
                      this.setState({ valueSearch: e.target.value });
                      this.handleSearch(e);
                    }}
                    style={{ width: 400, marginBottom: 12 }}
                    prefix={<SearchOutlined />}
                    placeholder={placeholderInputSearch || this.getPlaceHolder(typeSearch)}
                    value={valueSearch}
                  />
                )}
                {handleSearchOption && (
                  <Select
                    showSearch={!_.isEmpty(this.props.showSearch) ? true : this.props.showSearch}
                    optionFilterProp="children"
                    onChange={(e) => this.handleSearchOption(e)}
                    style={{
                      width: 400,
                      marginBottom: 12,
                      height: 42,
                      marginLeft: 12,
                      borderRadius: 4,
                    }}
                    defaultValue={''}
                  >
                    {dataOption?.length &&
                      dataOption.map((item) => {
                        return (
                          <Option value={item.value} key={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                  </Select>
                )}
                {handleSearchMutilOption && (
                  <Select
                    showSearch={!_.isEmpty(this.props.showSearch) ? true : this.props.showSearch}
                    optionFilterProp="children"
                    onChange={(e) => this.handleSearchMutilOption(e)}
                    placeholder={t('account_activation.search.date_time.placeholder')}
                    mode="multiple"
                    maxTagCount={2}
                    showArrow
                    style={{
                      width: 400,
                      marginBottom: 12,
                      minHeight: 42,
                      marginLeft: 12,
                      borderRadius: 4,
                    }}
                  >
                    {dataOption?.length &&
                      dataOption.map((item) => {
                        return (
                          <Option value={item.value} key={item.value}>
                            {item.text}
                          </Option>
                        );
                      })}
                  </Select>
                )}
                {handleSearchDateTime && (
                  <RangePicker
                    className="range-picker"
                    size="large"
                    placeholder={[
                      t('bulk_incident.label.date_time.placeholder.start'),
                      t('bulk_incident.label.date_time.placeholder.end'),
                    ]}
                    ranges={this.extraRanges()}
                    dropdownClassName="dropdown-range-picker range-bulk dropdown-range-picker-custom"
                    format="YYYY/MM/DD"
                    onChange={(date, dateString) => {
                      this.handleSearchDateTime(dateString);
                    }}
                    disabledDate={(current) => moment() <= current}
                    defaultPickerValue={[
                      moment().subtract(1, 'months'),
                      moment().subtract(1, 'months'),
                    ]}
                    allowClear
                    clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                  />
                )}
              </div>
            )}
          </div>
          {showState && (
            <div>
              <Button
                className={
                  rangeState === 'second'
                    ? 'button-range-state-active'
                    : 'button-range-state-not-active'
                }
                onClick={() => {
                  this.handleChangeRangeState('second');
                }}
              >
                {t('account_activation.range.title.month')}
              </Button>
              <Button
                onClick={() => {
                  this.handleChangeRangeState('first');
                }}
                className={
                  rangeState === 'first'
                    ? 'button-range-state-active'
                    : 'button-range-state-not-active'
                }
              >
                {t('account_activation.range.title.week')}
              </Button>
            </div>
          )}
        </div>
        <Table
          scroll={scroll || { x: 1334 }}
          locale={{ emptyText: <EmptyBase /> }}
          {...props}
          rowKey={rowKey}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination ? { ...pagination, showSizeChanger: false } : false}
          rowClassName={(record, index) =>
            !pagination
              ? index === dataSource.length - 1
                ? 'table-row-total'
                : index % 2 === 0
                ? ''
                : 'table-row-light'
              : ''
          }
        />
        <ModalCreate
          okText={okText}
          cancelText={cancelText}
          widthModalAdd={widthModalAdd}
          onRef={(ref) => {
            this.modalCreate = ref;
          }}
          content={formAdd}
          onOk={this.handleOke}
        />
        {allowAdd ? (
          <div className="button-add" style={{ top: handleSearch ? 58 : 5 }}>
            <Button
              disabled={!!disabledAdd}
              type="primary"
              icon={<PlusOutlined />}
              onClick={this.handleModalCreate}
            >
              {t(lableKeyI18n)}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(TableBase);
