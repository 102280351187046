import axiosConfig, { intaince } from 'config/axios';

const imports = {
  async getUrlUpload(dataReq) {
    try {
      const data = await axiosConfig.post('/import/get-upload-url', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async upload(url, headers, dataReq) {
    try {
      const data = await intaince.put(url, dataReq, headers);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async list(dataReq: any) {
    try {
      const data = await axiosConfig.get('/import/list', {
        params: dataReq,
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async import(dataReq: any) {
    try {
      const data = await axiosConfig.post('/import/incident', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async delete(dataReq: any) {
    try {
      const data = await axiosConfig.post('/import/delete', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default imports;
