import { Empty } from 'antd';
import { Component } from 'react';

class EmptyBase extends Component {
  render() {
    return <Empty description={false} />;
  }
}

export default EmptyBase;
