import axiosConfig, { intaince } from 'config/axios';

const upload = {
  async getUrlUpload(dataReq) {
    try {
      const data = await axiosConfig.post('/amdmin/file/url-upload', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async upload(url, headers, dataReq) {
    try {
      const data = await intaince.put(url, dataReq, headers);
      return data;
    } catch (e) {
      throw e;
    }
  },
  async preview(dataReq: any) {
    try {
      const data = await axiosConfig.post('/amdmin/file/preview', dataReq);
      return data;
    } catch (e) {
      throw e;
    }
  },
};

export default upload;
