import { Component } from 'react';
import BreadcrumbBase from 'components/Breadcrumb';
import { Tabs, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import LogEmail from './LogEmail';
import LogSms from './LogSms';
import { BrowserHistory } from 'history';

interface IProps {
  history: BrowserHistory;
  pathname: string;
  id: string;
  t: any;
  location: any;
}

interface IState {
  tab: string | number;
}

class HistoryLog extends Component<IProps, IState> {
  exportMail: any;
  exportCms: any;

  constructor(props: IProps) {
    super(props);
    const { location } = props;
    const query = new URLSearchParams(location.search);
    this.state = { tab: query.get('tab') || '1' };
  }

  UNSAFE_componentWillReceiveProps(nProps: IProps) {
    const { location } = this.props;
    if (location.search !== nProps.location.search) {
      const query = new URLSearchParams(nProps.location.search);
      this.setState({ tab: query.get('tab') || '1' });
    }
  }

  shouldComponentUpdate(_, nState: IState) {
    const { tab } = this.state;
    return tab !== nState.tab;
  }

  render() {
    const { pathname, t, history } = this.props;
    const { tab } = this.state;
    return (
      <div>
        <BreadcrumbBase
          pathname={pathname}
          extendRightComponent={
            <Button
              type="primary"
              onClick={() => {
                if (tab === '1') {
                  this.exportMail?.exportLog?.();
                } else {
                  this.exportCms?.exportLog?.();
                }
              }}
            >
              {t('history_log.button.export.title')}
            </Button>
          }
        />
        <Tabs
          onChange={(tab) => history.replace({ search: `?tab=${tab}` })}
          activeKey={tab.toString()}
          style={{ padding: 16 }}
        >
          <Tabs.TabPane tab={t('history_log.tab.email')} key="1">
            <LogEmail onRef={(ref) => (this.exportMail = ref)} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('history_log.tab.sms')} key="2">
            <LogSms onRef={(ref) => (this.exportCms = ref)} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(HistoryLog);
